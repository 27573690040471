import React from 'react'
import { Navigate,useLocation } from 'react-router-dom'

function Protected({ isAuthenticated, children }) {
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
   // <Navigate to="/login" replace state={{ path: location.pathname }} 
  }
  return children
}
export default Protected


// // src/components/ProtectedRoute.jsx
// import React from "react";
// import { Route, Navigate } from "react-router-dom";

// const Protected = ({ element: Element, isAuthenticated }) => {
//   return isAuthenticated ? <Element/> : <Navigate to="/login" replace />;
// };

// export default Protected;
