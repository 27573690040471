import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";
import Modal from 'react-modal';
import expand from './../../../assets/images/expand.svg';
import company_logo from './../../../assets/images/4bc.svg';
import calendar from './../../../assets/images/calendar.png';
import topArrow from './../../../assets/images/export.svg';
import EmptyPieChart from "../../../Components/EmptyPieChart";
import { COLORS, CancerSiteColors, HospitalColor } from '../../../utils/constants'
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, LabelList, Bar, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from "recharts";
const NTRKAnyalsis = (props) => {
    const { allGraphData, top10totalCount, filterGraphs, AlkVariationFilter,
        filterVal, top20CitiesData, hospitalList, setHospitalList, hospitalNameData, setHospitalNameData, downloadLoading,
        hospitalName, setHospitalName, AllGraphsList, excelDownload, alkDetecionStatus, primarySiteDistribution, alkVariations,
        primarySiteTotal, alkStatus, alkTotals, cancerType, cancerTypeName, searchCancerType, alkVariationTotal } = props
    const [citiesOpen, setCitiesOpen] = useState(false);
    const [hospitalOpen, sethospitalOpen] = useState(false);
    const [hospitalShow, sethospitalShow] = useState(false);
    const [primarySite, setprimarySite] = useState(false);
    const [alkVariation, setAlkVariation] = useState(false);
    const [alkDetection, setAlkDetecion] = useState(false);
    const navigate = useNavigate();
    const RADIAN = Math.PI / 180;

    const calculateLabelColor = (backgroundColor) => {
        if (backgroundColor.startsWith('#')) {
            const hex = backgroundColor.replace('#', '');
            const rgb = hex.match(/.{1,2}/g).map(val => parseInt(val, 16));

            const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;

            return luminance > 0.5 ? '#000000' : '#ffffff'; // Light background: use dark label color, Dark background: use light label color
        }

        if (backgroundColor.startsWith('rgb')) {
            const rgb = backgroundColor.match(/\d+/g).map(val => parseInt(val, 10));
            const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
            return luminance > 0.5 ? '#000000' : '#ffffff';
        }

        return '#000000';
    };

    const TmbMsiCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (alkTotals)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const CustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (primarySiteTotal)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const AlkVariantCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (alkVariationTotal)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const TMBMSICustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x - 8} y={y}
                fill={calculateLabelColor(payload?.fill)}
                fontSize={10}
                style={{ fontWeight: 'bold' }}
                // textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central">
                {(percent * 100).toFixed(0) > 1 ? `${(percent * 100).toFixed(0)}%` : null}
                {/* {payload?.payload?.value} */}
            </text>

        );
    };

    const PrimarySiteCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }, props) => {
        const value = (percent * 100).toFixed(0) >= 10 ? 0.5 : 0.8
        const radius = innerRadius + (outerRadius - innerRadius) * value;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x} y={y}
                fill={calculateLabelColor(payload?.fill)}
                style={{ fontWeight: 'bold' }}
                fontSize={(percent * 100).toFixed(0) > 2 ? 10 : 5}
                textAnchor="middle" dominantBaseline="central">
                {(percent * 100).toFixed(0) >= 2 ? `${(percent * 100).toFixed(0)}%` : null}
            </text>

        );
    };


    function citiesModal() {
        setCitiesOpen(true);
    }
    function hospitalModal() {
        sethospitalOpen(true);
    }

    function primaryModal() {
        setprimarySite(true);
    }

    function alkDetecionModal() {
        setAlkDetecion(true);
    }
    function primarycloseModal() {
        setprimarySite(false);
    }
    function alkDetecioncloseModal() {
        setAlkDetecion(false);
    }

    function alkVariationModal() {
        setAlkVariation(true);
    }

    function alkVariationcloseModal() {
        setAlkVariation(false);
    }



    function hospitalLocation(params) {
        const hospitalNames = hospitalNameData?.filter((item) => {
            if (item.hospitalCity === params?.name) {
                return item
            }
        })

        const transformedArray = [];
        hospitalNames?.map((item) => {
            return (
                Object.entries(item).forEach(([name, value]) => {
                    transformedArray.push({ name, value });
                })
            )
        })

        const finalHospital = transformedArray.filter((item) => item.value !== params?.name && item)
        setHospitalList(finalHospital)
        sethospitalShow(true);
        setHospitalName(params?.name)
    }
    function citiesCloseModal() {
        setCitiesOpen(false);
    }
    function hospitalCloseModal() {
        sethospitalOpen(false);
    }

    function commafy(num) {
        if (num) {
            var str = num.toString().split('.');
            if (str[0].length >= 3) {
                str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
            }
            if (str[1] && str[1].length >= 3) {
                str[1] = str[1].replace(/(\d{3})/g, '$1 ');
            }
            return str.join('.');
        }
    }

    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value, fill } = props;
        const radius = 13;
        return (
            <g>
                {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill={fill} /> */}
                <text x={x + width / 2} y={y - radius} fontSize={12} fill="#a9a9a9" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };
    const MAX_LABEL_LENGTH = 10; // Maximum number of characters to show in the label
    const LABEL_ANGLE = -25; // Angle (in degrees) to rotate the labels
    // const LABEL_ANGLE = -45; 
    const CustomizedXAxisTick = (props) => {
        const { x, y, payload } = props;
        const [isHovered, setHovered] = useState(false);

        const handleMouseEnter = () => {
            setHovered(true);
        };

        const handleMouseLeave = () => {
            setHovered(false);
        };

        const renderLabel = () => {
            const { value } = payload;
            return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
            //   return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
        };

        return (
            <g transform={`translate(${x},${y})`} textAnchor="end" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <text
                    x={0}
                    y={0}
                    dy={4}
                    dx={14}
                    fontSize={isHovered ? 12 : 10}
                    fontWeight={isHovered ? 'bold' : ''}
                    textAnchor="end"
                    fill={isHovered ? '#5373ff' : "#666"}
                    transform={`rotate(${LABEL_ANGLE})`}
                >

                    {renderLabel()}
                </text>
            </g>
        );
    };
    return (
        <div className="content-wrapper ">
            <div className="content-wrapper_inner">
                {downloadLoading && <div className='downloadLoader '>
                    <Loader type="spinner-circle" bgColor='black' title={"downloading..."} color={'#FFFFFF'} size={100} />
                </div>
                }
                <div>
                </div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-1 ">
                            <div className="col px-0">
                                <div className='w-100'></div>
                                <div className='d-flex align-items-center justify-content-between bg-white rounded py-3 cust_flex'>
                                    <div className='title_panel'>
                                        <h4 className='mb-0'>NTRK Analysis</h4>
                                    </div>
                                    <div className='m-top'>
                                        {/* <div className="btn-group range_button">
                                            <img src={topArrow} style={{ marginRight: 5 }} />
                                        </div> */}
                                        <div className="btn-group range_button det_height cancersite_btn">
                                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                            {cancerTypeName ? cancerTypeName : 'Cancer Type'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right mx-2 ">
                                                <div className='mx-2'>
                                                <input type="text"
                                                        //className="form-control"
                                                        className="search-input"
                                                        placeholder="Search..."
                                                        onChange={(e) => searchCancerType(e)} />
                                                </div>
                                                {cancerType?.map((item, i) => {
                                                    return (
                                                        <button key={i} onClick={() => filterGraphs(filterVal, null, null, item.name)} className="dropdown-item" type="button">{item.name}</button>
                                                    )
                                                })}
                                                <button onClick={() => AllGraphsList()} className="dropdown-item" type="button">All Cancer Type</button>
                                            </div>
                                        </div>
                                        <div className="btn-group range_button det_height">
                                            <button className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                                {alkDetecionStatus ? alkDetecionStatus : 'NTRK Detection Status'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right ">
                                                <button onClick={() => filterGraphs(filterVal, null, 'Detected', cancerTypeName)} className="dropdown-item" type="button">Detected</button>
                                                <button onClick={() => filterGraphs(filterVal, null, 'Undetected', cancerTypeName)} className="dropdown-item" type="button">Undetected</button>
                                                <button onClick={() => AllGraphsList()} className="dropdown-item" type="button">All Status</button>
                                            </div>
                                        </div>
                                        <div className="btn-group range_button cancersite_btn cust_width">
                                            <button className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                                {AlkVariationFilter ? AlkVariationFilter : 'NTRK Variant Types'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right mx-2 ">
                                                <button onClick={() => filterGraphs(filterVal, 'CNV', null, cancerTypeName)} className="dropdown-item" type="button">CNV</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Wild type', null, cancerTypeName)} className="dropdown-item" type="button">Wlid type</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Missense', null, cancerTypeName)} className="dropdown-item" type="button">Missense</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Nonsense', null, cancerTypeName)} className="dropdown-item" type="button">Nonsense</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Synonymous', null, cancerTypeName)} className="dropdown-item" type="button">Synonymous</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Splice site donor variant', null, cancerTypeName)} className="dropdown-item" type="button">Splice site donor variant</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Splice site acceptor', null, cancerTypeName)} className="dropdown-item" type="button">Splice site acceptor</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Indel', null, cancerTypeName)} className="dropdown-item" type="button">Indel</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Inframe Deletion', null, cancerTypeName)} className="dropdown-item" type="button">Inframe Deletion</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Inframe insertion', null, cancerTypeName)} className="dropdown-item" type="button">Inframe insertion</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Inversion', null, cancerTypeName)} className="dropdown-item" type="button">Inversion</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Frameshift', null, cancerTypeName)} className="dropdown-item" type="button">Frameshift</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Stop Gain', null, cancerTypeName)} className="dropdown-item" type="button">Stop Gain</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Stop loss', null, cancerTypeName)} className="dropdown-item" type="button">Stop loss</button>
                                                <button onClick={() => filterGraphs(filterVal, 'FUSION', null, cancerTypeName)} className="dropdown-item" type="button">FUSION</button>
                                                <button onClick={() => filterGraphs(filterVal, 'Duplication', null, cancerTypeName)} className="dropdown-item" type="button">Duplication</button>

                                                <button onClick={() => AllGraphsList()} className="dropdown-item" type="button">All Variant</button>
                                            </div>
                                        </div>

                                        <div className="btn-group range_button">


                                            <button className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                                <i className="zmdi zmdi-calendar-note"></i>{filterVal ? filterVal : 'Time Range'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right ">
                                                <button onClick={() => filterGraphs('Last 1 week', AlkVariationFilter, alkDetecionStatus, cancerTypeName)} className="dropdown-item" type="button">Last 1 Week</button>
                                                <button onClick={() => filterGraphs('Last 15 days', AlkVariationFilter, alkDetecionStatus, cancerTypeName)} className="dropdown-item" type="button">Last 15 Days</button>
                                                <button onClick={() => filterGraphs('Last 1 Month', AlkVariationFilter, alkDetecionStatus, cancerTypeName)} className="dropdown-item" type="button">Last 1 month</button>
                                                <button onClick={() => filterGraphs('Last 3 Month', AlkVariationFilter, alkDetecionStatus, cancerTypeName)} className="dropdown-item" type="button">Last 3 months</button>
                                                <button onClick={() => filterGraphs('Last 6 Month', AlkVariationFilter, alkDetecionStatus, cancerTypeName)} className="dropdown-item" type="button">Last 6 months</button>
                                                <button onClick={() => filterGraphs('Last 1 year', AlkVariationFilter, alkDetecionStatus, cancerTypeName)} className="dropdown-item" type="button">Last 1 year</button>
                                                <button onClick={() => AllGraphsList()} className="dropdown-item" type="button">All Time</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.content-header --> */}

                <section className="content">
                    <div className="container-fluid px-0">
                        <div className="rounded bg-white">
                            <div className="row ">
                                <div className="col-lg-3 col-md-6">
                                    <div className='row'>
                                        <div className="col-12 " style={{ 'height': 194 }}>
                                            <div className="card h-100">
                                                <div className='grap-title'>
                                                    <h6 className='mb-0 py-1'>Number of cases</h6>
                                                    {/* <img src={landScape} /> */}
                                                </div>
                                                <div className='simple-number'>
                                                    <h2 className='mb-0'>{commafy(allGraphData?.patientCount)}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ 'height': 194, marginTop: 15 }}>
                                            <div className="card h-100">
                                                <div className='grap-title'>
                                                    <h6 className='mb-0 py-1'>Primary  Sites</h6>
                                                </div>
                                                <div className='simple-number'>
                                                    <h2 className='mb-0'>{commafy(allGraphData?.cancerSiteCount)}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 pl-lg-0 pl-md-0 mt-md-0 mt-sm-3 mt-lg-0 pie_charts pdl1_charts pdl1_status primary" >
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Primary Site Distribution</h6>
                                            <div onClick={() => primaryModal()}>
                                                <img src={expand} />
                                            </div>
                                        </div>
                                        {primarySiteDistribution?.length > 0 ?
                                            <ResponsiveContainer width="100%" height="100%" className='primary_pie'>
                                                <PieChart width={300} height={400}>
                                                    {/* <Legend verticalAlign="top" align="top" /> */}
                                                    <Pie
                                                        data={primarySiteDistribution}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={PrimarySiteCustomizedLabel}
                                                        outerRadius={'80%'}
                                                        innerRadius={'35%'}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {primarySiteDistribution.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.cancerSiteName).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.cancerSiteName)[0].code : COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip content={<CustomTooltip />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                            :
                                            <EmptyPieChart width={300} height={400} outerRadius='80%' innerRadius='35%' />
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 pl-lg-0  mt-3 mt-sm-3 mt-lg-0  pie_charts pdl1_charts pdl1_status" >
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>NTRK Detection Status</h6>
                                            <div onClick={() => alkDetecionModal()}>
                                                <img src={expand} />
                                            </div>
                                        </div>
                                        {alkStatus?.length > 0 ?
                                            <ResponsiveContainer width="100%" height="100%">
                                                <PieChart width={300} height={400}>
                                                    <Legend verticalAlign="top" align="top" />
                                                    <Pie
                                                        data={alkStatus}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={TMBMSICustomizedLabel}
                                                        outerRadius={'60%'}
                                                        innerRadius={'25%'}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {alkStatus.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={entry.name === "Detected" ? '#EB455F' : '#323D7B'} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip content={<TmbMsiCustomTooltip />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                            :
                                            <EmptyPieChart width={300} height={400} outerRadius='60%' innerRadius='25%' />

                                        }

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 pl-0 pl-sm-3 pl-md-0 mt-3 mt-lg-0  pie_charts pdl1_charts pdl1_status primary" >
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>NTRK Variant Types</h6>
                                            <div onClick={() => alkVariationModal()}>
                                                <img src={expand} />
                                            </div>
                                        </div>
                                        {alkVariations?.length > 0 ?
                                            <ResponsiveContainer width="100%" height="100%" className='primary_pie'>
                                                <PieChart width={300} height={400}>
                                                    {/* <Legend verticalAlign="top" align="top" /> */}
                                                    <Pie
                                                        data={alkVariations}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={PrimarySiteCustomizedLabel}
                                                        outerRadius={'80%'}
                                                        innerRadius={'35%'}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {alkVariations.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.cancerSiteName).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.cancerSiteName)[0].code : COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip content={<AlkVariantCustomTooltip />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                            :
                                            <EmptyPieChart width={300} height={400} outerRadius='80%' innerRadius='35%' />

                                        }
                                    </div>
                                </div>
                            </div>

                            <div style={{}}>
                                <div className="col px-0" style={{ 'height': 520, marginTop: 14, marginBottom: 14 }}>
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Top 20 Cities (NTRK Detected / NTRK Undetected) </h6>
                                            <div onClick={() => citiesModal()}>
                                                <img src={expand} className='expandImage' />
                                            </div>
                                        </div>
                                        {top20CitiesData &&
                                            <ResponsiveContainer width="100%" height="100%">
                                                <BarChart
                                                    width={500}
                                                    height={300}
                                                    data={top20CitiesData}
                                                    margin={{
                                                        top: 30,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 40,
                                                    }}
                                                >
                                                    {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                    <XAxis dataKey="name" interval={0} tickLine={false} angle={-35} textAnchor="end" dx={15}
                                                        tick={<CustomizedXAxisTick />}
                                                    />
                                                    <YAxis />
                                                    <Tooltip cursor={{ fill: '#f7f7f7' }} />
                                                    {/* <Legend /> */}
                                                    {/* <Brush dataKey='name' height={30} stroke="#8884d8"/> */}
                                                    <Bar
                                                        dataKey="value" stackId="a" fill="#f4908e" barSize={40}
                                                    >
                                                        <LabelList dataKey="value" content={renderCustomizedLabel} />
                                                        {
                                                            top20CitiesData.map((entry, index) => (
                                                                <Cell onClick={() => hospitalLocation(entry)} key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                            ))
                                                        }
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="col px-0 mb-2" style={{ 'height': 750, marginTop: 8 }}>
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Hospitals ({hospitalName?.trim()})</h6>
                                            <div onClick={() => hospitalModal()}>
                                                <img src={expand} className='expandImage' />
                                            </div>
                                        </div>
                                        <ResponsiveContainer width="100%" height={650}>
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={hospitalList}
                                                margin={{
                                                    top: 25,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                <XAxis dataKey="name"
                                                    // tick={{ height: 55 }}
                                                    // interval={0}
                                                    // angle={-25}
                                                    // textAnchor="end"
                                                    // height={130}
                                                    // tickLine={false}

                                                    tick={<CustomizedXAxisTick />}
                                                    interval={0}
                                                    tickLine={false}
                                                    height={100}

                                                />
                                                <YAxis />
                                                <Tooltip cursor={{ fill: '#f7f7f7' }} />
                                                <Bar
                                                    dataKey="value" stackId="a" fill="#f4908e" barSize={40}
                                                >
                                                    <LabelList dataKey="value" content={renderCustomizedLabel} />

                                                    {
                                                        hospitalList?.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                        ))
                                                    }
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>

                                    </div>
                                </div>
                            </div>

                        </div></div>
                </section>


                {/* Primary Site Distribution */}
                <Modal
                    isOpen={primarySite}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={primarycloseModal}
                    className="Modal scroll_hiding"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>Primary Site Distribution</h6>
                            <div className='close_icon' onClick={() => primarycloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {primarySiteDistribution &&
                            <ResponsiveContainer width="100%" height={600} className='primary_pie'>
                                <PieChart width={300} height={400}>
                                    {/* <Legend verticalAlign="top" align="top" /> */}
                                    <Pie
                                        data={primarySiteDistribution}
                                        cx="50%"
                                        cy="40%"
                                        labelLine={false}
                                        label={PrimarySiteCustomizedLabel}
                                        outerRadius={'70%'}
                                        innerRadius={'30%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {primarySiteDistribution.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.cancerSiteName).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.cancerSiteName)[0].code : COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<CustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>
                        }
                    </div>
                </Modal>

                {/* ALK Variations */}
                <Modal
                    isOpen={alkVariation}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={alkVariationcloseModal}
                    className="Modal scroll_hiding"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>NTRK Variant Types</h6>
                            <div className='close_icon' onClick={() => alkVariationcloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {alkVariations &&
                            <ResponsiveContainer width="100%" height={600} className='primary_pie'>
                                <PieChart width={300} height={400}>
                                    {/* <Legend verticalAlign="top" align="top" /> */}
                                    <Pie
                                        data={alkVariations}
                                        cx="50%"
                                        cy="40%"
                                        labelLine={false}
                                        label={PrimarySiteCustomizedLabel}
                                        outerRadius={'70%'}
                                        innerRadius={'30%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {alkVariations.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.cancerSiteName).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.cancerSiteName)[0].code : COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<AlkVariantCustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>
                        }
                    </div>
                </Modal>

                {/* alk Detection */}
                <Modal
                    isOpen={alkDetection}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={alkDetecioncloseModal}
                    className="Modal scroll_hiding"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>NTRK Detection Status</h6>
                            <div className='close_icon' onClick={() => alkDetecioncloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {alkStatus &&
                            <ResponsiveContainer width="100%" height={600}>
                                <PieChart width={300} height={400}>
                                    <Legend verticalAlign="top" align="top" />
                                    <Pie
                                        data={alkStatus}
                                        cx="50%"
                                        cy="42%"
                                        labelLine={false}
                                        label={TMBMSICustomizedLabel}
                                        outerRadius={'70%'}
                                        innerRadius={'30%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {alkStatus.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.name === "Detected" ? '#EB455F' : '#323D7B'} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<TmbMsiCustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>
                        }
                    </div>
                </Modal>


                {/*Cities */}
                <Modal
                    isOpen={citiesOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={citiesCloseModal}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div>
                            <div className='grap-title Modal-top-cn'>
                                <h6 className='mb-0 py-1'>Top 20 Cities (NTRK Detected / NTRK Undetected)s</h6>
                                <div className='close_icon' onClick={() => citiesCloseModal()}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                </div>
                            </div>
                            {top20CitiesData &&
                                <ResponsiveContainer width="100%" height={600}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={top20CitiesData}
                                        margin={{
                                            top: 30,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        {/* <Legend /> */}
                                        {/* <Brush dataKey='name' height={30} stroke="#8884d8"/> */}
                                        <Bar
                                            dataKey="value" stackId="a" fill="#f4908e" barSize={40}
                                        >
                                            <LabelList dataKey="value" content={renderCustomizedLabel} />
                                            {
                                                top20CitiesData.map((entry, index) => (
                                                    <Cell onClick={() => console.log('entry', entry)} key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                ))
                                            }
                                        </Bar>


                                        {/* <Bar dataKey="pv" stackId="a" fill="#f4908e" barSize={40} /> */}
                                        {/* <Bar dataKey="uv" stackId="a" fill="#7e96ff" barSize={40} />
                                        <Bar dataKey="amt" stackId="a" fill="#fdd08e" barSize={40} /> */}
                                    </BarChart>
                                </ResponsiveContainer>
                            }

                        </div>
                    </div>
                </Modal>

                {/*hospital */}
                <Modal
                    isOpen={hospitalOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={hospitalCloseModal}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div>
                            <div className='grap-title Modal-top-cn'>
                                <h6 className='mb-0 py-1'>Hospitals ({hospitalName?.trim()})</h6>
                                <div className='close_icon' onClick={() => hospitalCloseModal()}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                </div>
                            </div>
                            <ResponsiveContainer width="100%" height={600}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={hospitalList}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        // bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <XAxis dataKey="name"
                                        tick={<CustomizedXAxisTick />}
                                        interval={0}
                                        tickLine={false}
                                        height={120} />
                                    <YAxis />
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    <Bar
                                        dataKey="value" stackId="a" fill="#f4908e" barSize={40}
                                    >
                                        <LabelList dataKey="value" content={renderCustomizedLabel} />
                                        {
                                            hospitalList.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                            ))
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </Modal>

            </div>
            <div className="footer_logo mt-2">
                <div>
                    <p className='mb-0'>4baseCare Onco Solutions Pvt. Ltd. All rights reserved. 2024-2025.</p>
                </div>
                <div className='note_img mr-1'>
                    <img src={company_logo} />
                </div>

            </div>
        </div>
    )
}
export default NTRKAnyalsis;