import React, { useEffect, useContext, useState } from "react";
import Dashboard from "./Components/Dashboard";
import Parts from "./Components/Parts";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { setDefaultHeader, apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { AuthContext } from "../../Context/context";
import { LoaderContext, UserDatailContext } from "../../Context/userContext";
import { CancerSiteColors, COLORS } from '../../utils/constants'
import { BodyLayout, Nasopharynx } from "./Components/Parts";
const DashboardScreen = () => {
    const history = useNavigate()
    const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [IsLoading, setIsLoading] = useContext(LoaderContext);
    const [userDetails, setUserDetails] = useContext(UserDatailContext);
    const [allGraphData, setAllGraphData] = useState([])
    const [indiaMapData, setIndiaMapData] = useState([])
    const [cancerSiteRestOfData, setCancerSiteRestOf] = useState([])
    const [cancerSiteTop10Data, setCancerSiteTop10] = useState([])
    const [filterVal, setFilterVal] = useState('')
    const [growthOfSimple, setGrowthOfSimple] = useState([])
    const [genderCancerSite, setGenderCancerSite] = useState([])
    const [genderAgeGroup, setGenderAgeGroup] = useState([])
    const [tmbData, setTmbData] = useState([])
    const [msiData, setMsiData] = useState([])
    const [pdlData, setPdlData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [top10totalCount, setTop10TotalCount] = useState(0)
    const [primarySiteCount, setPrimarySiteCount] = useState(0)
    const [psiteCount,setPSiteCount] = useState(0)
    const [top20Loader, setTop20Loader] = useState(false)
    const [top20BoiMarkerList, setTop20BoiMarkerList] = useState([])
    const [CommonBiomarkersList, setCommonBiomarkersList] = useState([])
    const [CommonBiomarkersKeys, setCommonBiomarkersKeys] = useState([])
    const [top20BoiMarkerKeys, setTop20BoiMarkerKeys] = useState([])
   const [treatmentDetails, setTreatmentDetails] = useState([]);
   const [drugTherapy, setDrugTherapy] = useState([]);
   const [biologicalTherapy, setBiologicalTherapy] = useState([])
   const [canSiteList,setCanSiteList] = useState([]);
   const [humanBodyData,setHumanBodyData] = useState([]);

    const [southCount, setSouthCount] = useState(0)
    const [EastCount, setEastCount] = useState(0)
    const [NorthCount, setNorthCount] = useState(0)
    const [WestCount, setWestCount] = useState(0)


    useEffect(() => {
        AllGraphsList()
    }, [])

    function bodyDataFun(array) {
        const humanData =[
            {name:'Nasopharynx',count:100,id:'Nasopharynx'},
            {name:'OralCavity',count:20,id:'OralCavity'},
            {name:'Kidney',count:65,id:'Kidney'},
            {name:'Testis',count:76,id:'Testis'},
            {name:'Eye',count:113,id:'Eye'},
            {name:'Lung',count:78,id:'Lung'},
            {name:'Heart',count:92,id:'Heart'},
            {name:'Brain',count:57,id:'Brain'},
            {name:'Breast',count:0,id:'Breast'},
            {name:'Thyroid',count:127,id:'Thyroid'},
            {name:'AdrenalGland',count:198,id:'AdrenalGland'},
            {name:'Pancreas',count:28,id:'Pancreas'},
           // {name:'Thyroid',count:121},
            {name:'Femur',count:21,id:'Femur'},
            {name:'CirculatorySystem',count:41,id:'CirculatorySystem'},
            {name:'Stomach',count:73,id:'Stomach'},
            {name:'Small Intestine',count:211,id:'Small Intestine'},
            {name:'Appendix',count:19,id:'Appendix'},
            {name:'Rectum',count:67,id:'Rectum'},
            {name:'Anus',count:17,id:'Anus'},
            {name:'Duodenum',count:126,id:'Duodenum'},
            {name:'Esophagus',count:102,id:'Esophagus'},
            {name:'Liver',count:12,id:'Liver'},
            {name:'Gallbladder',count:81,id:'Gallbladder'},
            {name:'SalivaryGland',count:56,id:'SalivaryGland'},
            {name:'Colon',count:44,id:'Colon'},
            {name:'Urinary Bladder',count:15,id:'Urinary Bladder'},
            {name:'Prostate',count:37,id:'Prostate'},
            {name:'SkeletalMuscle',count:10,id:'SkeletalMuscle'},
            {name:'Adipose',count:100,id:'Adipose'},
            {name:'Skin',count:189,id:'Skin'},
        ]
        const dataState = humanData.map((obj,i) => {
       //     color = CancerSiteColors.filter((item) => item.name === obj).length > 0 ? CancerSiteColors.filter((item) => item.name === obj)[0].code : '',{
            if (obj.name === array?.filter((item) => { return item.cancerSiteName === obj.name })[0]?.cancerSiteName) {
                return { ...obj, count: array?.filter((item) => { return item.cancerSiteName=== obj.name })[0]?.cancerSiteCount,
                id:array?.filter((item) => { return item.cancerSiteName === obj.name })[0]?.cancerSiteName,
                fill:CancerSiteColors.filter((item) => item.name === obj).length > 0 ? CancerSiteColors.filter((item) => item.name === obj)[0].code:COLORS[i % COLORS.length] };
            }
            return obj;
        });
        // const dataState = humanData.map(obj => {
        //     if (obj.name === array?.filter((item) => { return item.cancerSiteName === obj.name })[0]?.cancerSiteName) {
        //         return { ...obj, value: array?.filter((item) => { return item.cancerSiteName=== obj.name })[0]?.cancerSiteCount };
        //     }
        //     return obj;
        // });
        console.log("human",dataState);
        return dataState
    }

    // const dataKeys = uniqueKeys.filter(key => key !== 'geneName').map((key, i) => (
    //     color = CancerSiteColors.filter((item) => item.name === key).length > 0 ? CancerSiteColors.filter((item) => item.name === key)[0].code : '',
    //     {
    //         dataKey: key,
    //         // /fill: key == CancerSiteColors.find((item) => item.name === key)?.code || COLORS[i % COLORS.length]
    //         // fill: key == CancerSiteColors.filter((item) => item.name == key).length > 0 ? CancerSiteColors.filter((item) => item.name == key)[0].code : COLORS[i % COLORS.length]
    //         fill: color ? color : COLORS[i % COLORS.length]
    //     }));
   // setTop20BoiMarkerKeys(dataKeys)

    function mapDataFun(array) {
        const regionData =
            [
                { id: 'AP', zone: 'su', state: 'Andhra Pradesh', value: 0 },
                { id: 'AR', zone: 'ea', state: 'Arunachal Pradesh', value: 0 },
                { id: 'AS', zone: 'ea', state: 'Assam', value: 0 },
                { id: 'BR', zone: 'ea', state: 'Bihar', value: 0 },
                { id: 'CT', zone: 'ea', state: 'Chhattisgarh', value: 0 },
                { id: 'GA', zone: 'we', state: 'Goa', value: 0 },
                { id: 'GJ', zone: 'we', state: 'Gujarat', value: 0 },
                { id: 'HR', zone: 'no', state: 'Haryana', value: 0 },
                { id: 'HP', zone: 'no', state: 'Himachal Pradesh', value: 0 },
                { id: 'JH', zone: 'ea', state: 'Jharkhand', value: 0 },
                { id: 'KA', zone: 'su', state: 'Karnataka', value: 0 },
                { id: 'KL', zone: 'su', state: 'Kerala', value: 0 },
                { id: 'MP', zone: 'we', state: 'Madhya Pradesh', value: 0 },
                { id: 'MH', zone: 'we', state: 'Maharashtra', value: 0 },
                { id: 'MN', zone: 'ea', state: 'Manipur', value: 0 },
                { id: 'ML', zone: 'ea', state: 'Meghalaya', value: 0 },
                { id: 'MZ', zone: 'ea', state: 'Mizoram', value: 0 },
                { id: 'NL', zone: 'ea', state: 'Nagaland', value: 0 },
                { id: 'OD', zone: 'ea', state: 'Odisha', value: 0 },
                { id: 'PB', zone: 'no', state: 'Punjab', value: 0 },
                { id: 'RJ', zone: 'we', state: 'Rajasthan', value: 0 },
                { id: 'SK', zone: 'ea', state: 'Sikkim', value: 0 },
                { id: 'TN', zone: 'su', state: 'Tamil Nadu', value: 0 },
                { id: 'TG', zone: 'su', state: 'Telangana', value: 0 },
                { id: 'TR', zone: 'ea', state: 'Tripura', value: 0 },
                { id: 'UK', zone: 'no', state: 'Uttarakhand', value: 0 },
                { id: 'UP', zone: 'no', state: 'Uttar Pradesh', value: 0 },
                { id: 'WB', zone: 'ea', state: 'West Bengal', value: 0 },
                { id: 'AN', zone: '', state: 'Andaman and Nicobar Islands', value: 0 },
                { id: 'CH', zone: 'no', state: 'Chandigarh', value: 0 },
                { id: 'DN', zone: 'we', state: 'Dadra and Nagar Haveli', value: 0 },
                { id: 'DD', zone: 'we', state: 'Daman and Diu', value: 0 },
                { id: 'DL', zone: 'we', state: 'Delhi', value: 0 },
                { id: 'JK', zone: 'no', state: 'Jammu and Kashmir', value: 0 },
                { id: 'LA', zone: 'su', state: 'Ladakh', value: 0 },
                { id: 'LD', zone: 'su', state: 'Lakshadweep', value: 0 },
                { id: 'PY', zone: 'su', state: 'Puducherry', value: 0 },
            ];
        const newState = regionData.map(obj => {
            if (obj.state === array?.filter((item) => { return item.stateName === obj.state })[0]?.stateName) {
                return { ...obj, value: array?.filter((item) => { return item.stateName === obj.state })[0]?.stateCount};
            }
            return obj;
        });
        console.log("map",newState);
        return newState
    }
  
    function tmbDataChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.name === null) {
                return { ...obj, name: 'NA', z:250.9};
            } else if(obj?.name === "High"){
                return {...obj,z:300.5}
            } else if(obj?.name === "Low"){
                return {...obj,z:450.8}
            }

        })
        console.log("tmbnew",newState);
        return newState
    }

    function msiDataChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.name === "Stable") {
                return { ...obj, name: 'Stable'};
            } else if (obj?.name === "Unstable") {
                return { ...obj, name: 'Unstable' };
            } else if (obj?.name === null) {
                return { ...obj, name: 'NA'};
            }
            return obj;
        })
        console.log("newState",newState);
        return newState
      
    }

    function pdlDataChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.name === "Negative") {
                return { ...obj, name: 'Negative' };
            }
            else if (obj?.name === "Positive") {
                return { ...obj, name: 'Positive' };
            }
            if (obj?.name === null) {
                return { ...obj, name: 'NA' };
            }
            return obj;
        })
        console.log("new",newState);
        return newState 
    }

    function drugDataChange(array){
        const newState = array?.map((obj) => {
            if(obj?.name === "CHEMOTHERAPY") {
                return {...obj,name: 'Chemo Therapy'};
            }
            else if (obj?.name === "DRUG_THERAPY") {
                return { ...obj, name: 'Hormonal Therapy' };
            }
            return obj;
        })
        return newState
    }
    function treatmentDataChange(array){
        const newState = array?.map((obj) =>{
            if(obj?.name === "Surgery") {
                return {...obj,name:"Surgical Therapy"};
            }
            else if (obj?.name === "Radiation"){
                return {...obj,name: 'Radiation Therapy'};
            }
            if (obj?.name === "Drugs Therapy"){
                return{ ...obj,name:'Drug Therapy'}
            }
            return obj;
        })
        return newState
    }
    function biologicalDataChange(array){
        const newState = array?.map((obj) =>{
            if(obj?.name === "Targeted therapy") {
                return {...obj,name:"Targeted Therapy"};
            }
            return obj;
        })
        return newState
    }
  
    function renameKeys(obj, newKeys) {
        const keyValues = Object.keys(obj).map(key => {
            const newKey = newKeys[key] || key;
            return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    }


    function apiResponse(data) {
        setAllGraphData(data.payLoad);
        console.log("dt",allGraphData);
        setPrimarySiteCount(data?.payLoad?.cancerSiteCountList?.length);
    //    const treatment = data.payload?.pdl1List?.sort((a,b)=>b.value-a.value);
    //    console.log("tr",treatment); 
        const initialValue = 0;
        const sortedData = data.payLoad?.cancerSiteCountList?.sort((a, b) => b.cancerSiteCount - a.cancerSiteCount);
        console.log("sort",sortedData);
        const filterCancerData = sortedData.filter((item) => item.cancerSiteCount >= 6)
        console.log("filterr",filterCancerData);
        const sortArray = filterCancerData
        const top10data = sortArray?.slice(0, 10);
        console.log("top",top10data);
         setPSiteCount(top10data?.length);
        filterCancerData.splice(0, 10);
        const sumWithInitial = filterCancerData.reduce(
            (accumulator, currentValue) => accumulator + currentValue.cancerSiteCount,
            initialValue
        );
        const sumWithTop10Initial = top10data.reduce(
            (accumulator, currentValue) => accumulator + currentValue.cancerSiteCount,
            initialValue
           
        );
        console.log("countvalue",sumWithTop10Initial)
        const indiaData = mapDataFun(data.payLoad?.stateCountList)
        const southData = indiaData.filter((item) => item.zone === 'su' ? item : '')
        const eastData = indiaData.filter((item) => item.zone === 'ea' ? item : '')
        const westData = indiaData.filter((item) => item.zone === 'we' ? item : '')
        const northData = indiaData.filter((item) => item.zone === 'no' ? item : '')

        const southCount = southData?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        const eastCount = eastData?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        const westCount = westData?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        const northCount = northData?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
      

        setSouthCount(southCount)
        setEastCount(eastCount)
        setNorthCount(northCount)
        setWestCount(westCount)
        setTotalCount(sumWithInitial);
        setTop10TotalCount(sumWithTop10Initial);
        setCancerSiteTop10(top10data);
        setCancerSiteRestOf(filterCancerData);
        setIndiaMapData(mapDataFun(data.payLoad?.stateCountList));
        setHumanBodyData(bodyDataFun(data.payLoad?.cancerSiteCountList));
        setTmbData(tmbDataChange(data.payLoad?.tmbList));
        setMsiData(msiDataChange(data.payLoad?.msiList));
        setPdlData(pdlDataChange(data.payLoad?.pdl1List));
       setTreatmentDetails(treatmentDataChange(data.payLoad?.treatmentEventDetails));
       setDrugTherapy(drugDataChange(data.payLoad?.drugTherapyDetails)); 
       setBiologicalTherapy(biologicalDataChange(data.payLoad?.biologicalTherapyDetails)); 
       setCanSiteList(data.payLoad?.cancerSiteCountList)

        console.log("treat",treatmentDetails)
        const newMaleFemale = { maleCount: "Male", femaleCount: 'Female' };
     //   const newValue = {value:"y"}
        var renamedObj = []
        var genderCancerSiteObj = []
        var genderAgeGroupObj = []
    

        data.payLoad?.genderCancerSiteList?.map((item) => {
            return (
                genderCancerSiteObj.push(renameKeys(item, newMaleFemale))
            )
        })
        console.log("gender",genderCancerSiteObj);
        data.payLoad?.ageGroupGenderList?.map((item) => {
            return (
                genderAgeGroupObj.push(renameKeys(item, newMaleFemale))
            )
        })
        setGrowthOfSimple(renamedObj)
        const cancerSite = genderCancerSiteObj?.filter((item) => {
            if ((item.Female !== 0 || item.Male !== 0)) {
                return item
            }
        })
        setGenderCancerSite(cancerSite)
        const genderAge = genderAgeGroupObj?.filter((item) => {
            if ((item.Female !== 0 || item.Male !== 0)) {
                return item
            }
        })
        setGenderAgeGroup(genderAge)
      
    }

    const AllGraphsList = async () => {
        setIsLoading(true)
        Top20BoimarkerFun(null)
        const { data } = await apiCall('GET', ApiEndPoint.AllGraphs)
        console.log("data1",data);
        if (data.success) {
            setFilterVal('')
            apiResponse(data)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    const Top20BoimarkerFun = async (value) => {
        setTop20Loader(true)
        const params = value ? value : null
        const top20Bio = [
            'EGFR',
            'ALK',
            "KRAS",
            'ROS1',
            'RET',
            'MET',
            'BRCA1',
            'BRCA2',
            'BRAF',
            'FGFR1',
            'ATM',
            'PIK3CA',
            'PALB2',
            'MSH2',
            'MSH6',
            'MLH3',
            'ERBB2',
            'RAD54L',
            'NRAS',
            'FGFR2',
            'FGFR3',
            'BRIP1',
            'FANCL'
        ]
        const { data } = await apiCall('POST', ApiEndPoint.BioMarkers, params)
        console.log("biooo",data)
        if (data.length > 0) {

            //Top 20 biomarkers

            const updatedData = data.map((item) => {
                const values = Object.values(item);
                const total = values.reduce((acc, count) => {
                    if (typeof count === 'number') {
                        return acc + count;
                    }
                    return acc;
                }, 0);
                return {
                    ...item,
                    total: total
                };
            });
            console.log("updated",updatedData)
            const sortedData = updatedData?.sort((a, b) => b.total - a.total);
            const top10data = sortedData?.slice(0, 20);
            setTop20BoiMarkerList(top10data)
            console.log("top20",top10data)
            const allKeys = top10data.reduce((acc, obj) => {
                return [...acc, ...Object.keys(obj)];
            }, []);

            const uniqueKeys = [...new Set(allKeys)];
            console.log("unique",uniqueKeys);
            var color = ''
            console.log('uniqueKeys: ', CancerSiteColors.filter((item) => item.name === 'Lung'));
            // Generate data keys for the bar chart
            const dataKeys = uniqueKeys.filter(key => key !== 'geneName').map((key, i) => (
                color = CancerSiteColors.filter((item) => item.name === key).length > 0 ? CancerSiteColors.filter((item) => item.name === key)[0].code : '',
                {
                    dataKey: key,
                    // /fill: key == CancerSiteColors.find((item) => item.name === key)?.code || COLORS[i % COLORS.length]
                    // fill: key == CancerSiteColors.filter((item) => item.name == key).length > 0 ? CancerSiteColors.filter((item) => item.name == key)[0].code : COLORS[i % COLORS.length]
                    fill: color ? color : COLORS[i % COLORS.length]
                }));
            setTop20BoiMarkerKeys(dataKeys)
            console.log('dataKeys: ', dataKeys);

            //Prevalence of Common Biomarkers across cancers


            const updatedCommonBiomarkers = data.map((item) => {
                const values = Object.values(item);
                const total = values.reduce((acc, count) => {
                    if (typeof count === 'number') {
                        return acc + count;
                    }
                    return acc;
                }, 0);
                return {
                    ...item,
                    total: total
                };
            });

            const CommonBiomarkers = updatedCommonBiomarkers.filter(item => top20Bio.includes(item.geneName));
            const CommonBiomarkersAllKeys = CommonBiomarkers.reduce((acc, obj) => {
                return [...acc, ...Object.keys(obj)];
            }, []);

            const CommonUniqueKeys = [...new Set(CommonBiomarkersAllKeys)];
            // Generate data keys for the bar chart
            var colors = ''
            const CommonBiomarkersDataKeys = CommonUniqueKeys.filter(key => key !== 'geneName').map((key, i) => (
                colors = CancerSiteColors.filter((item) => item.name === key).length > 0 ? CancerSiteColors.filter((item) => item.name === key)[0].code : '',
                {
                    dataKey: key,
                    fill: colors ? colors : COLORS[i % COLORS.length]
                }));
            setCommonBiomarkersKeys(CommonBiomarkersDataKeys)
            setCommonBiomarkersList(CommonBiomarkers)
            setTop20Loader(false)
        } else {
            setTop20Loader(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const filterGraphs = async (value) => {
        setIsLoading(true)
        setFilterVal(value)
        Top20BoimarkerFun(value)
      //  const params = value
      const params = {
        dateFilter:value ? value : null
      }
        const { data } = await apiCall('POST', ApiEndPoint.FilterGraphs, params)
        if (data.success) {
            apiResponse(data)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    return (
        <>
            <Dashboard
                allGraphData={allGraphData}
                userDetails={userDetails}
                indiaMapData={indiaMapData}
                humanBodyData ={humanBodyData}
                filterGraphs={(val) => filterGraphs(val)}
                AllGraphsList={() => AllGraphsList()}
                filterVal={filterVal}
                totalCount={totalCount}
                growthOfSimple={growthOfSimple}
                genderCancerSite={genderCancerSite}
                genderAgeGroup={genderAgeGroup}
                tmbData={tmbData}
                msiData={msiData}
                pdlData={pdlData}
                treatmentDetails={treatmentDetails}
                drugTherapy={drugTherapy}
                biologicalTherapy={biologicalTherapy}
                canSiteList ={canSiteList}
                cancerSiteRestOfData={cancerSiteRestOfData}
                cancerSiteTop10Data={cancerSiteTop10Data}
                top10totalCount={top10totalCount}
                primarySiteCount={primarySiteCount}
                psiteCount = {psiteCount}
                southCount={southCount}
                EastCount={EastCount}
                NorthCount={NorthCount}
                WestCount={WestCount}
                top20Loader={top20Loader}
                top20BoiMarkerList={top20BoiMarkerList}
                top20BoiMarkerKeys={top20BoiMarkerKeys}
                CommonBiomarkersKeys={CommonBiomarkersKeys}
                CommonBiomarkersList={CommonBiomarkersList}
                IsLoading={IsLoading}

            />
        </>
    )
}
export default DashboardScreen