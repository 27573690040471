import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation,routerLink } from 'react-router-dom';
import { SideMenuHideShowContext, UserDatailContext } from "../Context/userContext";
import { AuthContext } from "../Context/context";
import OncoBizLogo from '../assets/images/oncoBiz_logo.svg'
import userPic from '../assets/images/user_account.png'
import userimg from '../assets/images/user_icon.jpg'

export default function New_header(props) {
    const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [sideMeneShowHide, setSideMeneShowHide] = useContext(SideMenuHideShowContext);
    const [userDetails, setUserDetails] = useContext(UserDatailContext);
    const [dropdownShow, setDropDownShow] = useState(null)
    const [initialRender, setInitialRender] = useState(true); 
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [activeTab, setActiveTab] = useState(splitLocation[1])
    const ids = ["anjali@4basecare.com","kshitij@user", "oncoviz-analyst@mailinator.com"];
   // const ids = ["anjali@4basecare.com","kshitij@user"];
    const value= ids?.includes(userDetails?.email)
    function logout(params) {
        navigate('/login')
        signOutFun()
    }
    // useEffect(() => {
    //     if (initialRender) {
    //         if (userDetails.email === 'roche@user') {
    //             navigate('/roche');
    //         } else {
    //             navigate('/Dashboard');
    //         }
    //         setInitialRender(false); 
    //     }
    // }, [initialRender]);

    useEffect(() => {
        if (userDetails.email === 'roche@user') {
            navigate('/PDLAnyalsis');
        // } else {
        //     navigate('/Dashboard');
        }
    }, []);

    useEffect(() => {
        if (userDetails.email === 'roche@user') {
          //  navigate('/roche');
            if (location.pathname !== '/PDLAnyalsis' && location.pathname !== '/login') {
                logout()
              //  navigate('/login');
            }
        } else if(!value ){
           // navigate('/Dashboard')
            if (location.pathname !== '/' && location.pathname !== '/login') {
                logout()
        }
    }
      
    }, [location.pathname, userDetails.email]);
   
    
    

  

    useEffect(() => {
        window.scrollTo({ top: 0 });

        if (location.pathname === "/Home" || activeTab === 'Home') {
            setActiveTab("/Home")
            setDropDownShow(null)
        }
        else if (location.pathname === "/" || activeTab === '') {
            setActiveTab("/")
            setDropDownShow(null)
        }
        else if (activeTab === 'GeneAnalysis') {
            setActiveTab('GeneAnalysis')

        }
        else if (location.pathname === "/PDLAnyalsis" || activeTab === 'PDLAnyalsis') {
            setActiveTab("PDLAnyalsis")
        }
        // else if (activeTab === '/ALKAnyalsis' ||activeTab === 'ALKAnyalsis') {
        //     setActiveTab('ALKAnyalsis')
        // }
        // else if (activeTab === '/NTRKAnyalsis' ||activeTab === 'NTRKAnyalsis') {
        //     setActiveTab('NTRKAnyalsis')
        // }
        // else if (activeTab === '/ROSAnyalsis' ||activeTab === 'ROSAnyalsis') {
        //     setActiveTab('ROSAnyalsis')
        // }
        // else if (activeTab === '/RETAnyalsis' ||activeTab === 'RETAnyalsis') {
        //     setActiveTab('RETAnyalsis')
        // }
        // else if (activeTab === '/EGFRAnyalsis' ||activeTab === 'EGFRAnyalsis') {
        //     setActiveTab('EGFRAnyalsis')
        // }
        // else if (activeTab === '/ERBB2Anyalsis' ||activeTab === 'ERBB2Anyalsis') {
        //     setActiveTab('ERBB2Anyalsis')
        // }

    }, [location.pathname, activeTab]);


    useEffect(() => {
        if (sideMeneShowHide) {
            setDropDownShow(null)
        } else {
            // setDropDownShow(null) 
        }
    }, [sideMeneShowHide])

    function homePage() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setActiveTab('/Home')
        setDropDownShow(null)
        navigate('/Home')
    }

    function dashboardPage() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setActiveTab('')
        setDropDownShow(null)
        navigate('/')
    }

    function PDLAnyalsisPage() {
        setActiveTab('PDLAnyalsis')
        navigate('/PDLAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    function HRRPage() {
        navigate('/HRR')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function ALKPage() {
        navigate('/ALKAnyalsis')
        setActiveTab('ALKAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function NTRKPage() {
        navigate('/NTRKAnyalsis')
        setActiveTab('NTRKAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function ROSKPage() {
        navigate('/ROSAnyalsis')
        setActiveTab('ROSAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function RETKPage() {
        navigate('/RETAnyalsis')
        setActiveTab('RETAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function EGFRKPage() {
        navigate('/EGFRAnyalsis')
        setActiveTab('EGFRAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function ERBB2Page() {
        navigate('/ERBB2Anyalsis')
        setActiveTab('ERBB2Anyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function GeneAnalytics(params) {
        setActiveTab('GeneAnalysis')
        setDropDownShow(!dropdownShow)
        // navigate('/ALKAnyalsis')
        //  navigate('/PDLAnyalsis')
        // setActiveTab('PDLAnalysis')
    }

   

    return (
        <header>
            <div className=" header-row ">
                <div className="header_left">
                    <div className="logo_section">
                        <div className="d-flex">
                            <div>
                                <img src="../../../assets/images/logo_icon.png" alt="" className="h-58" />
                            </div>
                            <div className="logo_title pl-2 mt-lg-1 mt-sm-0">
                                <h3 className="mb-0 font-weight-bold" >OncoBiz</h3>
                                {/* <img src={OncoBizLogo} /> */}
                                <a className="pl-1 text-cyan_light"> </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_right">
                    <div className="menu_list d-flex">
                        <ul className="list-unstyled d-flex mb-0 nav_list align-items-center">
         {ids?.includes(userDetails?.email) && <li onClick={() => homePage()}><a routerlinkactive="active" className={activeTab === 'Home' || location.pathname === '/Home' ? "is-active" : ''}>Home</a></li>}
         {userDetails.email!== "roche@user" && <li onClick={() => dashboardPage()}><a routerlinkactive="active" className={activeTab === '' || location.pathname === '/' ? "is-active" : ''}>Dashboard</a></li>}
         {/* {ids?.includes(userDetails?.email) && */} <li onClick={() => PDLAnyalsisPage()}><a routerlinkactive="active" className={activeTab === 'PDLAnyalsis' || location.pathname === '/PDLAnyalsis' ? "is-active" : ''} >PD-L1</a></li>
                            <li className="gene_list">
                                <a routerlink="" routerlinkactive="active"></a>
                                    <div className="dropdown" >
                                        {ids?.includes(userDetails?.email) && <a className={`dropbtn ${activeTab == 'ALKAnyalsis' || activeTab == 'NTRKAnyalsis' || activeTab == 'ROSAnyalsis' || activeTab == 'EGFRAnyalsis' || activeTab == 'RETAnyalsis' || activeTab == 'GeneAnalysis' || activeTab === 'ERBB2Anyalsis' ? "is-active" : ''}`} onClick={() => GeneAnalytics()}> Gene Analysis</a>}
                                        <div className="dropdown-content bg-white">
                                           <a className={`dropdown-item ${activeTab === 'ALKAnyalsis' || location.pathname === '/ALKAnyalsis' ? 'is-active' : ''}`} onClick={() => ALKPage()} > ALK Analysis</a>
                                            <a className={`dropdown-item ${activeTab === 'NTRKAnyalsis' || location.pathname === '/NTRKAnyalsis' ? "is-active" : ''}`} onClick={() => NTRKPage()}> NTRK Analysis</a>
                                            <a className={`dropdown-item ${activeTab === 'ROSAnyalsis' || location.pathname === '/ROSAnyalsis' ? "is-active" : ''}`} onClick={() => ROSKPage()}> ROS Analysis</a>
                                            <a className={`dropdown-item ${activeTab === 'RETAnyalsis' || location.pathname === '/RETAnyalsis' ? "is-active" : ''}`} onClick={() => RETKPage()}> RET Analysis</a>
                                            <a className={`dropdown-item ${activeTab === 'EGFRAnyalsis' || location.pathname === '/EGFRAnyalsis' ? "is-active" : ''}`} onClick={() => EGFRKPage()}> EGFR Analysis</a>
                                            <a className={`dropdown-item ${activeTab === 'ERBB2Anyalsis' || location.pathname === '/ERBB2Anyalsis' ? "is-active" : ''}`} onClick={() => ERBB2Page()}> ERBB2 Analysis</a>
                                        </div>
                                    </div>
                                </li>
                            <li className="gene_list">
                                <a routerlink="" routerlinkactive="active"></a>
                                    <div className="dropdown" >
                                        <img src={userimg} alt="" />
                                        <div className="dropdown-content bg-white">
                                            <a className="dropdown-item " onClick={() => logout()}> LogOut</a>
                                        </div>
                                    </div>
                                </li>



                        </ul>

                    </div>
                </div>
            </div >
        </header >
    )
}