import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSunburst from "highcharts/modules/sunburst"; // Import the Sunburst module
import { useNavigate } from 'react-router-dom';
import ToolTip from '@material-ui/core/Tooltip';
import Loader from "react-js-loader";
import Modal from 'react-modal';
import expand from './../../../assets/images/expand.svg';
import calendar from './../../../assets/images/calendar.png';
import topArrow from './../../../assets/images/export.svg';
import company_logo from './../../../assets/images/4bc.svg';
import EmptyPieChart from "../../../Components/EmptyPieChart";
import { MultiSelect } from "react-multi-select-component";
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, LabelList, Bar, CartesianGrid, XAxis, YAxis, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from "recharts";
// import { COLORS, CancerSiteColors, HospitalColor, tmbColor } from '../../../utils/constants'
import { COLORS, CancerSiteColors,CanSiteColors, HospitalColor, colrs,tmbColor, ColorValues, MSIColorValues } from '../../../utils/constants';
//import PDLAnyalsisScreen from '..';
const PDL = (props) => {
    const {pdlFilterVal, biologicalTherapy, drugTherapy, top10CanSiteList, MorphologyCount, morphologyTotal,userDetails,
        filterVal, top20CitiesData, biologicalTherapyTotal, drugsCountTotal,
         drugsCount, pdlCloneFilterVal, drugTherapyTotal,pdlexcel,
        primarySiteTotal, pdlCloneTotal, ageGender, totalPatientCount, tobaccoCount, msiTotal, msiValue,
        tmbTotal, tmbValue, familyHistoryVal, cancerSiteCount, pdlMultipleCloneVal, firstdegree,
        pdlStatusVal, pdlCloneVal, setPdlFilterVal, setFilterVal, setPdlCloneFilterVal,cansiteval,
        setPrimaryFilterVal, primaryFilterVal, setDrugFilterVal, drugFilterVal, setBiologicalFilterVal, biologicalFilterVal,
        hospNameData, hospName, hospCity, setHospName, pdlHospfun, cancerStage, cann, geneCancer, recistCriteria,IsLoading,originalCancerSearch,
        hospitalValue,CancersiteValue } = props
    const [citiesOpen, setCitiesOpen] = useState(false);
    const [hospitalOpen, sethospitalOpen] = useState(false);
    const [hospitalShow, sethospitalShow] = useState(false);
    const [primarySite, setprimarySite] = useState(false);
    const [drugOpen, setDrugOpen] = useState(false);
    const [biologicalOpen, setBiologicalOpen] = useState(false);
    const [drugsCountOpen, setDrugsCountOpen] = useState(false);
    const [activePart, setActivePart] = useState(null);
    const [hov, setHov] = useState(false);
    const [histpathOpen, setHistoPathOpen] = useState(false);
    const [tmbOpen, setTmbOpen] = useState(false);
    const [msiOpen, setMsiOpen] = useState(false);
    const [ageGenderOpen, setAgeGenderOpen] = useState(false);
    const [statusShow, setStatusShow] = useState(false)
    const [cloneShow, setCloneShow] = useState(false)
    const [bioShow, setBioShow] = useState(false)
    const [cancerShow, setCancerShow] = useState(false)
    const [recist, setRecist] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();
    const filters = ["Last 1 week", "Last 15 days", "Last 1 Month", "Last 3 Month"];
    const value = filters?.includes(filterVal)
    const formattedData = tmbValue.map((item, index) => ({ name: item[0], value: item[1] }));
    // const renderCustomLabel = ({ x, y, value }) => {
    //     console.log('Custom label called for:', `(${value})`);
    //     return (
    //       <text x={x} y={y} dy={-10} fill="#666" textAnchor="middle">{`(${value})`}</text>
    //     );
    //   };

    // const renderCustomLabel = (entry) => {
    //     console.log('Custom label called for:',  `${entry.biomarkerName} (${entry.total})`);
    //     return `${entry.biomarkerName} (${entry.total})`;

    //   };
    const renderCustomLabel = ({ x, y, value, index }) => {
        const { biomarkerName, total } = cancerStage[index];
        console.log('Custom label called for:', `${biomarkerName} (${total})`);
        return (
            <text x={x} y={y} dy={-10} fill="#666" textAnchor="middle">{total}</text>

            //   <text x={x} y={y} dy={-10} fill="#666" textAnchor="middle">{`(${total})`}</text>
        );
    };

    const allCancerSites = [
        'Lung',
        'Breast',
        'Large Intestine',
        'Pancreas',
        'Stomach',
        'Liver and intrahepatic bile ducts',
        'Esophagus',
        'Gallbladder',
        'Ovary',
        'Corpus Uteri',
        'Unknown primary',
        'Rectum',
        'Connective, subcutaneous and other soft tissues',
        'Prostate',
        'Brain',
        'Cervix Uteri',
        'Kidney',
        'Thyroid',
        'Other and unspecified parts of mouth',
        'Other parts of tongue',
        'Retroperitoneum and Peritoneum',
        'Urinary Bladder',
        'Skin',
        'Rectosigmoid junction',
        'Uterus',
        'Small Intestine',
        'Other ill defined sites',
        'Accessory sinuses',
        'Heart',
        'Intestinal tract',
        'Parotid gland',
        'Anus and Anal canal',
        'Gum',
        'Thymus',
        'Bones, joints, and articular cartilage of the limb',
        'Bones, joints and articular cartilage of other/ unspecified sites',
        'Other and unspecified major salivary gland',
        'Penis',
        'Nasopharynx',
        'Base of Tongue',
        'Larynx',
        'Eye',
        'Nasal cavity and middle ear',
        'Palate',
        'Other and unspecified urinary organs',
        'Floor of mouth',
        'Other endocrine glands',
        'Tonsil',
        'Lip',
        'Pyriform sinus',
        'Renal pelvis',
        'Testis',
        'Vagina',
        'Other and ill-defined sites in lip, oral cavity and pharynx',
        'Fallopian tube and other regions',
        'Ureter',
        'Spinal Cord and Other Central Nervous System',
        'Oropharynx',
        'Adrenal gland',
        'Hypopharynx',
        'Lymphnode',
        'Labia,Vulva',
        'Other unspecified male genital organs',
        'Cerebral Meninges'
    ];

    const filteredSites = originalCancerSearch.filter((site) =>
        site.name.toLowerCase().includes(searchInput.toLowerCase())
    );

   const handleButtonClick = (itemName) => {
    setPrimaryFilterVal(itemName);
    
}
    const optt = [
        { value: 'Lung', label: 'Lung' },
        { value: 'Breast', label: 'Breast' },
        { value: 'Large Intestine', label: 'Large Intestine' },
        { value: 'Pancreas', label: 'Pancreas' },
        { value: 'Stomach', label: 'Stomach' },
        { value: 'Liver and intrahepatic bile ducts', label: 'Liver and intrahepatic bile ducts' },
        { value: 'Esophagus', label: 'Esophagus' },
        { value: 'Gallbladder', label: 'Gallbladder' },
        { value: 'Ovary', label: 'Ovary' },
        { value: 'Corpus Uteri', label: 'Corpus Uteri' },
        { value: 'Unknown primary', label: 'Unknown primary' },
        { value: 'Rectum', label: 'Rectum' },
        { value: 'Connective, subcutaneous and other soft tissues', label: 'Connective, subcutaneous and other soft tissues' },
        { value: 'Prostate', label: 'Prostate' },
        { value: 'Brain', label: 'Brain' },
        { value: 'Cervix Uteri', label: 'Cervix Uteri' },
        { value: 'Kidney', label: 'Kidney' },
        { value: 'Thyroid', label: 'Thyroid' },
        { value: 'Other and unspecified parts of mouth', label: 'Other and unspecified parts of mouth' },
        { value: 'Other parts of tongue', label: 'Other parts of tongue' },
        { value: 'Retroperitoneum and Peritoneum', label: 'Retroperitoneum and Peritoneum' },
        { value: 'Urinary Bladder', label: 'Urinary Bladder' },
        { value: 'Skin', label: 'Skin' },
        { value: 'Rectosigmoid junction', label: 'Rectosigmoid junction' },
        { value: 'Uterus', label: 'Uterus' },
        { value: 'Small Intestine', label: 'Small Intestine' },
        { value: 'Other ill defined sites', label: 'Other ill defined sites' },
        { value: 'Accessory sinuses', label: 'Accessory sinuses' },
        { value: 'Heart', label: 'Heart' },
        { value: 'Intestinal tract', label: 'Intestinal tract' },
        { value: 'Parotid gland', label: 'Parotid gland' },
        { value: 'Anus and Anal canal', label: 'Anus and Anal canal' },
        { value: 'Gum', label: 'Gum' },
        { value: 'Thymus', label: 'Thymus' },
        { value: 'Bones, joints, and articular cartilage of the limb', label: 'Bones, joints, and articular cartilage of the limb' },
        { value: 'Bones, joints and articular cartilage of other/ unspecified sites', label: 'Bones, joints and articular cartilage of other/ unspecified sites' },
        { value: 'Other and unspecified major salivary gland', label: 'Other and unspecified major salivary gland' },
        { value: 'Penis', label: 'Penis' },
        { value: 'Nasopharynx', label: 'Nasopharynx' },
        { value: 'Base of Tongue', label: 'Base of Tongue' },
        { value: 'Larynx', label: 'Larynx' },
        { value: 'Eye', label: 'Eye' },
        { value: 'Nasal cavity and middle ear', label: 'Nasal cavity and middle ear' },
        { value: 'Palate', label: 'Palate' },
        { value: 'Other and unspecified urinary organs', label: 'Other and unspecified urinary organs' },
        { value: 'Floor of mouth', label: 'Floor of mouth' },
        { value: 'Other endocrine glands', label: 'Other endocrine glands' },
        { value: 'Tonsil', label: 'Tonsil' },
        { value: 'Lip', label: 'Lip' },
        { value: 'Pyriform sinus', label: 'Pyriform sinus' },
        { value: 'Renal pelvis', label: 'Renal pelvis' },
        { value: 'Testis', label: 'Testis' },
        { value: 'Vagina', label: 'Vagina' },
        { value: 'Other and ill-defined sites in lip, oral cavity and pharynx', label: 'Other and ill-defined sites in lip, oral cavity and pharynx' },
        { value: 'Fallopian tube and other regions', label: 'Fallopian tube and other regions' },
        { value: 'Ureter', label: 'Ureter' },
        { value: 'Spinal Cord and Other Central Nervous System', label: 'Spinal Cord and Other Central Nervous System' },
        { value: 'Oropharynx', label: 'Oropharynx' },
        { value: 'Adrenal gland', label: 'Adrenal gland' },
        { value: 'Hypopharynx', label: 'Hypopharynx' },
        { value: 'Lymphnode', label: 'Lymphnode' },
        { value: 'Labia,Vulva', label: 'Labia,Vulva' },
        { value: 'Other unspecified male genital organs', label: 'Other unspecified male genital organs' },
        { value: 'Cerebral Meninges', label: 'Cerebral Meninges' },

    ];
    const clearState = () => {
        setDrugFilterVal('')
        setPdlFilterVal('')
        setPdlCloneFilterVal('')
        setFilterVal('')
        setBiologicalFilterVal('')
        setPrimaryFilterVal('')
        setSearchInput('');
    };

    HighchartsSunburst(Highcharts);
    const options = {
        chart: {
            type: 'pie',
            width: 500,
            height: 350
        },
        title: {
            text: null
        },
        subtitle: {
            text: null
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.percentage:.1f} %',
                    distance: -30, // Adjust this value to control the distance of data labels from the center of the pie
                    rotationMode: 'parallel',
                    connectorWidth: 0,

                }
            }
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b>: ${this.y}`;
            }
        },
        series: [{
            //  name: 'Categories',
            colorByPoint: true,
            data: msiValue.map(({ name, value }) => ({
                name,
                y: value,
                drilldown: false
            }))
        }]
        // setChartOptions(options);
    };




    const handleMouseOverBar = (e) => {
        setActivePart(e.target.id);
        console.log("activepartt", activePart);
    };
    const handleMouseOutBar = (data, index) => {
        setActivePart(null);
        //   setHov(false)
    };

    const handleMouseEnterPath = (e) => {
        setHov(true)
        setActivePart(e.target.id)
    };

    const handleMouseLeavePath = (e) => {
        setHov(false)
        setActivePart(null);
    };

    useEffect(() => {
        if (activePart) {
            setActivePart(activePart)
        }
        //  console.log("activepart", activePart);
    }, [activePart])

    function BodyLayout() {
        return (
            <g id="layer1">
                <path opacity="0.5" fill="#080808" enableBackground="new    " d="M223.096,53.979c4.625,0.625,0.005,1.929-2.768,2.804
                                          c-2.773,0.875-4.981-0.27-6.482-0.263c-1.187,0.005-1.131,0.448-0.078,1.575c0.814,0.872,2.405,1.463,3.953,1.463
                                          c2.625,0,5.254-2.303,6.012-2.667c2.324-1.114,3.504-1.162,3.504-3.287c0-0.688-0.516-1.188-2.891-1.438l-6.75-0.438
                                          c-3,0.375-7,3.528-7,3.528s3.697-0.795,4.73-1.009C216.268,54.052,219.846,53.54,223.096,53.979z"/>
                <path opacity="0.5" fill="#080808" enableBackground="new    " d="M238.954,56.891c0.758,0.364,3.387,2.667,6.012,2.667
                                          c1.548,0,3.14-0.592,3.953-1.463c1.054-1.127,1.104-1.57-0.078-1.575c-1.501-0.007-3.71,1.138-6.481,0.263
                                          c-2.771-0.875-7.394-2.179-2.769-2.804c3.25-0.439,6.828,0.073,7.771,0.269c1.033,0.214,4.729,1.009,4.729,1.009s-4-3.153-7-3.528
                                          l-6.75,0.438c-2.375,0.25-2.891,0.75-2.891,1.438C235.45,55.729,236.63,55.777,238.954,56.891z"/>
                <path opacity="0.5" fill="#080808" enableBackground="new    " d="M225.174,73.606c0,0.706,0.652,0.993,1.781,0.993
                                          s2.008,0.954,2.442,1.423c0.449,0.478,0.938,1.047,2.183,1.047c1.374,0,1.585-0.283,2.291-1.059
                                          c0.706-0.776,1.897-1.341,2.682-1.482s1.693-0.353,1.693-0.917s-0.146-1.122-1.563-1.122c-1.438,0-1.758,0.979-2.322,1.828
                                          c-0.563,0.848-1.479,0.567-2.778,0.567c-1.31,0-1.892-0.105-2.515-0.567c-0.938-0.701-0.563-1.703-2.033-1.703
                                          C226.045,72.614,225.174,72.835,225.174,73.606z"/>
                <path opacity="0.5" fill="#080808" enableBackground="new    " d="M223.357,85.96c0,0,1.816,2.046,8.354,2.046
                                          c5.548,0,7.312-2.046,7.312-2.046s-0.771,0.847-7.438,0.847C225.174,86.807,225.884,86.65,223.357,85.96z"/>
                <path opacity="0.5" fill="#080808" enableBackground="new    " d="M234.152,81.54c-1.271,0-2.688,0.423-2.688,0.423
                                          s-1.366-0.423-2.638-0.423c-1.449,0-2.063,0.383-2.6,0.558c-1.673,0.548-3.896,1.276-3.896,1.276h18.481
                                          c0,0-2.104-0.749-3.766-1.261C235.811,81.726,235.88,81.54,234.152,81.54z"/>
                <polygon opacity="0.5" fill="#080808" enableBackground="new    " points="186.613,124.813 221.402,126.817 220.271,124.813 	" />
                <polygon opacity="0.5" fill="#080808" enableBackground="new    " points="241.759,126.817 276.548,124.813 242.891,124.813 	" />
                <path opacity="0.5" fill="#080808" enableBackground="new    " d="M232.348,268.854c0.127,1.625-1.875,1.875-1.875,3.375
                                          s0.375,1.625,1.375,1.625s1.021-0.392,1.021-1.891C232.861,270.565,232.268,267.827,232.348,268.854z"/>
                <path opacity="0.5" fill="#080808" enableBackground="new    " d="M422.096,374.557c-2.587-4.668-8.073-14.839-10.323-19.015
                                          c0.354,0.04,0.729,0.065,1.096,0.065c1.604,0,2.666-0.484,3.151-1.44c0.63-1.234,0.128-2.901-1.577-5.247
                                          c-4.368-6.007-5.03-7.143-6.802-10.179c-0.343-0.587-0.729-1.245-1.184-2.021c-2.896-4.902-3.979-6.105-9.813-8.894
                                          c-8.574-4.099-10.533-5.124-14.642-9.685c-6.414-7.122-22.479-41.115-23.971-45.485c-6.847-20.065-16.06-27.331-17.043-28.063
                                          l-5.533-5.576c1.819-24.02-12.393-50.524-14.154-53.717c0.472-2.84,3.354-20.992,1.854-31.051
                                          c-2.605-17.601-15.497-25.119-19.388-27.003c-6.854-3.323-17.175-5.28-18.679-5.554l-31.67-17.876
                                          c0.021-1.878,0.076-7.793,0.076-11.253c0-1.976,0.631-2.718,1.586-3.842c0.801-0.94,1.795-2.11,2.602-4.217
                                          c0.205-0.543,0.289-3.565,0.248-8.992c0.615,0.24,1.367,0.398,2.188,0.293l0.21-0.026c1.141-0.139,1.438-0.254,2.002-1.15
                                          c0.207-0.331,0.521-0.831,1.211-1.667c1.354-1.646,2.401-7.23,1.93-10.3c-0.188-1.215-0.045-1.983,0.154-3.046
                                          c0.217-1.171,0.482-2.629,0.369-5.098c-0.175-3.626-2.563-4.018-3.574-4.018c-0.273,0-0.543,0.025-0.791,0.064
                                          c0.053-1.729,0.121-5.254,0.121-11.579c0-10.026-7.563-32.003-30.039-32.003c-7.841,0-14.979,3.082-20.646,8.913
                                          c-6.62,6.818-10.023,16.227-9.866,21.619c0.044,1.519,0.122,3.257,0.192,4.898c0.1,2.043,0.188,3.974,0.188,5.07
                                          c0,0.867,0.184,1.94,0.36,2.884c-0.374-0.117-0.768-0.179-1.158-0.179c-1.771,0-3.688,1.285-3.733,4.107
                                          c-0.061,3.479,0.287,5.247,0.54,6.538c0.157,0.801,0.271,1.38,0.231,2.103c-0.146,2.841,0.354,6.425,1.272,9.13
                                          c0.415,1.219,1.707,3.084,3.28,3.415c0.813,0.173,1.884-0.085,2.734-0.416c-0.017,3.048,0.056,8.274,0.633,9.573
                                          c0.604,1.358,2.405,3.426,3.114,4.21l0.125,15.313c-11.644,6.793-30.146,17.406-31.714,17.835
                                          c-13.512,1.986-23.57,7.246-29.913,15.634c-6.552,8.663-7.536,19.635-7.655,23.951c-0.192,6.892,2.056,21.232,2.423,23.524
                                          c-2.023,4.687-3.478,7.925-4.646,10.536c-2.521,5.643-3.567,7.978-5.762,14.542c-3.259,9.775-3.87,26.636-3.896,27.349
                                          c-0.001,0.04,0,0.081,0.006,0.121l0.139,1.583c-9.426,7.715-17.289,18.922-20.229,26.118c-0.563,1.369-1.335,3.294-2.246,5.568
                                          c-3.961,9.863-10.589,26.372-13.699,31.993c-4.16,7.504-10.354,15.256-15.779,19.75c-1.896,1.572-4.271,2.736-6.787,3.969
                                          c-2.812,1.378-5.719,2.803-8.432,4.983c-2.813,2.254-11.354,16.146-12.813,18.607c-1.099,1.857-1.338,3.275-0.729,4.337
                                          c0.358,0.639,1.21,1.399,3.169,1.398c0.658,0,1.37-0.087,2.027-0.216c-3.603,5.386-10.596,16.296-12.479,22.474
                                          c-0.626,2.058-0.563,3.558,0.201,4.585c0.585,0.789,1.527,1.206,2.734,1.206c0.6,0,1.107-0.103,1.362-0.164
                                          c-0.712,1.304-0.954,2.517-0.724,3.619c0.248,1.183,1.021,2.166,2.247,2.849c0.316,0.178,0.698,0.271,1.134,0.271
                                          c0.993,0,2.204-0.498,3.011-0.896c0.502,0.535,1.322,0.998,2.572,0.998c0.873,0,1.893-0.229,3.021-0.688
                                          c1.813-0.729,5.465-4.108,7.15-5.729c0.392,0.354,0.951,0.644,1.759,0.644c1.476,0,3.479-0.964,6.31-3.029
                                          c9.431-6.885,22.53-24.962,25.494-31.385c1.646-3.567,2.46-6.722,3.109-9.255c0.745-2.895,1.335-5.181,2.776-6.763
                                          c2.979-3.267,14.048-17.842,17.771-23.256c1.146-1.665,4.483-5.551,8.354-10.051c7.001-8.145,15.713-18.281,18.206-23.05
                                          c0.334-0.64,0.804-1.405,1.349-2.291c1.448-2.358,3.438-5.588,4.563-9.343c0.854-2.859,1.207-4.514,1.611-6.43
                                          c0.313-1.464,0.662-3.125,1.313-5.646c1.088-4.23,3.805-8.714,6.946-13.907c1.063-1.762,2.171-3.583,3.277-5.511
                                          c3.171-5.502,6.609-12.747,8.429-16.677c0.778,3.87,2.102,10.24,2.675,11.988l0.204,0.622c0.884,2.659,2.214,6.677,2.665,11.492
                                          c0.479,5.165-0.968,13.011-2.585,16.575c-1.727,3.789-2.337,9.802-2.337,14.166c0,3.521,1.37,6.004,1.996,6.953
                                          c-0.685,2.442-4.604,14.717-6.146,19.546l-0.729,2.281c-1.11,3.5-3.745,17.701-2.372,32.479c0.138,1.46-0.638,27.36-1.663,36.548
                                          c-1.063,9.48-1.768,18.521-2.342,30.229c-0.327,6.688,0.19,19.852,0.839,25.17c0.617,5.121,3.149,21.146,5.033,27.649
                                          c1.295,4.476,1.479,6.935,1.732,10.328c0.099,1.295,0.207,2.771,0.396,4.498c0.438,4.063,0.438,11.422,0.438,17.912
                                          c0.002,3.172,0.002,6.168,0.061,8.34c0.074,2.988,0.396,5.521,0.707,7.978c0.375,2.957,0.729,5.75,0.645,9.145
                                          c-0.063,2.24-0.62,5.063-1.334,8.629c-1.202,6.035-2.854,14.305-2.854,24.734c0,16.715,1.299,27.385,2.432,34.813
                                          c1.271,8.337,8.522,38.609,8.601,38.914c0.988,4.121,8.646,38.563,8.811,42.563c0.125,3.141-0.646,6.354-1.396,9.477
                                          c-0.205,0.855-0.406,1.697-0.585,2.521c-0.479,2.202-0.896,3.77-1.338,5.43c-0.317,1.193-0.646,2.404-0.992,3.881
                                          c-0.729,3.061-6.813,9.479-12.17,13.965c-1.389,1.164-2.563,1.752-3.807,2.373c-1.813,0.906-2.741,3.369-2.699,5.229
                                          c0.066,3.146,1.809,4.563,3.271,4.828c0.109,0.018,0.229,0.018,0.339,0l0.805-0.144c0.386,0.664,1.077,1.563,2.141,1.875
                                          c1.091,0.324,2.283,0.33,3.021,0.188c0.636,0.799,1.904,1.981,3.8,1.877c0.801-0.045,1.822-0.467,2.479-0.777
                                          c0.688,0.488,1.943,1.152,3.716,1.152c2.008,0,3.102-0.979,3.65-1.98c1.129,0.854,2.979,1.857,5.339,1.857l0.28-0.004
                                          c0.458-0.021,11.254-0.463,14.391-7.324c1.153-2.523,1.144-3.563,1.114-5c-0.019-0.93-0.032-2.086,0.296-4.129
                                          c0.312-1.9,1.229-3.002,2.295-4.277c1.581-1.893,3.375-4.036,3.375-8.979c0-3.467-0.191-4.492-0.438-5.791
                                          c-0.183-0.949-0.385-2.023-0.563-4.438c-0.197-2.646-0.098-4.039-0.007-5.268c0.104-1.416,0.19-2.646-0.257-5.088
                                          c-0.197-1.074-0.717-2.021-1.313-3.115c-1.169-2.137-2.621-4.797-2.83-10.17c-0.022-0.721-0.18-2.666-0.388-5.355
                                          c-0.479-6.254-1.212-15.696-1.289-21.021c-0.13-8.949-0.189-14.336-0.001-20.957c0.105-3.695,1.646-8.563,3.13-13.262
                                          c1.577-4.997,3.067-9.724,3.104-13.449c0.017-1.787,0.066-3.734,0.128-5.793c0.167-5.986,0.354-12.771-0.271-19.378
                                          c-0.621-6.6-2.547-11.354-4.094-15.184c-0.558-1.37-1.08-2.672-1.464-3.854c-0.488-1.521-0.046-8.169,0.359-12.188
                                          c0.402-0.623,1.479-2.457,2.324-5.405c0.688-2.41,0.371-5.085,0.145-7.029c-0.094-0.771-0.204-1.715-0.143-1.974
                                          c0.054-0.186,0.301-0.602,0.563-1.034c0.938-1.574,2.688-4.521,3.953-10.188c0.843-3.793,0.935-11.438,1.037-20.287
                                          c0.104-8.635,0.221-18.421,1.143-25.127c1.104-8.021,3.396-20.184,5.419-30.913c1.349-7.145,2.616-13.893,3.411-18.728
                                          c1.748-10.63,2.412-28.697,2.554-33.074h2.604c0.396,5.217,2.381,31.02,3.87,41.881c1.187,8.648,3.886,22.439,6.056,33.521
                                          c0.896,4.565,1.703,8.7,2.285,11.854c1.211,6.563,1.348,17.957,1.452,27.115c0.066,6.016,0.134,11.209,0.459,14.16
                                          c0.688,6.178,2.979,8.895,4.021,9.826c-0.125,1.813-0.451,7.822,0.271,12.856c0.371,2.611,1.104,5.813,1.741,8.621
                                          c0.614,2.701,1.389,6.063,1.273,6.66c-4.768,10.68-6.511,19.438-6.668,33.479c-0.146,13.189,2.512,22.396,4.45,29.104
                                          c0.724,2.498,1.347,4.654,1.661,6.488c1.019,5.844-0.36,44.312-1.573,51.692c-0.742,4.541-1.779,6.33-2.614,7.771
                                          c-0.548,0.938-1.021,1.756-1.19,2.992c-0.411,2.92-0.241,5.975-0.104,8.43c0.08,1.455,0.149,2.713,0.068,3.498
                                          c-0.201,1.965-0.4,3.18-0.563,4.156c-0.305,1.842-0.479,2.95-0.299,6.588c0.105,2.086,1.526,6.203,3.072,7.338
                                          c2.17,1.596,2.354,5.381,2.508,8.422c0.022,0.488,0.05,0.959,0.078,1.404c0.228,3.299,2.779,4.971,4.838,6.313
                                          c0.609,0.4,1.188,0.779,1.631,1.15c2.229,1.895,6.396,2.563,9.544,2.563l0.347-0.004c2.646-0.041,4.628-1.096,5.541-1.688
                                          c0.896,0.438,2.548,1.182,3.535,1.188c0.813,0,2.304-0.712,3.186-1.175c0.773,0.229,2.313,0.653,3.12,0.653
                                          c0.018,0,0.092-0.002,0.104-0.002c1.373-0.063,2.576-1.076,3.182-1.688c0.396,0.053,0.939,0.104,1.451,0.104
                                          c0.705,0,1.184-0.104,1.529-0.313c0.511-0.313,0.926-1.104,1.229-1.896c0.086,0.006,0.178,0.008,0.271,0.008
                                          c1.688,0,3.041-1.002,3.711-2.75c0.054-0.131,0.104-0.258,0.154-0.383c0.391-0.945,0.877-2.121,0.032-3.898
                                          c-1.021-2.146-3.383-3.879-6.112-5.881c-4.324-3.166-9.707-7.104-12.341-14.446c-1.097-3.048-1.207-4.521-1.327-6.093
                                          c-0.068-0.889-0.146-1.81-0.386-3.019c-0.21-1.045-0.41-1.901-0.604-2.715c-0.547-2.311-0.979-4.125-1.209-8.895
                                          c-0.243-5.049,7.104-38.131,8.289-43.038c0.354-1.479,0.925-3.63,1.644-6.352c1.806-6.842,4.521-17.179,6.979-28.521
                                          c1.403-6.512,2.188-19.393,2.313-38.28l0.012-0.997c0.13-12.521-1.636-18.969-2.919-23.675c-0.688-2.5-1.229-4.479-1.254-6.593
                                          c-0.016-0.729-0.04-1.063-0.063-1.28c-0.022-0.29-0.022-0.29,0.188-1.75l0.063-0.476c2.201-15.334,1.545-31.063,1.142-37.34
                                          c0.069-0.104,0.119-0.173,0.12-0.188c0.005-0.092,0.136-2.188,0.285-4.875c1.13-4.563,3.435-14.271,4.397-21.227
                                          c0.438-3.146,0.98-6.391,1.563-9.818c1.14-6.731,2.313-13.704,2.761-20.563c0.389-5.854,0.207-10.817,0.021-16.076
                                          c-0.138-3.838-0.273-7.808-0.186-12.188c0.146-7.123-1.79-17.588-3.072-24.513c-0.537-2.894-1-5.392-1.013-6.138
                                          c-0.091-6.651,0.11-13.973,0.293-20.432c0.16-5.911,0.313-11.494,0.226-15.579c-0.17-7.354-1.547-15.211-4.334-24.727
                                          c-2.066-7.059-4.938-13.78-5.832-15.801c0.805-1.038,2.097-3.568,2.314-9.242c0.141-3.508-0.848-7.464-1.979-12.045
                                          c-1.271-5.132-2.719-10.95-2.699-17.103c0.01-2.869,0.945-6.663,1.854-10.331c0.826-3.342,1.604-6.499,1.794-9.17
                                          c0.053-0.771,0.815-2.587,1.581-4.174c0.729,1.325,1.694,3.149,2.179,4.277c2.268,5.346,8.201,15.2,12.535,22.396
                                          c2.44,4.062,4.559,7.567,5.172,8.875c0.574,1.224,0.684,2.419,0.826,4.075c0.253,2.859,0.6,6.773,3.766,13.403
                                          c3.388,7.096,14.495,21.735,32.122,42.333l0.772,0.91c2.756,3.251,15.021,19.825,16.396,21.689
                                          c0.307,1.351,2.021,8.943,2.612,11.324c0.703,2.819,5.994,11.397,9.328,16.063c0.062,0.081,9.27,10.909,15.742,15.993
                                          c4.863,3.817,6.688,4.263,7.639,4.263c0.628,0,1.149-0.229,1.503-0.654c0.939,1.146,3.334,3.964,5.771,6.385
                                          c1.69,1.678,3.416,2.523,5.12,2.521c1.807,0,2.941-0.943,3.283-1.273c0.995,0.646,2.063,0.988,3.064,0.988
                                          c1.291,0,2.372-0.596,2.968-1.633c0.646-1.132,0.97-3.188-0.772-6.759c1.354,0.181,2.569-0.434,3.304-1.673
                                          C423.594,379.98,423.435,376.975,422.096,374.557z M207.592,83.822c-0.339-0.762-0.491-7.271-0.451-11.647
                                          c0.072,0.031,0.146,0.066,0.217,0.092c3.248,1.104,4.183,5.462,4.183,5.462c0-3.06,0-4.471-2.854-6.306
                                          c-2.821-1.813-2.643-9.044-3.246-11.572c-0.771-3.235-2.753-4.123-4.771-4.123c0,0,3.568,2.133,3.568,6.732
                                          c0,2.493,0.139,3.321-0.463,4.697c-0.595,1.376-1.583,0.313-1.583,0.313c1.375,1.5,1.375,1.5,2.711,0.882
                                          c0,0,0.071,1.21,0.669,2.313c-0.641,0.504-1.834,2.87-2.563,2.87c-0.075,0-0.146-0.006-0.195-0.017
                                          c-0.628-0.132-1.514-1.267-1.798-2.103c-0.69-2.036-1.324-5.418-1.174-8.384c0.05-0.968-0.104-1.719-0.271-2.588
                                          c-0.249-1.271-0.558-2.853-0.503-6.121c0.026-1.578,0.914-2.14,1.734-2.14c0.561,0,1.104,0.257,1.498,0.705
                                          c0.264,0.302,0.563,0.456,0.906,0.456c0.299,0,0.611-0.151,0.809-0.381c0.397-0.482,0.298-0.994,0.002-2.512
                                          c-0.186-0.958-0.418-2.15-0.418-2.973c0-1.142-0.09-3.094-0.184-5.161c-0.074-1.63-0.153-3.357-0.197-4.866
                                          c-0.125-4.267,2.68-13.345,9.306-20.167c5.277-5.434,11.919-8.306,19.208-8.306c20.979,0,28.039,20.604,28.039,30.003
                                          c0,9.666-0.168,12.827-0.17,12.857c-0.02,0.349,0.146,0.681,0.438,0.878c0.286,0.199,0.656,0.231,0.979,0.088
                                          c0.008-0.003,0.701-0.31,1.433-0.31c0.479,0,1.479,0,1.575,2.113c0.104,2.237-0.132,3.512-0.341,4.638
                                          c-0.207,1.114-0.4,2.166-0.161,3.716c0.405,2.642-0.574,7.604-1.496,8.724c-0.771,0.936-1.143,1.523-1.354,1.875
                                          c-0.044,0.068-0.077,0.126-0.109,0.174c-0.104,0.02-0.247,0.032-0.439,0.055l-0.217,0.027c-1.056,0.134-1.938-1.954-2.188-2.609
                                          c0.924-1.203,1.021-2.856,1.021-2.856c1.337,0.618,1.337,0.618,2.712-0.882c0,0-0.982,1.063-1.581-0.312s-0.465-2.204-0.465-4.697
                                          c0-4.599,3.573-6.732,3.573-6.732c-2.021,0-4.002,0.888-4.772,4.123c-0.604,2.528-0.426,9.759-3.244,11.572
                                          c-2.854,1.834-2.854,3.246-2.854,6.306c0,0,0.869-4.045,3.849-5.324c0.041,4.462,0.007,10.832-0.104,11.383
                                          c-0.688,1.788-1.479,2.727-2.254,3.636c-0.832,0.979-1.62,1.925-1.93,3.591c-2.769,2.565-8.313,7.722-9.433,8.839
                                          c-1.5,1.5-2.625,1.25-4.25,1.375s-13,0-14.125,0s-2.75-0.625-4-2.125c-1.108-1.334-6.763-7.604-8.969-10.647v-0.014
                                          c-0.002-0.25-0.102-0.49-0.271-0.673C209.936,87.047,208.082,84.927,207.592,83.822z M420.759,380.852
                                          c-0.424,0.728-0.941,0.728-1.111,0.728c-0.425,0-1.343-0.214-2.735-1.57c-1.216-1.668-11.442-16.896-12.22-18.133
                                          c-0.293-0.476-0.908-0.606-1.379-0.313c-0.47,0.295-0.609,0.905-0.316,1.381c0.785,1.254,11.363,16.998,12.361,18.336
                                          c0.002,0.004,0.004,0.004,0.006,0.008c0.003,0.002,0.003,0.006,0.005,0.009c0.39,0.495,1.063,1.396,1.241,1.708
                                          c2.457,4.18,1.854,5.893,1.61,6.316c-0.563,0.979-2.334,0.729-3.771-0.461c-5.01-5.447-15.556-19.918-17.26-22.646
                                          c-0.291-0.473-0.907-0.611-1.378-0.316c-0.469,0.293-0.609,0.908-0.316,1.379c1.666,2.666,11.686,16.418,16.938,22.334
                                          c-0.217,0.179-0.854,0.619-1.793,0.619c-1.159,0-2.409-0.649-3.714-1.94c-2.999-2.978-6.021-6.69-6.075-6.763l-1.102-1.267
                                          c-0.771-0.89-5.979-7.754-7.938-10.366c-0.332-0.438-0.96-0.527-1.399-0.197c-0.438,0.332-0.525,0.961-0.193,1.397
                                          c1.318,1.771,6.633,8.789,7.88,10.3c0.055,0.192,0.098,0.428,0.096,0.584c-0.521-0.027-2.101-0.5-6.348-3.834
                                          c-6.246-4.896-15.311-15.522-15.354-15.582c-3.58-5.013-8.438-13.098-9.019-15.388c-0.646-2.58-2.63-11.396-2.647-11.483
                                          c-0.023-0.135-0.09-0.263-0.172-0.375c-0.558-0.753-13.658-18.482-16.604-21.963l-0.782-0.917
                                          c-17.271-20.175-28.571-35.054-31.839-41.896c-3.011-6.303-3.323-9.859-3.575-12.717c-0.149-1.72-0.28-3.206-1.009-4.751
                                          c-0.659-1.4-2.694-4.778-5.271-9.055c-4.305-7.144-10.191-16.928-12.401-22.143c-0.862-2.033-3.104-5.961-3.197-6.127
                                          c-0.021-0.035-0.048-0.063-0.069-0.094c0.006-0.013,0.017-0.023,0.021-0.036c0.383-0.95,4.25-21.125,4.521-43.187
                                          c0.063-4.953,3.604-8.376,7.413-8.376c8.375,0,12.375,7.25,12.375,7.25c-1.438-3.5-5.625-9.688-12.688-9.562
                                          c-7.063,0.126-7.875,4.062-9.313,6.875c-1.438,2.813-2.375,8.125-4.75,11.188c-2.375,3.063-4,5.125-6.5,7.188
                                          c-2.5,2.063-3.054,2.278-5.75,3.562c-5.25,2.5-15.688,2.125-15.688,2.125s3.45,3.073,10.625,2
                                          c9.188-1.375,12.625-5.062,15.438-7.812c0.131-0.126,2.767-4.215,3.75-2.875c0.875,1.188-1.832,6.663-4.438,9.812
                                          c-2.844,3.432-6.375,6.313-6.375,6.313s6.875-2.688,10.688-8.5c3.017-4.59,2.972,1.966-0.97,8.18
                                          c-1.108,1.753-3.037,3.205-3.037,5.33c0,2.553,2.068,2.928,2.443,4.366c0.129,0.494,0.545,3.338-0.438,5.938
                                          c-1.354,3.591-4.188,7.938-4.188,7.938s1.191-0.504,2.681-1.788c-0.079,0.31-0.134,0.594-0.146,0.836
                                          c-0.175,2.497-0.938,5.571-1.734,8.826c-0.938,3.783-1.905,7.695-1.914,10.806c-0.021,6.4,1.458,12.345,2.76,17.59
                                          c1.104,4.433,2.054,8.261,1.929,11.485c-0.114,2.997-0.541,4.965-0.978,6.23c-0.021-0.008-0.038-0.001-0.058,0.041
                                          c-0.928,2.338-3.046,0.598-3.532-0.109c-0.489-0.707-0.489-1.632-0.489-1.632s-0.484,2.937-2.441,5.275
                                          c-1.793,2.14-5.301,4.188-5.301,4.188s4.166-1.146,6.396-2.871c2.646-2.046,4.649,0.479,5.791,2.661
                                          c1.23,3.054,2.885,7.403,4.199,11.9c2.731,9.343,4.092,17.036,4.258,24.21c0.093,4.034-0.063,9.592-0.229,15.479
                                          c-0.181,6.478-0.384,13.82-0.295,20.514c0.015,0.917,0.426,3.128,1.045,6.477c1.271,6.845,3.188,17.188,3.041,24.104
                                          c-0.097,4.438,0.049,8.438,0.188,12.301c0.188,5.207,0.354,10.126-0.021,15.875c-0.438,6.761-1.604,13.679-2.729,20.364
                                          c-0.58,3.441-1.131,6.7-1.571,9.876c-0.429,3.063-1.12,6.685-1.854,10.146c0.006-0.376-0.011-0.533-0.039-0.41
                                          c-0.123,0.465-1.521,7.798-3.713,13.89c-2.567,7.155-4.589,11.869-4.589,11.869s3.28-3.895,5.502-6.593
                                          c0.375,7.188,0.698,21.111-1.252,34.723l-0.063,0.471c-0.233,1.623-0.242,1.688-0.198,2.207c0.021,0.195,0.042,0.49,0.054,1.144
                                          c0.033,2.367,0.604,4.451,1.323,7.091c1.254,4.597,2.979,10.886,2.851,23.122l-0.011,1.007
                                          c-0.162,23.359-1.323,33.489-2.271,37.878c-2.439,11.305-5.153,21.609-6.95,28.436c-0.721,2.73-1.287,4.894-1.646,6.394
                                          c-0.704,2.927-8.612,38-8.347,43.604c0.237,4.947,0.713,6.94,1.264,9.255c0.188,0.791,0.389,1.625,0.594,2.646
                                          c0.219,1.091,0.278,1.909,0.353,2.775c0.127,1.63,0.259,3.313,1.438,6.611c2.84,7.918,8.492,12.062,13.041,15.386
                                          c2.519,1.844,4.688,3.438,5.483,5.123c0.465,0.979,0.271,1.438-0.072,2.279c-0.061,0.141-0.114,0.279-0.175,0.43
                                          c-0.38,0.983-0.979,1.469-1.846,1.469c-0.003,0-0.003,0-0.005,0c0.191-1.525-0.442-3.296-1.393-4.275
                                          c-0.837-0.87-1.688-1.158-2.365-1.396c-0.707-0.241-1.175-0.396-1.604-1.082c-0.222-0.352-0.687-0.457-1.032-0.229
                                          c-0.353,0.223-0.455,0.687-0.229,1.032c0.714,1.13,1.604,1.436,2.39,1.698c0.608,0.207,1.188,0.403,1.771,1.016
                                          c0.789,0.816,1.188,2.321,0.938,3.318c-0.16,0.125-0.297,0.282-0.354,0.479c-0.193,0.64-0.5,1.354-0.686,1.61
                                          c-0.073,0.017-0.221,0.026-0.46,0.026c-0.744,0-1.66-0.146-1.672-0.149c-0.103-0.019-0.199-0.017-0.301,0.004
                                          c0.354-1.644,0.04-3.088-0.997-4.687c-0.646-0.985-1.506-1.545-2.269-2.033c-0.748-0.479-1.396-0.896-1.729-1.646
                                          c-0.17-0.379-0.617-0.543-0.992-0.377c-0.378,0.17-0.546,0.612-0.377,0.991c0.521,1.146,1.414,1.729,2.282,2.287
                                          c0.692,0.443,1.354,0.869,1.817,1.592c0.978,1.498,1.141,2.703,0.586,4.303c-0.101,0.27-0.021,0.541,0.149,0.734
                                          c-0.396,0.297-0.849,0.543-1.247,0.563c-0.512,0-2.021-0.396-2.932-0.688c-0.055-0.021-0.108-0.012-0.168-0.021
                                          c0.991-2.714,0.408-5.009-1.719-6.512c-1.862-1.313-2.415-2.052-3.418-3.386c-0.245-0.328-0.521-0.688-0.831-1.09
                                          c-0.257-0.326-0.729-0.391-1.061-0.13c-0.318,0.257-0.384,0.728-0.13,1.054c0.313,0.396,0.574,0.746,0.813,1.063
                                          c1.021,1.354,1.691,2.261,3.752,3.709c1.716,1.214,2.021,3.032,0.921,5.421c-0.076,0.166-0.078,0.347-0.033,0.513
                                          c-0.688,0.321-1.297,0.563-1.506,0.573c-0.512-0.007-2.037-0.646-3.156-1.241c-0.021-0.021-0.062-0.021-0.094-0.033
                                          c0.561-2.566-0.406-4.804-1.174-5.674c-0.427-1.354-2.813-6.046-8.49-6.438c-0.4-0.022-0.771,0.281-0.8,0.693
                                          c-0.028,0.414,0.282,0.771,0.696,0.803c2.222,0.152,3.854,1.102,5,2.16c-0.047-0.008-0.093-0.019-0.14-0.021
                                          c-1.822-0.28-3.586,0.478-4.188,1.08c-0.438,0.438-1.445,1.575-1.37,2.726c0.032,0.483,0.253,0.896,0.636,1.188
                                          c1.199,0.916,3.479,0.25,6.117-0.646c0.521-0.18,1.146-0.392,1.479-0.468c0.427,0.599,1.475,2.729,0.46,5.24
                                          c-0.815,0.469-2.258,1.097-4.095,1.121l-0.312,0.007c-1.323,0-2.813-0.146-4.198-0.438c-1.396-0.842-3.724-2.354-3.969-5.947
                                          c-0.255-3.744-1.527,2.513-0.244,4.168c-0.5-0.4-1.063-0.78-1.668-1.182c-1.856-1.215-3.779-2.472-3.938-4.771
                                          c-0.026-0.438-0.057-0.896-0.073-1.367c-0.07-1.421-0.15-2.979-0.422-4.479c-0.357-6.549-1.995-10.623-1.995-10.623
                                          s0.603,3.789,0.271,4.854c-0.127,0.405-0.307,0.705-0.465,0.938c-0.22-0.226-0.451-0.438-0.711-0.627
                                          c-0.836-0.611-2.167-4.052-2.259-5.83c-0.182-3.418-0.021-4.396,0.271-6.161c0.164-1.002,0.369-2.25,0.576-4.271
                                          c0.1-0.94,0.021-2.271-0.063-3.813c-0.139-2.365-0.299-5.313,0.093-8.035c0.12-0.854,0.446-1.416,0.939-2.271
                                          c0.864-1.495,2.062-3.545,2.856-8.444c1.269-7.721,2.645-46.205,1.571-52.361c-0.339-1.938-0.979-4.146-1.716-6.7
                                          c-1.903-6.603-4.521-15.634-4.371-28.526c0.153-13.907,1.798-22.155,6.491-32.688c0.396-0.896,0.021-2.771-1.148-7.92
                                          c-0.454-1.99-0.948-4.169-1.327-6.178c0.187,0.438,0.396,0.813,0.656,1.069c3.344,3.375,9.342,3.734,9.342,3.734
                                          c-1.31-0.771-4.688-2.697-7.212-5.195c-2.021-1.998-3.073-4.276-3.503-5.391c-0.175-3.101-0.074-6.142,0.018-7.902
                                          c2.326,1.193,6.063,1.183,4.451,0.616c-1.541-0.541-3.365-1.864-5.274-3.511c-0.843-0.864-2.563-3.229-3.14-8.344
                                          c-0.313-2.854-0.379-8-0.444-13.965c-0.109-9.716-0.24-20.729-1.481-27.454c-0.583-3.157-1.396-7.3-2.29-11.878
                                          c-2.165-11.06-4.857-24.813-6.035-33.408c-1.646-11.994-3.904-42.377-3.928-42.686c-0.038-0.521-0.479-0.928-0.997-0.928h-4.5
                                          c-0.542,0-0.985,0.436-0.999,0.977c-0.006,0.219-0.59,21.814-2.56,33.773c-0.791,4.813-2.063,11.55-3.4,18.684
                                          c-2.026,10.752-4.323,22.938-5.438,31.014c-0.938,6.828-1.057,16.688-1.154,25.377c-0.104,8.375-0.192,16.282-0.991,19.879
                                          c-0.976,4.365-2.242,7.008-3.158,8.639c-2.049,1.793-4.012,3.256-5.652,3.832c-1.56,0.545,1.908,0.578,4.24-0.51
                                          c0.003,0.507,0.068,1.108,0.162,1.896c0.192,1.625,0.442,3.793,0.049,5.698c-0.373,1.018-1.429,3.461-3.577,5.584
                                          c-2.521,2.498-5.898,4.427-7.212,5.195c0,0,4.705-0.286,8.104-2.697c-0.287,3.27-0.715,9.34-0.11,11.22
                                          c0.406,1.266,0.944,2.596,1.514,4.002c1.497,3.702,3.361,8.313,3.956,14.612c0.611,6.479,0.431,13.205,0.266,19.136
                                          c-0.063,2.069-0.112,4.03-0.131,5.829c-0.028,3.438-1.549,8.229-3.014,12.865c-1.521,4.834-3.104,9.831-3.219,13.806
                                          c-0.194,6.663-0.131,12.063-0.001,21.045c0.078,5.394,0.813,14.875,1.295,21.153c0.198,2.563,0.354,4.597,0.382,5.275
                                          c0.229,5.846,1.871,8.854,3.072,11.055c0.537,0.979,0.963,1.76,1.102,2.519c0.404,2.196,0.33,3.196,0.229,4.58
                                          c-0.09,1.233-0.203,2.771,0.008,5.563c0.189,2.521,0.414,3.702,0.594,4.652c0.234,1.238,0.404,2.146,0.404,5.421
                                          c0,4.223-1.414,5.905-2.91,7.692c-0.381,0.457-0.764,0.924-1.123,1.424c-0.096-0.174-0.188-0.364-0.262-0.604
                                          c-0.332-1.068,0.271-4.856,0.271-4.856s-2.064,5.127-2.064,13.136c0,0,0.045-0.199,0.127-0.526
                                          c-0.009,0.435-0.004,0.813,0.002,1.157c0.021,1.308,0.033,2.021-0.938,4.14c-0.894,1.946-2.68,3.323-4.695,4.272
                                          c0.796-2.143-0.288-7.065-0.521-3.646c-0.146,2.257-1.124,3.688-2.173,4.656c-1.961,0.563-3.886,0.822-5.246,0.872l-0.212,0.003
                                          c-3.031,0-5.051-2.267-5.064-2.281c-0.055-0.063-0.108-0.104-0.177-0.146c-0.188-1.172,0.032-2.447,0.229-3.021l0.071,0.014
                                          l0.063-0.12c0.351,0.098,0.896,0.274,1.358,0.438c2.639,0.896,4.918,1.562,6.113,0.646c0.389-0.293,0.604-0.705,0.641-1.188
                                          c0.074-1.146-0.938-2.287-1.371-2.729c-0.605-0.604-2.357-1.36-4.192-1.075c-0.044,0.006-0.087,0.016-0.132,0.021
                                          c1.146-1.063,2.779-2.006,5.002-2.161c0.412-0.023,0.729-0.389,0.695-0.8c-0.028-0.414-0.391-0.724-0.803-0.695
                                          c-5.763,0.399-8.132,5.219-8.511,6.493c-0.626,0.902-0.903,2.94-0.632,4.541c-0.063,0.128-0.114,0.266-0.124,0.409
                                          c-0.021,0.422-0.242,1.793-2.167,1.793c-1.835,0-2.813-0.981-2.853-1.021c-0.083-0.089-0.188-0.146-0.287-0.198
                                          c-0.945-2.238-0.607-3.975,1.037-5.133c2.055-1.449,2.729-2.354,3.753-3.715c0.238-0.313,0.503-0.668,0.812-1.063
                                          c0.259-0.325,0.199-0.797-0.127-1.055c-0.323-0.255-0.799-0.195-1.053,0.131c-0.314,0.399-0.586,0.763-0.831,1.088
                                          c-1.003,1.337-1.556,2.07-3.419,3.392c-2.162,1.522-2.729,3.869-1.666,6.646c-0.591,0.271-1.292,0.538-1.68,0.563L179,711.384
                                          c-1.41,0-2.266-1.512-2.271-1.521c-0.082-0.153-0.207-0.271-0.346-0.354c-0.329-1.314-0.098-2.412,0.756-3.725
                                          c0.467-0.72,1.125-1.146,1.82-1.593c0.867-0.563,1.766-1.146,2.281-2.287c0.17-0.377,0.002-0.819-0.378-0.989
                                          c-0.377-0.17-0.82-0.004-0.989,0.375c-0.334,0.744-0.98,1.16-1.729,1.641c-0.761,0.492-1.621,1.05-2.265,2.035
                                          c-1.021,1.568-1.336,2.994-1.014,4.604c-0.154,0.021-0.326,0.041-0.537,0.041c-0.437,0-0.888-0.067-1.343-0.205
                                          c-0.606-0.188-1.091-1.11-1.206-1.418c-0.076-0.216-0.229-0.375-0.402-0.487c-0.162-0.992,0.229-2.369,0.977-3.146
                                          c0.583-0.604,1.16-0.805,1.771-1.014c0.785-0.269,1.676-0.567,2.389-1.701c0.222-0.35,0.116-0.812-0.229-1.027
                                          c-0.354-0.229-0.813-0.115-1.034,0.229c-0.437,0.686-0.897,0.842-1.604,1.082c-0.682,0.233-1.525,0.521-2.366,1.396
                                          c-0.924,0.963-1.556,2.67-1.398,4.174l-0.525,0.088c-0.423-0.131-1.43-0.676-1.479-2.867c-0.03-1.366,0.716-2.954,1.597-3.396
                                          c1.291-0.646,2.627-1.313,4.195-2.631c3.998-3.353,11.824-10.807,12.83-15.033c0.348-1.452,0.666-2.646,0.979-3.825
                                          c0.448-1.688,0.871-3.271,1.359-5.521c0.177-0.81,0.375-1.638,0.576-2.479c0.771-3.247,1.581-6.604,1.439-10.021
                                          c-0.179-4.455-8.104-39.813-8.854-42.942c-2.029-8.453-7.523-31.896-8.566-38.747c-1.121-7.355-2.406-17.936-2.406-34.518
                                          c0-10.232,1.626-18.392,2.813-24.345c0.729-3.661,1.308-6.558,1.37-8.967c0.094-3.55-0.271-6.414-0.654-9.449
                                          c-0.305-2.397-0.619-4.881-0.693-7.771c-0.055-2.146-0.055-5.132-0.057-8.286c0-4.313-0.002-8.987-0.132-12.885
                                          c2.06,2.558,6.437,7.735,6.437,7.735s-2.016-4.714-4.588-11.871c-2.188-6.091-3.59-13.425-3.711-13.886
                                          c-0.012-0.045-0.021-0.041-0.021-0.017c-0.185-0.741-0.382-1.521-0.628-2.386c-1.854-6.396-4.355-22.269-4.978-27.342
                                          c-0.635-5.244-1.146-18.229-0.824-24.832c0.573-11.66,1.271-20.662,2.328-30.104c0.982-8.756,1.842-35.104,1.669-36.958
                                          c-1.344-14.468,1.206-28.289,2.288-31.688l0.729-2.28c1.764-5.523,3.247-10.187,4.335-13.651c0.969-2.246,3.191-6.141,6.303-3.739
                                          c2.229,1.725,6.396,2.871,6.396,2.871s-3.508-2.047-5.299-4.188c-1.957-2.338-2.44-5.275-2.44-5.275s0,0.925-0.488,1.632
                                          c-0.408,0.589-1.945,1.886-2.98,0.956c-0.52-0.821-1.572-2.847-1.572-5.713c0-3.964,0.563-9.836,2.15-13.338
                                          c1.729-3.793,3.271-12.121,2.759-17.589c-0.476-5.042-1.851-9.189-2.761-11.935l-0.201-0.616c-0.442-1.364-1.425-5.94-2.186-9.646
                                          c2.061,2.348,4.104,3.206,4.104,3.206s-2.83-4.347-4.188-7.938c-0.98-2.599-0.564-5.443-0.438-5.938
                                          c0.375-1.438,2.44-1.813,2.44-4.366c0-2.125-1.927-3.576-3.037-5.33c-3.936-6.214-3.979-12.77-0.969-8.18
                                          c3.813,5.812,10.688,8.5,10.688,8.5s-3.531-2.88-6.375-6.313c-2.604-3.149-5.313-8.625-4.438-9.812
                                          c0.987-1.34,3.621,2.749,3.75,2.875c2.813,2.75,6.25,6.438,15.438,7.812c7.175,1.073,10.625-2,10.625-2s-10.438,0.375-15.688-2.125
                                          c-2.697-1.285-3.25-1.5-5.75-3.562c-2.5-2.063-4.125-4.125-6.5-7.188c-2.375-3.063-3.313-8.375-4.75-11.188
                                          c-1.438-2.813-2.25-6.75-9.313-6.875c-7.063-0.125-11.25,6.062-12.688,9.562c0,0,4-7.25,12.375-7.25
                                          c3.812,0,7.352,3.424,7.413,8.376c0.249,20.018,3.456,38.482,4.334,42.439c-0.421,0.943-5.271,11.75-9.587,19.252
                                          c-1.104,1.909-2.197,3.721-3.266,5.473c-3.226,5.328-6.013,9.929-7.174,14.445c-0.654,2.563-1.017,4.247-1.331,5.729
                                          c-0.396,1.873-0.733,3.49-1.567,6.271c-1.059,3.505-2.881,6.479-4.354,8.871c-0.563,0.918-1.051,1.71-1.418,2.411
                                          c-2.386,4.566-11.016,14.605-17.949,22.673c-4.063,4.729-7.272,8.469-8.479,10.222c-3.606,5.238-14.736,19.894-17.605,23.043
                                          c-1.771,1.949-2.42,4.446-3.229,7.608c-0.666,2.584-1.423,5.515-2.992,8.917c-2.894,6.267-15.667,23.896-24.858,30.605
                                          c-3.146,2.303-4.563,2.646-5.125,2.646c-0.261,0-0.396-0.063-0.532-0.268c2.685-3.063,8.668-11.008,9.47-12.186
                                          c0.229-0.348,0.143-0.813-0.2-1.042c-0.341-0.229-0.812-0.146-1.042,0.196c-0.958,1.412-6.991,9.367-9.438,12.137
                                          c-0.218,0.023-0.418,0.118-0.572,0.271c-2.076,2.063-5.905,5.539-7.338,6.111c-0.896,0.359-1.656,0.546-2.271,0.546
                                          c-0.811,0-1.104-0.319-1.197-0.479c6.264-6.322,15.784-19.851,16.699-21.396c0.211-0.354,0.096-0.815-0.264-1.026
                                          c-0.354-0.209-0.814-0.092-1.027,0.268c-0.917,1.555-10.598,15.295-16.766,21.396c-0.004,0.002-0.004,0.006-0.006,0.008
                                          c-0.061,0.021-0.115,0.021-0.169,0.056c-0.592,0.354-2.063,1.08-2.854,1.08c-0.107,0-0.157-0.019-0.159-0.019
                                          c-0.486-0.271-1.104-0.756-1.264-1.516c-0.188-0.877,0.25-2.041,1.256-3.365c1.851-2.438,1.851-2.438,1.896-2.75
                                          c0.008-0.053-0.013-0.1-0.013-0.146c3.602-4.688,10.94-15.521,12.563-18.688c0.188-0.369,0.041-0.82-0.326-1.012
                                          c-0.369-0.188-0.819-0.041-1.011,0.326c-1.54,3.021-8.823,13.771-12.338,18.363c-0.37,0.02-0.505,0.15-1.582,1.521
                                          c-0.629,0.18-1.989,0.563-2.063,0.574c-0.004,0.002-0.01,0.004-0.015,0.004c-0.007,0.004-0.45,0.129-0.974,0.129
                                          c-0.385,0-0.891-0.064-1.134-0.396c-0.338-0.457-0.301-1.479,0.105-2.813c2.021-6.648,10.637-19.618,13.457-23.765
                                          c0.104-0.134,0.211-0.282,0.321-0.452c0.698-1.054,3.46-2.974,4.675-3.886c1.938-1.461,3.979-5.235,5.475-5.235
                                          c2.488,0,3.938,1.339,8.271,1.339s8.818-3.072,8.818-6.066c0-2.362-6.613-1.575-9.06-1.575s-7.521,2.181-10.032,3.084
                                          c-2.646,0.952-4.414,5.903-8.688,9.969c-0.185,0.056-0.354,0.146-0.479,0.303c-0.189,0.235-0.593,0.636-0.811,0.744
                                          c-0.49,0.244-2.104,0.699-3.431,0.699c-0.892,0-1.317-0.201-1.432-0.391c-0.055-0.095-0.271-0.651,0.717-2.327
                                          c2.53-4.277,10.156-16.312,12.342-18.066c2.541-2.042,5.354-3.417,8.063-4.747c2.623-1.286,5.103-2.501,7.183-4.225
                                          c5.604-4.64,11.981-12.616,16.251-20.32c3.184-5.73,9.834-22.311,13.813-32.218c0.911-2.269,1.683-4.19,2.241-5.556
                                          c2.89-7.063,10.703-18.144,20.052-25.653c0.26-0.209,0.396-0.534,0.369-0.866l-0.185-2.051c0.042-1.119,0.724-17.504,3.791-26.726
                                          c2.157-6.471,3.191-8.779,5.688-14.358c1.194-2.666,2.68-5.984,4.771-10.829c0.075-0.175,0.104-0.369,0.068-0.557
                                          c-0.027-0.162-2.646-16.282-2.443-23.436c0.115-4.123,1.061-14.598,7.259-22.8c6.015-7.955,15.641-12.955,28.604-14.861
                                          c1.813-0.266,25.664-14.134,32.937-18.379c0.311-0.181,0.494-0.513,0.49-0.872l-0.063-7.999c0.572,0.503,1.271,1.008,1.771,1.008
                                          c1,0,7.625,4.75,9,6.625s0.5,3.125,1.625,5.5s3.625,4.25,3.625,4.25s-1.625-3.75-1.625-5.25s-0.125-4.625,1.5-4.625
                                          s7.5,0.125,8.875,0.25s1.875,4,1.875,5.125s-1.375,4.5-1.375,4.5s2.25-1.875,3-3.75s1.25-3.625,2.625-5.625
                                          s5.625-4.125,8.625-6.875c0.408-0.375,0.742-0.692,1.022-0.967c-0.022,3.697-0.063,7.497-0.063,7.497
                                          c-0.004,0.365,0.19,0.702,0.517,0.881l32.332,18.25c0.099,0.056,0.206,0.095,0.313,0.114c0.111,0.021,11.324,2.023,18.32,5.415
                                          c3.665,1.776,15.813,8.866,18.274,25.498c1.541,10.384-1.878,30.567-1.911,30.77c-0.038,0.23,0.009,0.466,0.118,0.668
                                          c0.16,0.281,16.151,28.364,14.037,53.33c-0.021,0.292,0.08,0.581,0.287,0.789l5.916,5.958c0.038,0.039,0.084,0.076,0.127,0.108
                                          c0.096,0.068,9.532,7.042,16.388,27.136c1.413,4.142,17.523,38.577,24.372,46.179c4.354,4.83,6.567,5.995,15.271,10.15
                                          c5.396,2.579,6.229,3.506,8.938,8.105c0.456,0.771,0.843,1.426,1.185,2.011c1.809,3.1,2.479,4.259,6.911,10.349
                                          c1.758,2.417,1.428,3.133,1.406,3.163c-0.088,0.173-0.546,0.348-1.363,0.348c-1.271,0-2.688-0.394-2.695-0.397
                                          c-0.032-0.01-0.064-0.008-0.101-0.013c-4.955-2.843-7.771-9.631-10.396-11.051c-2.041-1.104-13.25-3.458-15.5-3.458
                                          c-2.371,0-4.498,1.205-4.5,2.292c-0.007,1.833,4.416,6,9.916,5.917c1.75-0.025,6.696-1.938,8.5-1.167
                                          c2.334,1,4.295,4.455,6.416,6.417c1.17,1.083,2.914,1.927,4.502,2.496c0.002,0.001,0.002,0.001,0.002,0.002
                                          c0.08,0.149,8.041,14.95,11.328,20.879C421.464,377.543,421.393,379.775,420.759,380.852z M272.26,704.064
                                          c-0.293,0.09-0.632,0.205-1.029,0.34c-1.313,0.447-4.062,1.381-4.724,0.873c-0.032-0.025-0.046-0.041-0.05-0.104
                                          c-0.021-0.28,0.266-0.895,0.934-1.562c0.233-0.234,1.188-0.703,2.313-0.703c0.188,0,0.391,0.014,0.591,0.045
                                          C271.072,703.08,271.73,703.451,272.26,704.064z M190.356,704.715c0.528-0.611,1.188-0.979,1.977-1.104
                                          c1.354-0.228,2.625,0.383,2.896,0.647c0.669,0.668,0.953,1.279,0.938,1.564c-0.011,0.059-0.021,0.072-0.057,0.098
                                          c-0.664,0.517-3.399-0.424-4.725-0.871C190.987,704.922,190.648,704.807,190.356,704.715z"/>
            </g>
        )
    }
    function Breast() {
        return (   
         <ToolTip
                //  title="Breast"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">

                <g
                    id="Breast"
                    //value='9238'
                    className="Breast"
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "Breast" ? 7 : 0.5}
                    fill="#bc8f8f"
                    fillOpacity="10"
                    stroke="#787878"
                    //   strokeWidth={ishovered ? 10 : 0.5}
                    transform="translate(10.5,22)">
                    <path id="Breast" d="M210.508,159.558c0,5.094-9.027,9.223-20.161,9.223
                                                    s-20.161-4.129-20.161-9.223c0-5.094,9.027-9.223,20.161-9.223S210.508,154.465,210.508,159.558z"/>
                    <path id="Breast" d="M274.354,159.558c0,5.094-9.021,9.223-20.162,9.223
                                                    c-11.133,0-20.151-4.129-20.151-9.223c0-5.094,9.021-9.223,20.151-9.223C265.326,150.335,274.354,154.465,274.354,159.558z"/>
                </g>
            </ToolTip>

        )
    }

    function CirculatorySystem() {
        return (
            <ToolTip
                // title="Circulatory System"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">
                <g
                    id="circulatory_system"
                    className="circulatory_system"
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "CirculatorySystem" ? 2 : 0.5}
                    //  strokeWidth={circ ? 2 : 0.5}
                    fill="#ff0000"
                    stroke="#ff0000"
                    fillOpacity="0.5"
                    transform="translate(118.53498,19.115319)">
                    <path id="CirculatorySystem" d="M61.632,393.265c0-0.128,0.748-2.486,1.663-5.262
                                                    c1.72-5.203,3.153-8.979,4.771-12.548c0.901-1.984,0.953-2.173,1.354-4.895c0.756-5.172,1.798-10.271,3.217-15.74
                                                    c6.197-23.902,17.034-49.145,32.18-74.958c0.979-1.656,1.979-3.547,2.229-4.202c1.332-3.431,2.522-10.141,3.262-18.293
                                                    c0.695-7.722,0.869-12.195,1.051-26.95c0.231-18.696,0.608-41.88,0.955-58.789l0.303-14.675l0.521-0.462
                                                    c0.472-0.419,0.604-0.456,1.438-0.395c0.604,0.044,1.275,0.241,1.959,0.578l1.039,0.511l0.105,4.533
                                                    c0.508,21.432,0.656,47.017,0.418,72.068c-0.162,17.08-0.165,22.67-0.015,25.747c0.382,7.809,1.113,13.11,2.313,16.624
                                                    c0.215,0.629,1.217,2.542,2.229,4.251c18.505,31.353,31.468,64.666,35.314,90.773c0.363,2.461,0.449,2.771,1.207,4.387
                                                    c1.699,3.62,3.646,8.831,5.693,15.229l0.858,2.695l-0.893-0.048l-0.89-0.048l-0.835-2.166c-1.542-4.014-3.993-9.443-4.361-9.671
                                                    c-0.416-0.267-0.492,0.146-0.271,1.48c0.272,1.71,0.808,6.226,0.938,8.021c0.055,0.75,0.137,1.598,0.18,1.885l0.08,0.521h-2.719
                                                    h-2.72l-0.091-1.808c-0.054-0.992-0.169-3.465-0.271-5.494c-0.429-9.05-0.47-9.315-2.672-17.237
                                                    c-9.479-34.122-22.88-65.475-35.646-83.417c-0.852-1.191-1.541-2.312-1.541-2.486c0-0.673-0.488-0.261-1.723,1.444
                                                    c-10.14,14.074-20.71,36.461-29.505,62.48c-2.411,7.132-5.894,18.665-7.817,25.905c-0.705,2.646-0.846,3.427-1.055,5.937
                                                    c-0.31,3.714-0.528,7.533-0.669,11.513l-0.106,3.175H70.43h-2.702v-0.441c0-0.448,0.409-4.884,0.557-6.056
                                                    c0.045-0.354,0.231-1.722,0.414-3.024c0.319-2.28,0.324-2.831,0.025-2.831c-0.334,0-3.13,6.108-4.7,10.271l-0.757,2.005
                                                    l-0.818,0.049C61.821,393.49,61.632,393.449,61.632,393.265L61.632,393.265z"/>
                    <path id="CirculatorySystem" d="M72.313,630.377c-0.699-3.412-2.394-12.848-3.292-18.327
                                                    c-0.622-3.794-1.28-7.729-1.464-8.757c-0.897-5.008-0.556-10.239,0.914-14.018c0.31-0.797,0.6-1.604,0.644-1.777
                                                    s-0.184-1.588-0.508-3.128c-1.755-8.346-2.496-18.558-2.002-27.595c0.63-11.523,2.641-21.792,6.327-32.313
                                                    c1.896-5.415,3.702-16.56,4.61-28.474c0.271-3.565,0.271-15.486,0.001-19.009c-0.537-6.978-1.458-12.528-3.164-19.099
                                                    c-2.587-9.941-4.848-22.971-5.999-34.646c-0.742-7.483-0.89-10.57-0.891-19.41c-0.001-4.676,0.063-8.917,0.134-9.424l0.128-0.922
                                                    h2.688h2.69l-0.075,5.173c-0.135,9.396,0.41,21.785,1.419,32.203c0.684,7.063,1.93,16.354,2.568,19.139
                                                    c1.438,6.313,2.75,14.446,3.595,22.408c1.252,11.809,1.489,21.226,0.738,29.436c-1.354,14.813-3.497,25.876-6.328,32.673
                                                    l-0.579,1.396l0.784,2.071c1.84,4.854,4.304,13.351,4.809,16.562c2.404,15.312,3.702,38.119,3.706,65.169l0.004,11.19h-0.481H82.81
                                                    l-0.037-2.286c-0.02-1.257-0.015-2.813,0.011-3.455c0.073-1.771-0.271-15.159-0.542-20.888
                                                    c-0.522-11.352-1.455-24.563-2.688-38.418c-0.572-6.402-0.712-7.555-0.934-7.595c-0.211-0.041-0.301,0.313-0.525,2.084
                                                    c-1.092,8.366-3.456,18.599-6.166,26.646l-0.961,2.854L72,595.736c1.302,7.383,4.729,27.52,5.36,31.472
                                                    c0.268,1.632,0.519,3.134,0.563,3.338c0.079,0.354,0.042,0.367-0.601,0.321l-0.679-0.05l-0.709-4.018
                                                    c-0.39-2.203-1.912-11.229-3.382-20.053c-2.156-12.936-2.722-16.049-2.922-16.088c-0.771-0.146-1.546,6.317-1.345,11.194
                                                    c0.099,2.35,0.352,4.096,2.26,15.544c1.182,7.093,2.146,13.025,2.146,13.188C72.701,631.093,72.428,630.935,72.313,630.377
                                                    L72.313,630.377z M71.168,583.229c1.555-4.729,3.028-11.411,4.097-18.503c0.563-3.771,1.286-10.023,1.286-11.146
                                                    c0-1.034-0.946-5.587-1.771-8.505c-0.686-2.44-2.047-6.571-2.229-6.778c-0.153-0.171-1.645,4.011-2.407,6.778
                                                    c-3.144,11.323-3.377,24.709-0.656,37.778c0.485,2.33,0.648,2.859,0.815,2.692C70.384,585.473,70.773,584.428,71.168,583.229
                                                    L71.168,583.229z"/>
                    <path id="CirculatorySystem" d="M143.531,625.885c-0.174-7.794,0.197-27.384,0.726-37.978
                                                    c0.63-12.802,1.813-25.746,3.063-33.366c0.51-3.119,3.563-13.417,5.082-17.128l0.479-1.166l-0.563-1.32
                                                    c-2.727-6.396-4.977-17.851-6.293-32.04c-0.811-8.729-0.643-17.338,0.568-29.188c0.815-8.06,2.242-17.146,3.536-22.619
                                                    c1.042-4.417,2.646-17.479,3.341-27.19c0.615-8.617,0.963-20.201,0.791-26.428l-0.109-3.97h2.729h2.727l0.104,1.457
                                                    c0.232,3.349,0.303,11.773,0.113,15.857c-0.729,16.421-2.896,31.396-6.832,47.242c-1.525,6.172-1.979,8.459-2.557,13.011
                                                    c-0.771,6.108-0.937,9.559-0.843,17.313c0.117,9.639,0.668,15.782,2.271,25.435c0.916,5.482,1.574,8.245,2.861,12.005
                                                    c1.838,5.359,3.244,10.684,4.191,15.819c2.271,12.355,2.521,26.099,0.706,38.104c-0.327,2.146-1.377,7.479-1.521,7.719
                                                    c-0.041,0.063,0.11,0.541,0.34,1.054c0.674,1.521,1.328,3.94,1.609,5.95c0.313,2.229,0.229,6.646-0.158,8.458
                                                    c-0.135,0.618-0.574,3.216-0.979,5.771c-1.354,8.475-3.777,21.968-3.994,22.185c-0.058,0.05-0.146,0.04-0.202-0.021
                                                    c-0.063-0.063,0.886-6.094,2.104-13.396l2.225-13.29l-0.081-4.396c-0.079-4.308-0.17-5.298-0.703-7.642
                                                    c-0.202-0.889-0.329-1.163-0.539-1.163c-0.238,0-0.595,1.938-2.794,15.199c-1.386,8.358-2.896,17.329-3.354,19.932l-0.838,4.732
                                                    l-0.683,0.05c-0.646,0.047-0.676,0.025-0.604-0.321c0.047-0.198,0.949-5.523,2.015-11.84c1.058-6.308,2.468-14.57,3.133-18.368
                                                    c0.665-3.794,1.332-7.643,1.479-8.553l0.271-1.655l-1.069-3.229c-2.775-8.411-5.25-19.302-6.176-27.225
                                                    c-0.094-0.807-0.188-1.056-0.4-1.098c-0.188-0.037-0.273,0.047-0.273,0.257c0,0.178-0.322,3.912-0.722,8.313
                                                    c-2.046,22.896-3.13,42.864-3.134,57.771l-0.002,5.979h-0.464h-0.462L143.531,625.885L143.531,625.885z M157.61,584.169
                                                    c1.369-5.817,2.043-12.082,2.063-19.121c0.013-7.617-0.74-13.438-2.599-20.048c-0.562-2.008-1.901-5.934-2.188-6.395
                                                    c-0.211-0.352-2.521,7.062-3.313,10.642c-0.942,4.258-0.938,4.093-0.416,8.743c0.955,8.479,2.232,15.62,3.938,21.977
                                                    c0.646,2.438,1.836,6.021,1.979,6.021C157.135,585.982,157.374,585.166,157.61,584.169L157.61,584.169z"/>
                    <path id="CirculatorySystem" d="M167.038,468.137c-0.008-0.099,0.1-1.218,0.229-2.479
                                                    c1.239-11.938,2.323-33.771,2.326-46.856c0.001-4.881-0.003-4.944-0.486-7.219c-0.562-2.646-1.562-6.678-2.257-9.109
                                                    c-0.429-1.499-2.597-8.27-2.813-8.774c-0.1-0.233,1.166-0.282,1.521-0.063c0.271,0.172,1.321,3.793,2.75,9.484
                                                    c1.845,7.345,2.195,9.165,2.409,12.512c0.287,4.485,0.395,19.254,0.172,23.563c-0.542,10.479-1.632,19.229-3.37,27.056
                                                    C167.262,467.379,167.047,468.236,167.038,468.137L167.038,468.137z"/>
                    <path id="CirculatorySystem" d="M60.046,467.194c-0.017-0.088-0.313-1.563-0.658-3.276
                                                    c-2.752-13.414-3.779-30.908-2.798-47.642c0.203-3.463,0.316-4.465,0.795-6.657c0.727-3.377,2.094-8.83,3.229-12.887l0.911-3.248
                                                    h0.835h0.835l-0.713,2.206c-2.096,6.479-3.221,10.605-4.313,15.834l-0.574,2.729l0.179,8.503
                                                    c0.336,15.996,1.137,31.188,2.192,41.586c0.136,1.354,0.215,2.571,0.174,2.729C60.098,467.227,60.057,467.283,60.046,467.194
                                                    L60.046,467.194z"/>
                    <path id="CirculatorySystem" d="M153.363,638.553c-0.004-0.146,1.209-7.854,1.561-9.854
                                                    c0.292-1.694,0.294-1.521,0.004,0.453c-0.11,0.748-0.2,1.438-0.2,1.542c0,0.209,0.082,0.276,0.201,0.187
                                                    c0.063-0.055,0.083-0.052,0.083,0.021c0,0.188-1.526,7.516-1.613,7.729C153.379,638.653,153.365,638.621,153.363,638.553
                                                    L153.363,638.553L153.363,638.553z"/>
                    <path id="CirculatorySystem" d="M147.121,675.908c-2.029-0.179-3.391-1.129-3.633-2.549
                                                    c-0.103-0.586-0.135-2.566-0.061-3.297c0.219-2.07,0.904-3.906,2.086-5.567c0.527-0.74,1.869-2.039,2.438-2.356
                                                    c0.063-0.026,0.021-0.179-0.229-0.706c-0.814-1.714-1.313-3.427-1.604-5.563c-0.133-0.979-0.156-3.771-0.053-4.854
                                                    c0.066-0.675,3.24-20.104,3.295-20.178c0.008-0.007,0.074,0.007,0.146,0.033c0.215,0.078,1.181-0.001,1.243-0.104
                                                    c0.03-0.061,0.054-0.063,0.055-0.013c0.004,0.313-2.271,12.566-3.016,16.263c-0.644,3.146-0.597,2.869-0.679,4.025
                                                    c-0.266,3.729,0.791,7.956,2.754,11.003c0.271,0.424,0.332,0.48,0.908,0.847c4.642,2.938,8.064,6.071,9.08,8.308l0.193,0.426
                                                    l-0.099,0.46c-0.268,1.3-0.752,2.238-1.299,2.496c-0.104,0.05-0.331,0.091-0.502,0.091c-0.172,0-2.338,0.278-4.813,0.618
                                                    C148.699,675.922,148.033,675.989,147.121,675.908L147.121,675.908z M153.026,674.411c3.205-0.386,3.438-0.43,4.043-0.79
                                                    c0.664-0.388,1.188-1.172,1.363-2.035c0.08-0.393,0.081-0.479,0.006-0.801c-0.464-2.001-3.007-4.324-6.838-6.246
                                                    c-0.951-0.477-2.324-1.104-2.418-1.104c-0.08,0-0.656,0.631-1.119,1.225c-1.113,1.43-2.144,3.485-2.617,5.215
                                                    c-0.293,1.074-0.43,1.965-0.508,3.323l-0.033,0.547l0.283,0.181c0.604,0.395,1.861,0.733,2.952,0.814
                                                    C149.285,674.824,149.988,674.777,153.026,674.411L153.026,674.411z"/>
                    <path id="CirculatorySystem" d="M141.279,658.485c0-0.021,0.049-0.374,0.107-0.789
                                                    c0.165-1.127,0.602-4.627,0.794-6.398c0.786-7.135,1.21-13.574,1.29-19.595c0.021-1.561,0.059-2.772,0.078-2.694
                                                    c0.019,0.078,0.043,0.544,0.046,1.035l0.009,0.896h0.485h0.48l-0.026,0.577c-0.021,0.314-0.063,1.271-0.101,2.107
                                                    c-0.301,7.895-1.139,15.666-2.301,21.313C141.82,656.556,141.279,658.753,141.279,658.485L141.279,658.485z"/>
                    <path id="CirculatorySystem" d="M85.736,657.097c-1.497-6.062-2.523-14.716-2.872-24.024
                                                    c-0.025-0.873-0.063-1.759-0.08-1.957c-0.012-0.203-0.01-0.324,0.004-0.271c0.022,0.085,0.103,0.104,0.517,0.104h0.479
                                                    l-0.035-11.896c-0.019-6.543-0.05-12.675-0.063-13.626c-0.021-0.949-0.021-1.717-0.005-1.699c0.082,0.08,0.174,10.021,0.18,19.431
                                                    c0.007,10.688,0.015,11.153,0.23,15.107c0.322,6.034,1.015,13.146,1.813,18.854c0.078,0.557,0.132,1.021,0.12,1.036
                                                    C86.009,658.15,85.88,657.681,85.736,657.097L85.736,657.097L85.736,657.097z"/>
                    <path id="CirculatorySystem" d="M74.015,674.971c-2.542-0.354-4.704-0.636-4.804-0.622
                                                    c-0.807,0.107-1.489-0.856-1.858-2.624l-0.087-0.416l0.222-0.474c1.044-2.229,4.354-5.257,8.948-8.195l0.747-0.478l0.345-0.552
                                                    c1.95-3.124,2.979-7.392,2.674-11.077c-0.043-0.523-0.167-1.331-0.315-2.104c-0.528-2.604-0.999-5.023-1.521-7.818
                                                    c-0.548-2.938-1.791-9.808-1.79-9.89c0-0.021,0.025-0.007,0.062,0.041c0.066,0.116,1.046,0.188,1.252,0.092l0.144-0.063
                                                    l0.357,2.159c0.189,1.188,0.896,5.438,1.563,9.438c0.666,4.018,1.239,7.53,1.273,7.827c0.121,1.037,0.169,2.604,0.113,3.688
                                                    c-0.146,2.771-0.68,4.998-1.725,7.215L79.3,661.78l0.23,0.153c1.667,1.099,3.188,3.218,3.865,5.395
                                                    c0.429,1.374,0.567,2.48,0.525,4.208c-0.045,1.83-0.161,2.233-0.839,2.916c-0.615,0.618-1.5,0.979-2.739,1.117
                                                    C79.27,675.688,78.867,675.65,74.015,674.971L74.015,674.971z M79.518,674.378c0.998-0.124,1.684-0.307,2.342-0.623
                                                    c0.614-0.296,0.574-0.188,0.499-1.268c-0.146-2.093-0.604-3.771-1.546-5.649c-0.65-1.298-1.412-2.415-2.358-3.457l-0.274-0.31
                                                    l-0.91,0.41c-0.821,0.37-2.465,1.202-3.05,1.544c-2.33,1.354-4.153,2.998-4.932,4.422c-0.548,1.012-0.569,1.671-0.104,2.659
                                                    c0.354,0.757,1.104,1.346,2.004,1.563c0.201,0.058,1.591,0.24,3.088,0.42C77.186,674.448,78.42,674.513,79.518,674.378
                                                    L79.518,674.378z"/>
                    <path id="CirculatorySystem" d="M73.515,636.395c-0.552-2.559-1.146-5.436-1.146-5.563
                                                    c0-0.08,0.021-0.079,0.115,0.02c0.153,0.139,0.215,0.063,0.236-0.284c0.012-0.189,0.229,1.081,0.66,3.813
                                                    c0.353,2.256,0.632,4.113,0.62,4.125C73.988,638.522,73.77,637.571,73.515,636.395L73.515,636.395z"/>
                    <path id="CirculatorySystem" d="M-42.48,339.176c-2.059-0.554-4.361-2.199-6.492-4.637
                                                    c-2.043-2.337-3.021-4.836-2.769-7.068c0.13-1.146,0.749-3.178,1.175-3.85c0.167-0.265,3.417-4.084,7.221-8.488
                                                    c3.811-4.402,6.917-8.062,6.917-8.132c0-0.409-0.424-0.209-1.222,0.576c-1.678,1.651-4.252,3.271-6.879,4.333
                                                    c-1.543,0.623-4.458,1.429-5.775,1.597c-0.718,0.092-0.625,0.033,0.882-0.547c4.271-1.646,9.451-4.6,12.438-7.089
                                                    c2.479-2.068,5.873-5.888,9.45-10.642c10.148-13.495,20.813-32.292,31.225-55.07c2.419-5.292,3.17-6.55,5.152-8.624
                                                    c3.186-3.336,7.347-5.414,12.159-6.072l1.355-0.186l0.938-2.322c1.188-2.949,3.979-11.322,5.135-15.395
                                                    c3.05-10.797,5.421-22.066,7.003-33.286c0.924-6.554,1.872-11.27,3.869-19.25c1.813-7.251,4.99-17.766,7.799-25.827l0.95-2.727
                                                    l2.436-0.044l2.438-0.044l-0.531,0.695c-1.771,2.325-5.479,11.497-8.053,19.927c-2.616,8.574-6.5,24.172-6.5,26.101
                                                    c0,0.994-0.419,3.947-0.984,6.944c-1.858,9.872-5.827,24.251-11.051,40.089l-1.741,5.279l0.521,0.657
                                                    c1.266,1.599,2.311,3.767,2.834,5.879c0.646,2.617,0.6,6.368-0.122,9.279c-0.243,0.979-0.354,1.131-2.368,3.289
                                                    c-2.284,2.444-6.879,6.793-10.348,9.793c-2.283,1.975-3.278,3.019-6.969,7.307C-2.48,273.379-12.934,288-21.054,301.724
                                                    c-3.228,5.456-5,10.319-5.996,16.455c-0.605,3.769-1.48,6.132-3.583,9.669c-1.854,3.138-4.388,6.482-7.442,9.849
                                                    C-39.704,339.479-40.438,339.726-42.48,339.176L-42.48,339.176L-42.48,339.176z M-39.689,338.231
                                                    c1.296-0.604,2.757-2.22,4.067-4.495c0.438-0.773,1.857-2.896,3.148-4.717c1.29-1.822,2.48-3.506,2.646-3.74
                                                    c0.396-0.574,1.312-4.202,1.729-6.872c0.606-3.87,0.849-5.021,1.46-7.141c1.909-6.535,5.62-13.354,10.042-18.446
                                                    c0.46-0.529,2.097-2.875,3.623-5.214c10.246-15.628,18.921-27.065,26.022-34.309c3.5-3.57,6.747-6.162,8.299-6.627
                                                    c1.283-0.385,3.64-3.952,4.583-6.94c0.357-1.134,0.409-1.58,0.413-3.619c0.003-1.905-0.063-2.558-0.348-3.609
                                                    c-0.402-1.472-1.488-3.695-2.429-4.959l-0.641-0.867l-1.008,0.078c-5.604,0.43-10.275,3.042-13.501,7.542
                                                    c-0.771,1.07-2.021,3.392-2.495,4.623c-0.822,2.13-5.258,11.493-8.779,18.528c-8.371,16.726-15.71,29.162-22.766,38.58
                                                    c-2.563,3.421-4.18,5.324-6.459,7.62c-1.103,1.103-2.369,2.51-2.828,3.128c-0.459,0.618-3.815,4.387-7.457,8.376
                                                    c-4.926,5.395-6.726,7.471-7.021,8.101c-0.755,1.613-1.021,2.721-1.116,4.547c-0.159,3.112,0.56,5.005,2.664,7.019
                                                    c1.486,1.425,2.344,2.021,4.315,3.026C-41.718,338.76-40.984,338.834-39.689,338.231L-39.689,338.231L-39.689,338.231z"/>
                    <path id="CirculatorySystem" d="M268.362,339.266c-0.338-0.084-0.784-0.392-1.122-0.772
                                                    c-0.311-0.349-1.075-1.21-1.703-1.913c-4.127-4.63-7.504-9.873-8.976-13.936c-0.354-0.984-0.73-2.554-1.037-4.369
                                                    c-1.045-6.146-2.823-11.156-5.68-15.989c-9.18-15.521-20.812-31.518-32.406-44.564c-0.532-0.6-2.162-2.143-3.616-3.428
                                                    c-4.38-3.866-8.087-7.38-10.339-9.801l-2.131-2.29l-0.27-1.239c-0.729-3.444-0.735-6.306-0.025-9.088
                                                    c0.469-1.839,1.51-4.079,2.547-5.489l0.724-0.981l-1.189-3.668c-4.854-14.889-8.063-25.986-10.229-35.431
                                                    c-1.316-5.742-2.348-11.65-2.348-13.484c0-1.307-3.241-14.736-5.221-21.634c-2.646-9.197-5.401-16.76-7.91-21.668
                                                    c-0.586-1.146-1.272-2.319-1.524-2.607l-0.457-0.523h2.479h2.478l2.271,6.778c5.26,15.743,8.557,28.799,10.3,40.786
                                                    c2.749,18.912,7.152,36.52,12.411,49.566l0.713,1.762l1.42,0.195c5.635,0.772,10.417,3.553,13.729,7.986
                                                    c1.129,1.512,1.383,2.005,4.047,7.866c4.156,9.145,11.214,22.974,15.763,30.88c9.561,16.628,19.355,29.952,25.317,34.446
                                                    c3.063,2.303,7.146,4.559,11.063,6.106c2.294,0.908,2.288,1-0.028,0.498c-4.299-0.931-8.403-2.979-11.135-5.556
                                                    c-0.826-0.779-1.169-1.011-1.303-0.879c-0.138,0.132,0.022,0.422,0.604,1.101c0.435,0.508,3.596,4.176,7.024,8.148
                                                    c6.699,7.766,6.93,8.077,7.413,10.369c0.466,2.184-0.021,4.562-1.313,6.531c-0.313,0.47-1.302,1.599-2.199,2.511
                                                    c-1.645,1.664-3.181,2.784-4.693,3.42C270.865,339.297,269.186,339.474,268.362,339.266L268.362,339.266L268.362,339.266z
                                                    M271.997,337.819c1.991-1.018,3.896-2.438,4.985-3.729c1.463-1.729,2.04-3.389,2.02-5.815c-0.014-1.604-0.298-2.889-1.02-4.622
                                                    c-0.451-1.083-0.904-1.619-7.16-8.454c-3.672-4.014-7.013-7.76-7.42-8.326s-1.614-1.902-2.682-2.968
                                                    c-7.355-7.357-16.509-21.41-26.7-41c-3.525-6.774-10.482-21.238-11.459-23.824c-1.752-4.642-5.391-8.769-9.316-10.579
                                                    c-2.104-0.97-4.438-1.572-6.771-1.746l-1.005-0.075l-0.65,0.907c-0.932,1.297-2.021,3.585-2.416,5.078
                                                    c-0.494,1.864-0.505,4.822-0.021,6.588c0.623,2.283,1.607,4.162,3.313,6.286c0.439,0.551,0.915,1.004,1.055,1.006
                                                    c0.146,0.002,0.643,0.215,1.104,0.473c6.986,3.853,19.416,18.922,33.87,41.071c1.354,2.073,2.87,4.239,3.371,4.812
                                                    c2.554,2.921,5.521,7.483,7.146,11.002c2.326,5.016,3.442,8.747,4.246,14.183c0.188,1.279,0.597,3.33,0.903,4.56l0.563,2.23
                                                    l2.688,3.784c1.479,2.081,2.931,4.217,3.227,4.746c1.312,2.35,2.95,4.224,4.243,4.833
                                                    C269.401,338.843,270.156,338.761,271.997,337.819L271.997,337.819L271.997,337.819z"/>
                    <path id="CirculatorySystem" d="M113.319,130.899c-1.226-0.314-2.021-1.04-2.554-2.331
                                                    c-0.325-0.787-1.124-1.764-3.997-4.888c-2.577-2.802-3.952-3.83-7.593-5.676c-8.854-4.486-17.124-5.852-25.398-4.192
                                                    c-6.813,1.367-13.188,4.961-18.918,10.663l-1.862,1.854l-2.466,0.044c-2.336,0.042-2.46,0.029-2.35-0.25
                                                    c0.063-0.162,0.479-1.305,0.926-2.54c0.949-2.629,1.32-3.182,3.967-5.911c8.645-8.904,19.898-12.747,31.53-10.761
                                                    c6.695,1.143,13.759,4.146,20.749,8.82c1.784,1.193,2.214,1.287,2.344,0.513c1.146-6.809-0.123-19.198-3.141-30.76
                                                    c-1.707-6.536-4.79-15.924-6.506-19.811c-0.843-1.909-1.589-3.873-2.563-6.737c-2.759-8.145-4.427-15.957-5.027-23.581
                                                    c-0.104-1.235-0.285-3.488-0.416-5.006c-0.842-9.732,0.418-17.557,3.936-24.567c1.528-3.045,4.422-6.86,6.564-8.654
                                                    c0.779-0.658,0.859-0.688,1.731-0.642l0.915,0.048l-1.346,0.554c-1.161,0.478-1.524,0.732-2.646,1.845
                                                    c-4.291,4.272-6.938,11.971-7.606,22.145c-0.154,2.32-0.067,4.394,0.396,9.465c1.156,12.599,3.354,23.007,6.247,29.677
                                                    c0.938,2.145,2.021,4.12,2.317,4.22c0.318,0.107,1.642-1.397,2.478-2.823c0.436-0.731,1.101-2.067,1.479-2.968
                                                    c0.803-1.879,0.88-1.821,0.293,0.22c-0.82,2.849-1.997,5.672-3.058,7.324l-0.582,0.911l1.543,4.739
                                                    c4.655,14.308,7.801,27.774,8.849,37.944c0.136,1.323,0.293,3.868,0.349,5.655l0.104,3.25l0.647,0.176
                                                    c1.611,0.434,3.459-0.202,4.402-1.515l0.547-0.763l-0.263-1.316c-0.853-4.278-0.733-11.135,0.312-18.881
                                                    c1.063-7.888,3.082-17.333,5.955-27.872l1.063-3.89l-0.567-0.855c-0.82-1.235-1.388-2.408-1.908-3.948
                                                    c-0.623-1.84-0.564-2.128,0.104-0.585c0.307,0.698,0.813,1.673,1.146,2.166c0.662,1,2.437,2.783,2.631,2.658
                                                    c0.246-0.152,1.738-4.158,2.545-6.836c3.313-10.993,5.01-23.357,5.267-38.499c0.104-6.673,0.065-7.181-0.834-10.49
                                                    c-0.824-3.007-2.673-7.396-3.849-9.116c-0.479-0.718-0.276-0.76,0.396-0.081c2.148,2.148,4.136,6.9,4.955,11.848
                                                    c0.546,3.296,0.661,5.081,0.661,10.266c0,17.143-2.613,32.797-7.15,42.855c-2.41,5.337-5.228,16.716-6.469,26.125
                                                    c-0.783,5.95-1.234,13.598-1.07,18.207c0.131,3.695,0.445,8.217,0.59,8.449c0.08,0.129,0.902-0.325,2.578-1.423
                                                    c3.146-2.064,4.443-2.8,7.688-4.362c9.347-4.506,18.459-5.709,26.854-3.545c6.113,1.575,11.979,4.998,16.711,9.753
                                                    c2.86,2.877,3.354,3.583,4.313,6.173c0.461,1.254,0.889,2.442,0.95,2.64l0.104,0.361h-2.396h-2.396l-1.826-1.808
                                                    c-3.846-3.805-7.131-6.169-11.401-8.208c-4.666-2.225-8.979-3.193-14.24-3.197c-5.09-0.004-9.527,0.877-14.604,2.898
                                                    c-3.819,1.526-8.758,4.304-9.75,5.494l-0.521,0.632l0.589,0.771c1.287,1.684,1.751,3.807,1.375,6.305
                                                    c-0.188,1.256-1.295,1.546-3.256,0.854l-0.741-0.261l-0.097-1.034c-0.162-1.73-1.229-3.466-2.545-4.148
                                                    c-0.402-0.209-0.521-0.207-0.979,0.012c-1.078,0.515-1.662,1.933-1.826,4.438c-0.063,1.005-0.172,1.594-0.299,1.642
                                                    C114.969,130.947,113.774,131.015,113.319,130.899L113.319,130.899L113.319,130.899z"/>
                </g>
            </ToolTip>
        )
    }

    function Esophagus() {
        return (
            <ToolTip
                //  title="Esophagus"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">
                <path
                    id="Esophagus"
                    className="Esophagus"
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "Esophagus" ? 5 : 0.5}
                    //  strokeWidth={eso ? 10 : 0.5}
                    fill="#00008b"
                    fillOpacity="0.5"
                    stroke="#00008b"
                    //  strokeWidth="0.5"
                    d="M229.155,136.059
                                                c0.101,14.84,0.568,29.672,0.591,44.516c0.011,9.012-0.868,18.128,0.001,27.11c0.359,3.77,0.729,7.824,0.727,11.789
                                                c0.693-0.356,1.401-0.712,2.134-1.123c1.104-0.616,2.151-1.591,3.188-2.705c-0.229-6.227-0.278-12.482-0.353-18.711
                                                c-0.164-15.387-1.438-30.737-1.422-46.11c0.007-10.021-0.352-19.916-1.102-29.884c-0.323-4.305,0.313-8.695-0.541-12.96
                                                c-0.33-1.652-0.729-4.417-2.595-4.816c-1.662-0.355-6.192,1.64-8.027,2.007v0.547C230.423,112.668,229.092,126.434,229.155,136.059z
                                                "/>
            </ToolTip>
        )
    }
    function GalBladder() {
        return (
            <ToolTip
                //   title="GallBladder"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">
                <path
                    id="Gallbladder"
                    className="gall_bladder"
                    //   onMouseEnter={handleMouseEnter}
                    //   onMouseLeave={handleMouseLeave}
                    //   strokeWidth={gal ? 20 : 0.5}
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "Gallbladder" ? 13 : 0.5}
                    fill="#800080"
                    fillOpacity="0.5"
                    stroke="#800080"
                    // strokeWidth="0.5"
                    d="M214.75,231.5
                                            c-2.462-0.055-8.877-1.733-10.175,1.517c-1.906,4.77,7.269,4.012,9.553,3.994c2.029-0.017,9.188,0.599,9.7-2.883
                                            c0.47-3.208-7.819-2.605-9.827-2.628"/>
            </ToolTip>
        )
    }

    function Liver() {
        return (
            <ToolTip
                //  title="Liver"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">
                <path
                    id='Liver and intrahepatic bile ducts'
                    className="Liver"
                    fill="#800000"
                    //   strokeWidth={liver ? 10 : 0.5}
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === 'Liver and intrahepatic bile ducts' ? 8 : 0.5}
                    fillOpacity="0.5"
                    stroke="#800000"
                    // strokeWidth="0.5"
                    d="M197.814,254.221
                                                c3.532-0.967,6.563-2.202,9.563-4.215c4.147-2.783,8.129-6.554,11.707-9.975c1.389-1.33,2.837-2.638,4.815-3.046
                                                c3.723-0.765,7.458-1.232,11.112-2.355c4.158-1.278,7.914-3.587,11.279-6.158c2.992-2.286,7.766-8.155,3.896-11.644
                                                c-2.021-1.822-5.588-1.64-8.209-1.506c-3.76,0.191-7.504,0.807-11.271,0.846c-7.039,0.072-13.565-2.428-20.402-3.53
                                                c-5.975-0.963-12.244-0.149-16.96,3.527c-5.196,4.051-7.469,11.844-7.473,17.954c-0.002,2.655,0.334,5.474,1.002,8.057
                                                c0.583,2.254,1.675,4.472,2.078,6.739c0.316,1.816-1.037,4.9,0.712,6.313C191.739,256.9,195.671,254.808,197.814,254.221z"/>
            </ToolTip>
        )
    }

    function Lung() {

        return (
            <ToolTip
                //   title="Lung" 
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">
                <path id="Lung" className="lung"
                    fill="#ffd700" fillOpacity="0.5"
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "Lung" ? 10 : 0.5}
                    // strokeWidth={lung ? 20 : 0.5}
                    stroke="#ffd700"
                    //strokeWidth="0.5"
                    d="M220.109,140.161
                                                c-8.377-0.101-26.996-1.415-33.942,7.271c-2.766,3.447-3.973,11.976-5.82,15.45c-2.772,5.194-1.633,12.48-2.91,17.268
                                                c-1.813,6.801,1.641,8.806,5.82,12.724c4.769,4.468,15.037,6.363,22.309,9.088c7.025,2.633,10.703-5.579,11.646-9.088
                                                c1.146-4.319,8.179-7.491,11.64-11.815c2.769-3.456,3.88-9.581,3.88-14.541c0-2.115,1.94-8.063,1.94-12.723
                                                c0-5.702-2.281-8.499-5.82-11.815C227.913,141.111,221.011,140.372,220.109,140.161z"/>
            </ToolTip>
        )
    }
    function Pancreas() {

        return (
            <ToolTip
                //   title="Pancreas"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">
                <path
                    id="Pancreas"
                    className="pancreas"
                    //   onMouseEnter={handleMouseEnter}
                    //   onMouseLeave={handleMouseLeave}
                    //   strokeWidth={panc ? 20 : 0.5}
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "Pancreas" ? 10 : 0.5}
                    fill="#ff00ff"
                    fillOpacity="0.5"
                    stroke="#ff00ff"
                    //strokeWidth="0.5"
                    d="M266.973,254.765
                                            c-16.765,9.238-29.807,14.289-29.807,14.289l-18.568,0.76l-8.433-4.23l-1.955-2.581l1.489-3.148l10.314-4.721l15.551-0.622
                                            l16.05-4.788l14.233-4.833L268.4,246l1.819,2.807l-0.144,3.391L266.973,254.765z"/>
            </ToolTip>
        )
    }

    function Ovary() {

        return (
            <ToolTip
                placement="right"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
            >
                <path
                    id="Ovary"
                    className="prostate"
                    fill="#8b008b "
                    //   strokeWidth={pros ? 20 : 0.5}
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "Ovary" ? 10 : 0.5}
                    fillOpacity="0.5"
                    stroke="#8b008b"
                    //strokeWidth="0.5"
                    d="M247.002,345.606
                                            c0,3.913-4.729,7.085-10.55,7.085c-5.829,0-10.557-3.172-10.557-7.085s4.729-7.085,10.557-7.085
                                            C242.28,338.521,247.002,341.694,247.002,345.606z"/>
            </ToolTip>
        )
    }
   

    function Skin() {

        return (
            <ToolTip
                // title="Skin"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">
                <path
                    id="Skin"
                    className="skin"
                    fill="#bdb76b"
                    //   strokeWidth={skin ? 20 : 0.5}
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "Skin" ? 20 : 0.5}
                    stroke="#bdb76b"
                    fillOpacity="0.5"
                    d="M315.75,197.5c0.669,4.716,3.612,8.76,5.105,13.263c1.392,4.198-1.286,17.414,3.933,19.572
                                            c5.205,2.154,3.629-15.588,2.814-18.597c-1.017-3.754-3.752-7.978-5.541-11.396c-1.111-2.123-2.781-5.594-5.812-4.092"/>
            </ToolTip>
        )
    }


    function LargeIntestine() {
        return (
            <ToolTip
                //  title="Small Intestine"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">
                <path
                    id="Large Intestine"
                    className="large_intestine"
                    fill="#ffff00 "
                    //   strokeWidth={small ? 20 : 0.5}
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "Large Intestine" ? 10 : 0.5}
                    fillOpacity="0.5"
                    stroke="#ffff00"
                    // strokeWidth="0.5"
                    d="M261.399,283.242
                                            c0.482-0.575,1.416-0.613,2.291-0.739c1.287-1.915,3.82-2.005,5.621-3.457c2.87-2.313,2.037-8.969-2.164-10.227
                                            c-3.76-1.127-7.744,0.939-10.297,2.78c-2.34,1.687-5.088,3.957-4.938,6.523c-0.027-0.46-0.063-1.082-0.234-1.5
                                            c-0.855,0.188-1.716,0.717-2.707,0.76c1.088-0.327,2.342-0.667,3.189-1.279c1.709-1.234,2.854-3.667,2.94-5.402
                                            c0.091-1.813-1.311-3.036-3.755-3.39c-6.172-0.891-9.287,3.629-14.789,3.76c-2.092,0.049-3.98-0.627-6.054-0.543
                                            c-2.54,0.104-4.782,1.085-7.03,1.851c-1.938,0.66-4.617,1.632-6.75,0.972c-0.771-0.239-1.531-0.558-2.251-0.872
                                            c-0.934-0.408-1.948-0.906-2.261-1.71c0.546,1.049-0.828,1.926-2.153,2.093c-1.457,0.186-3.106,0.478-4.595,0.264
                                            c1.492,1.918,3.104,3.736,6.083,4.507c1.937,0.501,4.188,0.85,6.222,0.858c1.204,0.006,2.372-0.073,3.563-0.22
                                            c0.896-0.109,1.914-0.156,2.597-0.633c0.531-0.369,0.695-0.731,1.468-0.928c0.92-0.234,1.969-0.286,2.934-0.382
                                            c1.043-0.104,2.209-0.097,3.254-0.001c1.303,0.121,2.498,0.533,3.778,0.714c1.01,0.142,1.981,0.215,2.979,0.409
                                            c0.807,0.156,2.703,0.837,3.353,0.23c0.334-0.313,0.479-0.726,0.864-1.044c0.456-0.371,1.021-0.686,1.524-1.027
                                            c1.289-0.873,2.479-1.853,3.479-2.893c0.588-0.615,0.494-1.618,0.271-2.345c0.257,0.563,0.104,1.193,0.476,1.733
                                            c0.155-0.37,2.029-0.497,2.563-0.728c-1.063,0.192-2.205,0.521-2.979,1.072c-1.096,0.787-1.792,1.95-2.789,2.812
                                            c-0.896,0.777-3.417,1.356-3.229,2.581c0.102,0.68,0.938,0.794,1.745,1.125c1.281,0.53,2.585,1.045,3.882,1.567
                                            c1.504,0.604,5.412,1.496,6.897,0.527c0.95-0.618,1.868-1.569,2.734-2.269c2.286-1.847,5.331-3.432,6.687-5.777
                                            c-0.214,0.838-1.587,2.065-2.173,2.93c1.102-0.681,2.112-1.169,3.58-1.321c-3.214,0.543-4.679,1.738-6.749,3.405
                                            c-1,0.804-1.852,1.692-2.812,2.509c-0.521,0.439-1.212,0.81-1.652,1.302c-0.39,0.434-0.306,0.733-0.339,1.222
                                            c-0.053,0.798-0.619,1.282-0.95,2.023c-0.634,1.413,1.007,2.146,2.546,2.647c0.866,0.28,1.771,0.507,2.604,0.841
                                            c0.328,0.132,0.915,0.314,1.157,0.515c0.471,0.385,0.194,0.86,0.354,1.316c0.179,0.533,0.747,1.008,0.913,1.569
                                            c0.163,0.563,0.178,1.184-0.156,1.705c-0.331,0.518-1.098,0.772-1.363,1.28c1.926,1.194,6.48,2.127,7.895,0.199
                                            c0.457-0.625,0.503-1.38,0.563-2.069c0.086-0.931,0.205-1.894,0.113-2.832c-0.063-0.683-0.24-1.363-0.345-2.042
                                            c-0.077-0.5-0.479-1.333-0.319-1.811c0.103-0.296,0.732-0.653,1.004-0.91c0.604-0.574,1.105-1.195,1.783-1.729
                                            c-1.166,0.75-2.563,1.931-2.5,3.12c0.072,1.299,0.148,2.62,0.22,3.929c0.104,1.825-0.347,3.593-1.343,5.273
                                            c-0.969,1.637-3.203,3.309-2.639,5.193c0.155-1.75,0.687-3.916,2.48-5.223c-0.545,0.396-2.193,0.334-2.889,0.279
                                            c-1.07-0.085-1.959-0.379-2.863-0.783c-1.688-0.753-2.893,0.029-3.58,1.237c-0.479,0.841-0.174,1.924-0.102,2.801
                                            c0.078,0.9-0.311,1.794,0.24,2.626c0.516,0.783,1.424,1.507,1.836,2.302c-0.271-0.378-0.565-0.744-0.887-1.109
                                            c-0.197-0.229-0.875-1.173-1.214-1.236c-0.323-0.06-0.513,0.224-0.772,0.392c-0.875,0.563-1.867,1.1-2.854,1.554
                                            c0.73-0.776,1.987-1.259,2.904-1.911c0.862-0.612,0.47-1.529,0.479-2.279c0.013-1.081,0.313-2.321,0.646-3.37
                                            c0.404-1.252,2.41-1.632,3.172-2.726c0.339-0.486,0.697-1.344,0.604-1.896c-0.074-0.43-1.363-1.463-1.334-1.608
                                            c0.188-0.939-0.729-1.712-1.853-2.069c-1.193-0.382-2.248-0.161-3.312,0.34c-2.043,0.962-3.027,2.757-4.187,4.237
                                            c-0.327,0.421-0.597,0.848-0.856,1.287c-0.208,0.348-0.861,0.967-0.83,1.354c0.021,0.243,0.391,0.557,0.549,0.77
                                            c0.326,0.439,0.636,0.876,0.83,1.352c-0.117-0.473-0.896-1.979-1.713-1.926c1.008-2.368,3.47-4.375,5.228-6.459
                                            c0.239-0.282,0.479-0.604,0.771-0.849c0.133-0.114,0.53-0.268,0.594-0.401c0.193-0.425-0.77-0.575-1.063-0.775
                                            c-0.403-0.273-0.675-0.74-0.771-1.128c-0.274-1.115,0.855-1.577,1.123-2.187c0.434-0.99-0.04-1.722-1.268-2.2
                                            c-0.625-0.243-1.184-0.298-1.813-0.328c-0.407-0.021-0.571,0.09-1.021-0.042c-1.146-0.337-2.229-0.904-3.313-1.352
                                            c-1.145-0.471-2.396-0.83-3.613-1.188c-2.271-0.661-4.589-1.179-6.933-1.67c-2.301-0.482-4.572-1.1-7.008-0.923
                                            c-1.396,0.101-4.137,0.462-4.5,1.684c-0.146,0.477,0.683,0.982,0.849,1.438c0.505,1.381-1.249,2.448-2.379,3.346
                                            c-1.205,0.955-2.42,1.93-3.071,3.157c0.513,0.574,1.68,0.598,2.521,0.704c1.444,0.182,2.688,0.778,4.033,1.227
                                            c2.771,0.922,6.026,2.091,8.139,3.734c0.771-1.062,1.787-1.956,3.109-2.712c-2.415,2.119-3.972,2.847-1.908,5.543
                                            c-0.31-1.803-1.818-3.234-3.863-4.169c-3.172-1.45-12.409-6.517-14.45-1.671c1.201-0.983,1.646-2.396,2.709-3.475
                                            c1.005-1.015,2.343-1.806,3.382-2.807c0.929-0.89,2.22-2.244,1.283-3.368c-0.964-1.167-2.751-0.625-4.137-0.463
                                            c-5.049,0.595-8.37-0.413-12.979-1.694c-4.042-1.124-7.229-0.833-9.781,1.458c-1.074,0.964-2.479,1.626-2.622,2.775
                                            c-0.135,1.026,0.292,2.035-0.032,3.157c-0.387,1.339-0.897,2.622-1.084,3.987c-0.269,1.951,0.919,3.696,0.811,5.642
                                            c-0.068,1.33-1.059,2.726-0.792,4.043c0.151,0.036,0.289-0.014,0.438,0.034c0.533,0.743-0.057,1.456,0.337,2.319
                                            c0.713,1.575,2.854,4.336,5.146,4.917c1.521,0.388,4.688,0.768,6.064-0.025c0.704-0.405,0.913-1.263,1.188-1.845
                                            c0.469-0.987,1.361-2.337,2.543-2.971c2.944-1.586,7.521-0.078,10.063-2.183c0.283-0.234,0.564-0.498,0.823-0.746
                                            c-0.446,0.466-1.066,0.978-1.299,1.514c0.021,0.016,0.043,0.03,0.063,0.048c1.141-0.131,2.487-0.234,3.657-0.158
                                            c-0.734,0.249-1.882,0.1-2.722,0.199c-0.616,0.071-1.185,0.105-1.798,0.142c-0.979,0.059-1.876,0.391-2.834,0.466
                                            c-0.758,0.061-1.556,0.021-2.322,0.078c-1.663,0.125-3.396,0.369-4.563,1.318c-0.598,0.484-0.75,1.67-1.121,2.266
                                            c-0.282,0.455-1.182,1.212-1.296,1.681c-0.635,2.579,3.926,5.361,6.689,6.213c3.608,1.113,4.789-0.968,6.792-2.666
                                            c2.536-2.151,5.523-3.816,8.919-5.178c1.286-0.515,1.614-0.899,1.604-1.992c0.057,0.391,0.021,0.825,0.232,1.186
                                            c0.5-0.108,1.092-0.058,1.625-0.116c-2.071-0.039-3.896,1.046-5.497,1.891c-0.523,0.276-2.354,1.021-1.417,1.561
                                            c0.599,0.347,1.322,0.234,2.016,0.317c0.809,0.098,1.645,0.311,2.436,0.468c0.895,0.179,4.096,0.727,2.821,1.651
                                            c0.854-0.182,1.841,0.035,2.663,0.155c0.438,0.064,1.025,0.009,1.381,0.193c-0.023-0.297,0.224-0.882,0.339-1.23
                                            c0.351-1.071,1.011-2.145,1.692-3.12c0.353-0.493,1.876-1.802,2.738-1.603c-1.252,0.197-2.162,1.154-2.768,1.914
                                            c0.93-0.134,1.754-0.512,2.673-0.652c0.716-0.109,1.485-0.072,2.194-0.151c-1.387,0.155-2.684,0.374-4.063,0.688
                                            c-0.637,0.146-0.837,0.175-1.145,0.601c-0.588,0.824-0.755,1.682-1.224,2.521c-0.161,0.292-0.414,0.627-0.331,0.963
                                            c0.28,1.118,2.146,1.537,3.367,1.949c1.803,0.605,2.578,1.586,3.828,2.665c0.951,0.822,1.646,1.977,3.192,2.289
                                            c1.565,0.319,2.97-0.47,4.394-0.774c-1.07,0.38-2.563,0.284-3.414,0.93c-0.996,0.755-1.813,2.511-1.479,3.511
                                            c0.188-0.862,1.274-2.821,0.5-3.545c-0.372-0.348-1.348-0.591-1.821-0.946c-1.688-1.258-2.472-3.107-4.763-3.902
                                            c-0.824-0.287-1.763-0.326-2.568-0.613c-1.039-0.367-1.063-1.387-2.238-1.646c-0.412-0.091-3.64-0.15-3.695-0.062
                                            c0.13-0.204,0.396-0.342,0.706-0.383c-0.146-0.309-0.759-0.593-1.188-0.75c-2.063-0.755-5.7-1.381-7.979-0.893
                                            c-1.433,0.308-2.563,1.055-3.451,1.902c-0.856,0.821-1.742,1.766-2.401,2.68c-0.396,0.546-0.122,1.041,0.516,1.396
                                            c0.621,0.348,1.563,0.711,2.271,0.958c1.896,0.664,4.049,0.283,6.063,0.45c1.072,0.09,2.012,0.021,2.95,0.465
                                            c0.992,0.471,1.625,1.289,2.336,1.937c-0.813-1.05-3.739-2.314-5.45-2.476c-2.125-0.2-4.093,0.075-6.199-0.465
                                            c-1.585-0.405-5.61-2.306-7.056-0.825c-0.402,0.416-0.463,1.613-0.645,2.126c-0.324,0.915-1.001,2.073-0.781,3.021
                                            c0.385,1.66,3.562,3.187,5.445,3.979c0.923,0.387,1.851,0.498,2.848,0.707c0.896,0.188,1.789,0.354,2.722,0.428
                                            c1.282,0.101,2.593,0.067,3.878,0.129c1.038,0.053,2.063,0.196,3.098,0.251c1.313,0.069,2.688,0.156,4.004,0.153
                                            c0.474,0,0.896-0.145,1.357-0.2c0.58-0.071,1.146-0.053,1.729-0.006c-0.785,0.078-1.979-0.058-2.673,0.212
                                            c0.639,0.313,1.145,0.757,1.778,1.065c-0.502-0.285-0.938-0.619-1.512-0.849c-0.438-0.177-0.832-0.165-1.318-0.195
                                            c-1.768-0.107-3.532-0.315-5.313-0.381c-1.995-0.076-3.914-0.239-5.876-0.494c-3.926-0.512-8.292-1.588-10.107-4.446
                                            c-0.932-1.464,0.665-3.184,0.929-4.637c0.328-1.841-2.021-3.307-4.358-3.649c-0.994-0.146-2.545-0.512-3.552-0.268
                                            c-0.939,0.226-1.894,1.174-2.673,1.603c-0.813,0.445-1.556,0.746-2.137,1.39c-1.548,1.71-0.153,3.265,2.414,3.517
                                            c2.125,0.208,4.545-1.229,5.09-2.632c-0.342,0.78-1.188,1.389-2.112,1.875c-0.838,0.438-0.866,0.475-1.203,1.167
                                            c-0.677,1.383-1.387,2.517-1.466,4.037c-0.063,1.216,0.271,3.067,1.521,3.897c1.646,1.092,3.856,0.214,5.636,0.595
                                            c0.037-0.125,0.168-0.314,0.156-0.462c-0.004-0.039,0.06,0.305,0.063,0.385c-0.026-0.452-0.442-0.887-0.539-1.348
                                            c1.304,1.988,4.014,3.331,6.715,4.242c2.675,0.904,5.854,0.874,8.717,0.594c2.173-0.212,3.96-0.143,6.104-0.012
                                            c2.564,0.158,4.584,0.208,7.084-0.234c1.854-0.33,4.512-0.653,4.78-2.396c0.177-1.106,0.251-2.721-0.636-3.661
                                            c-0.735-0.786-2.104-1.224-2.813-2.011c-0.449,0.329-1,0.111-1.641,0.217c-0.729,0.121-1.309,0.457-2.087,0.481
                                            c-2.33,0.077-4.894-0.527-7.024-1.169c-1.604-0.48-3.271-0.572-4.844-1.123c-1.084-0.381-2.256-1.01-3.358-1.293
                                            c-1.177-0.3-2.375,0.036-3.531,0.196c0.636,0.024,1.26-0.225,1.916-0.242c-0.253-0.378-0.577-0.815-0.665-1.223
                                            c0.33,1.532,2.536,1.379,4.038,1.909c0.469,0.165,0.872,0.393,1.327,0.575c0.853,0.346,1.828,0.49,2.764,0.666
                                            c0.889,0.166,1.793,0.278,2.664,0.49c0.776,0.188,1.467,0.509,2.215,0.747c0.988,0.314,1.968,0.352,3.041,0.406
                                            c1.045,0.055,1.917-0.094,2.938-0.234c0.822-0.113,1.813-0.237,2.141-0.879c0.359-0.715,0.107-1.611-0.264-2.295
                                            c-1.013-1.85-2.938-4.372-5.57-5.186c-0.688-0.214-1.012-0.44-1.59-0.751c-0.691-0.371-0.733-0.308-1.671-0.358
                                            c-0.625-0.033-1.202-0.155-1.821-0.176c-0.729-0.022-1.392,0.112-2.126,0.119c0.905-0.436,2.173-0.354,3.103-0.127
                                            c-0.428-0.35-0.61-0.84-1.023-1.208c0.238,0.371,0.773,1.108,1.272,1.318c0.425,0.179,1.071,0.032,1.526,0.128
                                            c0.939,0.198,1.563,0.854,2.487,1.156c1.161,0.377,2.579,0.936,3.378,1.711c0.785,0.766,1.113,1.854,1.533,2.75
                                            c0.373,0.798,0.875,1.568,0.916,2.419c0.029,0.7,0.229,1.174,0.947,1.702c1.188,0.875,2.926,1.448,4.526,1.783
                                            c1.485,0.309,2.946,0.498,4.474,0.464c1.495-0.031,1.895-0.003,2.854-0.758c2.605-2.041,4.116-4.016,4.884-6.827
                                            c0.191-0.7-0.254-1.527-0.879-2.118c-0.832-0.789-2.559-0.733-3.651-1.214c-0.005-0.005,0.052-0.092,0.042-0.12
                                            c-3.028-0.893-5.932-1.483-6.178-4.18c-0.104-1.212,0.471-2.34,1.271-3.376c1.928-2.5-1.68-3.199-3.901-4.166
                                            c-0.634-0.277-1.56-0.881-2.343-0.908c-1.311-0.046-2.283,0.859-3.533,0.939c-1.405,0.091-3.188-0.4-4.525-0.675
                                            c-1.021-0.21-1.906-0.398-2.77-0.803c-0.873-0.413-1.562-0.689-2.603-0.321c-1.321,0.47-1.466,1.798-2.907,2.484
                                            c-1.398,0.664-3.037,1.104-4.501,1.668c-1.572,0.604-4.913,1.534-5.512,2.877c-0.418,0.946-0.414,2.373,0.148,3.276
                                            c-0.608-1.295-0.076-2.474-0.151-3.729c-0.031-0.55-0.228-0.569-0.873-0.802c-1.266-0.454-2.545-0.635-3.436-1.521
                                            c0.678,0.761,2.767,1.548,4.007,1.725c0.725,0.104,2.141-0.512,2.845-0.702c1.565-0.426,2.98-1.164,4.443-1.744
                                            c1.611-0.64,3.403-1.022,4.319-2.276c0.51-0.697,1.194-1.227,0.877-2.055c-0.271-0.686-1.124-1.33-1.886-1.747
                                            c-0.854-0.465-1.821-0.837-2.825-1.093c-0.778-0.2-1.609-0.26-2.39-0.464c-0.688-0.18-1.274-0.48-1.979-0.652
                                            c-1.432-0.35-3.001-0.345-4.491-0.287c-0.702,0.024-1.423,0.006-2.107,0.141c-0.66,0.131-1.342,0.304-1.979,0.114
                                            c-1.344-0.398-1.926-1.745-1.991-2.678c-0.096-1.341,0.595-2.395,1.086-3.634c0.327-0.823,0.688-1.639,0.647-2.507
                                            c-0.028-0.61-0.163-0.638-1.009-0.741c-0.741-0.09-1.504-0.141-2.254-0.083c-1.961,0.15-3.009,1.554-3.591,2.766
                                            c-0.749,1.557-1.351,3.01-1.627,4.649c-0.223,1.302-0.278,2.474,0.043,3.759c0.299,1.179,0.191,2.236,2.188,2.062
                                            c-0.391-0.081-0.876,0.104-1.323,0.025c0.459,1.034,1.243,2.104,1.962,3.057c-1.601-1.563-2.862-3.651-3.184-5.593
                                            c-0.243-1.472,0.178-3.381,0.793-4.801c0.79-1.818,0.686-4.362,3.293-5.441c0.181-0.191,0.647-0.41,1.027-0.483
                                            c-3.125,0.235-6.771-0.533-7.271-3.1c0.479,1.154,2.162,2.572,3.799,2.974c0.936,0.228,2.051,0.042,3.021,0.126
                                            c0.646,0.057,1.173,0.046,1.848,0.046c1.249,0,2.479,0.149,3.688-0.097c1.649-0.338,3.508-1.006,4.988-1.621
                                            c-1.761,0.49-3.383,1.261-5.102,1.817c-0.738,0.238-0.987,0.307-1.212,0.866c-0.271,0.682-0.307,1.396-0.48,2.093
                                            c-0.171,0.674-0.6,1.249-0.85,1.905c-0.508,1.328-0.604,2.823,0.638,3.959c1.341,1.227,3.57,0.153,5.595,0.104
                                            c2.644-0.063,4.333,0.781,6.61,1.438c1.396,0.4,3.183,0.58,4.397,1.257c1.147,0.64,1.604,1.59,3.001,2.116
                                            c2.854,1.076,6.385,2.205,9.639,2.069c3.318-0.14,4.211-2.58,4.76-4.581c0.321-1.173,0.465-2.363,0.657-3.548
                                            c0.179-1.1,0.695-2.099,0.81-3.203c0.279-2.771-2.258-3.021-5.544-3.844c-1.634-0.407-4.291-1.418-5.991-1.387
                                            c-1.006,0.017-2.708,0.727-3.813,0.873c1.137-0.396,2.313-0.747,3.51-1.039c-2.271-0.564-1.792-1.571-3.171-2.76
                                            c1.566,3.79,9.771,3.188,13.255,5.103c3.313,1.817,1.648,4.345,1.16,7.161c-0.257,1.489-0.621,3.111-1.293,4.534
                                            c-0.63,1.336-1.104,1.451,0.286,2.364c0.703,0.461,1.731,0.514,2.565,0.825c1.615,0.601,3.641,1.669,3.021,3.223
                                            c-0.396,0.999-1.133,1.897-1.396,2.961c-0.668,2.696,2.309,4.772,5.675,5.392c1.604,0.298,3.584,0.563,5.196,0.149
                                            c2.308-0.59,2.143-2.012,3.556-3.196c-0.758-0.421-1.119-1.132-1.088-1.841c-0.138,2.812,7.263-0.571,8.411-1.129
                                            c2.783-1.348,3.129-3.424,4.607-5.453c1.26-1.726,0.248-2.357-0.204-4.319c-0.479-2.077,0.803-4.052,1.204-6.075
                                            c0.563-2.811,1.217-9.186-4.043-9.833c-1.65-0.204-3.638,0.369-4.826,1.286c-0.678,0.521-0.921,1.2-1.797,1.577
                                            c-1.3,0.562-2.777-0.142-4.004,0.48c1.287-0.188,2.587-0.342,3.873-0.533"/>
            </ToolTip>
        )
    }

    function Stomach() {
        return (
            <ToolTip
                // title="Testis"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">
                <path
                    id="Stomach"
                    className="Stomach"
                    //   strokeWidth={stomach ? 20 : 0.5}
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "Stomach" ? 10 : 0.5}
                    fill="#648c11"
                    fillOpacity="0.5"
                    stroke="#648c11"
                    //  strokeWidth="0.5"
                    d="M262.916,219.401
                                            c-5.788-4.85-17.363-1.243-19.912,4.814l0.258-1.779c-1.775,0.847-1.959,2.909-2.836,4.298c-1.112,1.765-3.505,2.635-5.183,3.924
                                            c-3.307,2.538-2.187,7.75-7.688,7.753c-1.378-0.071-2.756-0.128-4.136-0.173c0.101,0.716-0.184,1.28-0.84,1.691
                                            c-1.35,0.749-3.242,0.479-4.744,0.704c-3.462,0.521-6.354,3.36-7.447,6.301c1.984,1.908,4.021,3.754,6.209,5.416
                                            c0.59-1.529,1.923-2.49,4.563-2.365c3.683,0.174,6.461,3.357,9.713,4.544c4.14,1.51,8.297-1.693,12.465-2.065
                                            c6.965-0.621,22.063-6.492,23.802-13.072C268.536,234.101,267.696,223.405,262.916,219.401z"/>
            </ToolTip>
        )
    }
    function CorpusUteri() {
        return (
            <ToolTip
                // title="Urinary Bladder"
                title={top10CanSiteList?.map(obj => {
                    if (obj.name === activePart) {
                        return (
                            <b>{obj.name} : {obj.value} </b>
                        )
                    }
                })}
                placement="right">
                <path
                    id="Corpus Uteri"
                    className="corpus_uteri"
                    fill="#ff4500 "
                    //   strokeWidth={urinary ? 20 : 0.5}
                    onMouseEnter={handleMouseEnterPath}
                    onMouseLeave={handleMouseLeavePath}
                    strokeWidth={activePart === "Corpus Uteri" ? 20 : 0.5}
                    fillOpacity="0.5"
                    stroke="#ff4500"
                    // strokeWidth="0.5"
                    d="M231.139,325.129
                                        c-15.16-2.202-28.836,18.655-9.9,21.638C236,349,251.281,331.751,232.759,325.776"/>
            </ToolTip>
        )
    }
    const RADIAN = Math.PI / 180;
    const BiologicalColor = [{
        code: '#EB455F',
        name: 'Immunotherapy'
    },
    {
        code: '#BAD7E9',
        name: 'Targeted Therapy'
    },
    ]
   
    const DrugsCountColor = [{
        code: '#EB455F',
        name: "Pembrolizumab"
    },
    {
        code: '#BAD7E9',
        name: "Nivolumab"
    },
    {
        code: '#323D7B',
        name: "Cemiplimab"
    },
    {
        code: '#775CA1',
        name: "Atezolizumab"
    },
    {
        code: '#006D77',
        name: "Avelumab"
    },
    {
        code: '#83C5BE',
        name: "Durvalumab"
    },
    ]
    const DrugColor = [{
        code: '#EB455F',
        name: 'Chemo Therapy'
    },
    {
        code: '#BAD7E9',
        name: 'Biological Therapy'
    },
    {
        code: '#323D7B',
        name: 'Hormonal Therapy'
    },
    ]
    const calculateLabelColor = (backgroundColor) => {
        if (backgroundColor.startsWith('#')) {
            const hex = backgroundColor.replace('#', '');
            const rgb = hex.match(/.{1,2}/g).map(val => parseInt(val, 16));

            const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;

            return luminance > 0.5 ? '#000000' : '#ffffff'; // Light background: use dark label color, Dark background: use light label color
        }

        if (backgroundColor.startsWith('rgb')) {
            const rgb = backgroundColor.match(/\d+/g).map(val => parseInt(val, 10));
            const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
            return luminance > 0.5 ? '#000000' : '#ffffff';
        }

        return '#000000';
    };
  
    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        const [isHovered, setHovered] = useState(false);

        const handleMouseEnter = () => {
            setHovered(true);
        };

        const handleMouseLeave = () => {
            setHovered(false);
        };

        const renderLabel = () => {
            const { value } = payload;
            return isHovered ? value : value
            //  return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
            //   return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
        };

        return (
            <g transform={`translate(${x},${y})`} textAnchor="end" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <text
                    x={0}
                    y={10}
                    dy={0}
                    fontSize={isHovered ? 14 : 10}
                    fontWeight={isHovered ? 'bold' : 'bold'}
                    textAnchor="middle"
                    fill={isHovered ? '#5373ff' : "#666"}
                //  transform={`rotate(${LABEL_ANGLE})`}
                >

                    {renderLabel()}
                </text>
            </g>
        );
    };

    const PdlCloneCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (pdlCloneTotal)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };

    const CustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.cancerSiteName}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.cancerSiteCount}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (primarySiteTotal)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const topCustomizedLabel = (props) => {
        const { x, y, width, height, value, fill } = props;
        const radius = 10;
        return (
            <g>
                {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill='#a9a9a9' /> */}
                <text x={x + width / 2} y={y - radius} fontSize={12} fill="#a9a9a9" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };

    const TMBMSICustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x - 8} y={y}
                fill={calculateLabelColor(payload?.fill)}
                fontSize={10}
                // textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central">
                {(percent * 100).toFixed(0) > 1 ? `${(percent * 100).toFixed(0)}%` : null}
                {/* {payload?.payload?.value} */}
            </text>

        );
    };
    const TDBCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x - 8} y={y}
                // fill="white"
                fill={calculateLabelColor(payload.fill)}
                fontSize={10}
                style={{ fontWeight: 'bold', fontFamily: 'Mulish' }}
                // textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central">
                {(percent * 100).toFixed(0) >= 1 ? `${(percent * 100).toFixed(0)}%` : null}
            </text>

        );
    };


    const PrimarySiteCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }, props) => {
        const value = (percent * 100).toFixed(0) >= 10 ? 0.5 : 0.8
        const radius = innerRadius + (outerRadius - innerRadius) * value;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x} y={y}
                fill={calculateLabelColor(payload?.fill)}
                fontSize={(percent * 100).toFixed(0) > 2 ? 10 : 5}
                textAnchor="middle" dominantBaseline="central">
                {(percent * 100).toFixed(0) >= 2 ? `${(percent * 100).toFixed(0)}%` : null}
            </text>

        );
    };


    function citiesModal() {
        setCitiesOpen(true);
    }
    function hospitalModal() {
        sethospitalOpen(true);
    }

    function primaryModal() {
        setprimarySite(true);
    }
    function histopathologyModal() {
        setHistoPathOpen(true);
    }
    
    function tmbModal() {
        setTmbOpen(true);
    }
    function msiModal() {
        setMsiOpen(true);
    }
    function statusValModal() {
        setStatusShow(true);
    }
    function cloneValModal() {
        setCloneShow(true);
    }
    function ageGenderModal() {
        setAgeGenderOpen(true);
    }
    function biomarModal() {
        setBioShow(true);
    }
    function cancerstatModal() {
        setCancerShow(true);
    }
    function recistModal() {
        setRecist(true);
    }

    function recistCloseModal() {
        setRecist(false);
    }

    function closeModal() {
        setStatusShow(false);
    }
    function valueCloseModal() {
        setCloneShow(false);
    }
    function biomarCloseModal() {
        setBioShow(false);
    }
    function cancerstatCloseModal() {
        setCancerShow(false);
    }
    function histopathologyCloseModal() {
        setHistoPathOpen(false);
    }

    function tmbCloseModal() {
        setTmbOpen(false);
    }
    function msiCloseModal() {
        setMsiOpen(false);
    }
    function ageGenderCloseModal() {
        setAgeGenderOpen(false);
    }

    function primarycloseModal() {
        setprimarySite(false);
    }

    function drugModal() {
        setDrugOpen(true);
    }
    function drugscountModal() {
        setDrugsCountOpen(true);
    }
    function biologicalModal() {
        setBiologicalOpen(true);
    }
    function drugcloseModal() {
        setDrugOpen(false);
    }
    function biologicalcloseModal() {
        setBiologicalOpen(false);
    }
    function drugscountcloseModal() {
        setDrugsCountOpen(false)
    }
    // function useDidUpdateEffect(callback, dependencies) {
    //     const didMountRef = useRef(false);
    //     useEffect(() => {
    //         if (didMountRef.current) {
    //             callback();
    //         } else {
    //             didMountRef.current = true;
    //         }
    //     }, dependencies);
    // }
    // useDidUpdateEffect(() => {
    //     pdlHospfun();
    // }, [hospName]);
    function hospitalLocation(params) {
        //  event.preventDefault();
        // const hospital = params?.hospitalNameResList?.filter((item) => {
        //     if ((item.value !== 0)) {
        //         return item
        //     }
        // })
        // setHospitalNameData(hospital)
        // setHospNameData
        sethospitalShow(true);
        setHospName(params?.name)
        console.log("ppp", params?.name)
        if (params?.value) {
            pdlHospfun()
        }
        return null;
    }
    function citiesCloseModal() {
        setCitiesOpen(false);
    }
    function hospitalCloseModal() {
        sethospitalOpen(false);
    }
    function commafy(num) {
        if (num !== undefined && num !== null) {
            var str = num.toString().split('.');
            if (str[0].length >= 0) {
                str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
            }
            if (str[1] && str[1].length >= 0) {
                str[1] = str[1].replace(/(\d{3})/g, '$1 ');
            }
            return str.join('.');
        }
        return '0';
    }

    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value, fill } = props;
        const radius = 13;
        return (
            <g>
                {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill={fill} /> */}
                <text x={x + width / 2} y={y - radius} fontSize={12} fill="#a9a9a9" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };
    const renderLegend = (props) => {
        const { payload } = props;

        return (
            <div>
                {
                    payload.map((entry, index) => (
                        <li key={`item-${index}`}>{entry.payload[0]}</li>
                    ))
                }
            </div>
        );
    }
    const HistopathologyCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalMorphologyInitial = MorphologyCount?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalMorphologyInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const CancerSiteCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalcancerSiteCountInitial = cansiteval?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalcancerSiteCountInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const TmbCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totaltmbValueCountInitial = tmbValue?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totaltmbValueCountInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const MsiCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalmsiValueCountInitial = msiValue?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalmsiValueCountInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const PdlStatusCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalpdlInitial = pdlStatusVal?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalpdlInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const PdlcloneCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalpdlCloneInitial = pdlCloneVal?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalpdlCloneInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };

    const DrugCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalDrugInitial = drugTherapy?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalDrugInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };

    const BiologicalCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalBiologicalInitial = biologicalTherapy?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            console.log("bio", biologicalTherapy);
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalBiologicalInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const DrugsCountCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalDrugsCountInitial = drugsCount?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            //  console.log("bio", biologicalTherapy);
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalDrugsCountInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const TreatmentResCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalTreatmentInitial = recistCriteria?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            //  console.log("bio", biologicalTherapy);
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalTreatmentInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const Top20CitiesCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const total20CitiesInitial = hospCity?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            //  console.log("bio", biologicalTherapy);
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (total20CitiesInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const BBioCustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload.cancerSiteCountList;
            const total = payload[0].payload.total;
            // const dtt = payload[0].payload;
            return (
                <div className="custom-tooltip">
                    <p>Total: {total}</p>
                    {data.map((entry) => (
                        <p key={entry.cancerSiteName}>
                            {entry.cancerSiteName}: {entry.cancerSiteCount}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    // const BBioCustomTooltip = ({ active, payload, label }) => {
    //     if (active && payload && payload.length) {
    //       return (
    //         <div className="custom-tooltip">
    //           {/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}
    //           {payload[0].payload.cancerSiteCountList.map((item, index) => (
    //             <p key={index} style={{ color: payload[index].fill }}>{`${item.cancerSiteName}: ${item.cancerSiteCount}`}</p>
    //           ))}
    //         </div>
    //       );
    //     }
    //     return null;
    //   };
    // const BBioCustomTooltip = ({ active, payload, label }) => {
    //     if (active && payload && payload.length) {
    //       return (
    //         <div className="custom-tooltip">
    //           <p className="label">{`${label} : ${payload[0].value}`}</p>
    //           {payload[0].payload.cancerSiteCountList.map((item, index) => (
    //             <p key={index} style={{ color: payload[0].fill }}>{`${item.cancerSiteName}: ${item.cancerSiteCount}`}</p>
    //           ))}
    //         </div>
    //       );
    //     }
    //     return null;
    //   };
    const MAX_LABEL_LENGTH = 10; // Maximum number of characters to show in the label
    const LABEL_ANGLE = -25; // Angle (in degrees) to rotate the labels

    const CustomizedXAxisTick = (props) => {
        const { x, y, payload } = props;
        const [isHovered, setHovered] = useState(false);

        const handleMouseEnter = () => {
            setHovered(true);
        };

        const handleMouseLeave = () => {
            setHovered(false);
        };

        const renderLabel = () => {
            const { value } = payload;
            return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
            //   return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
        };

        return (
            <g transform={`translate(${x},${y})`} textAnchor="end" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <text
                    x={0}
                    y={0}
                    dy={4}
                    dx={14}
                    fontSize={isHovered ? 12 : 10}
                    fontWeight={isHovered ? 'bold' : ''}
                    textAnchor="end"
                    fill={isHovered ? '#5373ff' : "#666"}
                    transform={`rotate(${LABEL_ANGLE})`}
                >

                    {renderLabel()}
                </text>
            </g>
        );
    };

    const CustomTick = ({ x, y, payload }) => {
        // const{payload} = humanBodyData
        const [isHovered, setHovered] = useState(false);
        // if (name.toLowerCase() === "john")
        const color = payload.value === activePart ? 'red' : 'black' && isHovered ? '#5373ff' : 'black';

        //   const color = payload.value === activePart  ? 'red' : 'black';
        //   const col = activePart ? payload.value === activePart :

        const handleMouseEnter = () => {
            setHovered(true);
        };

        const handleMouseLeave = () => {
            setHovered(false);
        };

        const renderLabel = () => {
            const { value } = payload;
            //  const col = activePart ? payload.value === activePart :
            //     return activePart ? activePart :value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
            //     return activePart ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
            return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
        };


        return (
            <g transform={`translate(${x},${y})`} textAnchor="end" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <text
                    x={0}
                    y={0}
                    dy={0}
                    // dx={0}
                    fontSize={isHovered ? 13 : 12}
                    fontWeight={isHovered ? 'bold' : 'normal'}
                    textAnchor="end"
                    fill={color}
                // transform={`rotate(${LABEL_ANGLE})`}
                >
                    {renderLabel()}
                </text>
            </g>
        );
    };

    return (
        <div className="content-wrapper ">
            <div className="content-wrapper_inner">
                {IsLoading && <div className='downloadLoader '>
                    <Loader type="spinner-circle" bgColor='black' title={"loading..."} color={'#FFFFFF'} size={100} />
                </div>
                }
                <div>
                </div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-1 ">
                            <div className="col px-0">
                                <div className='w-100'></div>
                                <div className='d-flex align-items-center justify-content-between bg-white rounded py-3 cust_flex'>
                                    <div className='title_panel'>
                                        <h4 className='mb-0'>PD-L1 Analysis</h4>
                                        {/*  */}




                                    </div>

                                    <div className='m-top'>
                                        {/* <div className='panel_main'>
                                            <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div className="panel panel-default">
                                                    <div className="panel-heading" role="tab" id="heading-1">
                                                        <h4 className="panel-title range_button mb-0">
                                                            <button type="button" className='btn btn-outline-blue filter_button ml-0' data-toggle="collapse" data-parent="#accordion" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                                                                Filter
                                                            </button>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse-1" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="heading-1">
                                                        <div className="panel-body panel_inner">

                                                            <div className="panel-group" id="accordion-1" role="tablist" aria-multiselectable="true">
                                                                <div className="panel panel-default">
                                                                    <div className="panel-heading" role="tab" id="heading-1-1">
                                                                        <h4 className="panel-title">
                                                                            <a role="button" className='heading' data-toggle="collapse" data-parent="#accordion-1" href="#collapse-1-1" aria-expanded="false" aria-controls="collapse-1-1">
                                                                                Cancer site
                                                                            </a>
                                                                        </h4>
                                                                    </div>
                                                                    <div id="collapse-1-1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-1-1">
                                                                        <div className="panel-body font_12">
                                                                            Lung
                                                                        </div>
                                                                    </div>
                                                                    <div id="collapse-1-1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-1-1">
                                                                        <div className="panel-body font_12">
                                                                            Breast
                                                                        </div>
                                                                    </div>
                                                                    <div id="collapse-1-1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-1-1">
                                                                        <div className="panel-body font_12">
                                                                            Stomach
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="panel panel-default">
                                                                    <div className="panel-heading" role="tab" id="heading-1-2">
                                                                        <h4 className="panel-title">
                                                                            <a className="collapsed" className='heading' role="button" data-toggle="collapse" data-parent="#accordion-1" href="#collapse-1-2" aria-expanded="false" aria-controls="collapse-1-2">
                                                                                Drug Count
                                                                            </a>
                                                                        </h4>
                                                                    </div>
                                                                    <div id="collapse-1-2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-1-2">
                                                                        <div className="panel-body">
                                                                            Text 1 - 2
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div> */}
                                        {userDetails?.email==='roche@user' && value ?
                                            <button type="button" className="btn btn-outline-blue excel_button" onClick={() => pdlexcel()}>
                                                Roche Excel <i className="zmdi zmdi-download excel_download"></i>

                                            </button>
                                            : null}
                                        {/* <button onClick={pdlexcel}>ExcelData</button> */}
                           {drugFilterVal !== '' || pdlFilterVal !== '' || pdlCloneFilterVal !== '' || filterVal !== '' ||biologicalFilterVal !== '' | primaryFilterVal !== '' ? 
                                        (
                                            <div className="btn-group range_button det_height">
                                                <button type="button" className="btn btn-outline-blue dropdown-toggle" onClick={() => clearState()}>
                                                    Clear Filter
                                                </button>
                                            </div>
                                        ) : null}

                                           <div className="btn-group range_button det_height cancersite_btn">
                                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                            {primaryFilterVal ? primaryFilterVal : 'Cancer Site'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right mx-2 ">
                                                <div className='mx-2'>
                                                 <input type="text"
                                               className="search-input"
                                               placeholder="Search..."
                                               value={searchInput}
                                               onChange={(e) => setSearchInput(e.target.value)}
                                             //   onChange={(e) => searchCancerType(e)} 
                                              />
                                                </div>
                                                {filteredSites?.map((item,i) => {
                                                    return (
                                                        <button key={i} onClick={() => setPrimaryFilterVal(item.name)} className="dropdown-item" type="button">{item.name}</button>
                                                    )
                                                })}
                                                <button onClick={() => setPrimaryFilterVal('')} className="dropdown-item" type="button">All Cancer Type</button>
                                            </div>
                                        </div>
                                         
                                         {/* <div className="btn-group range_button det_height cancersite_btn">
                                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                                {primaryFilterVal ? primaryFilterVal : 'Cancer site'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right mx-2 ">
                                                <div className='mx-2'>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search cancer sites..."
                                                        value={searchInput}
                                                        onChange={(e) => setSearchInput(e.target.value)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                {filteredSites.map((site) => (
                                                    <button
                                                        key={site}
                                                        onClick={() => setPrimaryFilterVal(site)}
                                                        className="dropdown-item"
                                                        type="button"
                                                    >
                                                        {site}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>  */}

                                        <div className="btn-group range_button det_height ">
                                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                                {drugFilterVal ? drugFilterVal : 'Immunotherapy drugs'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right ">
                                                <button onClick={() => setDrugFilterVal('Atezolizumab')} className="dropdown-item" type="button">Atezolizumab</button>
                                                <button onClick={() => setDrugFilterVal('Nivolumab')} className="dropdown-item" type="button">Nivolumab</button>
                                                <button onClick={() => setDrugFilterVal('Pembrolizumab')} className="dropdown-item" type="button">Pembrolizumab</button>
                                                <button onClick={() => setDrugFilterVal('Durvalumab')} className="dropdown-item" type="button">Durvalumab</button>
                                                <button onClick={() => setDrugFilterVal('Avelumab')} className="dropdown-item" type="button">Avelumab</button>
                                                <button onClick={() => setDrugFilterVal('')} className="dropdown-item" type="button">All Status</button>

                                            </div>
                                        </div>
                                        {/* <div className="btn-group range_button det_height">
                                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                                {biologicalFilterVal ? biologicalFilterVal : 'Biological Therapy'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right ">
                                                <button onClick={() => setBiologicalFilterVal('Immunotherapy')} className="dropdown-item" type="button">Immunotherapy</button>
                                                <button onClick={() => setBiologicalFilterVal('Targeted therapy')} className="dropdown-item" type="button">Targeted therapy</button>
                                                <button onClick={() => setBiologicalFilterVal('')} className="dropdown-item" type="button">All Status</button>

                                            </div>
                                        </div> */}
                                        <div className="btn-group range_button det_height">
                                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                                {pdlFilterVal ? pdlFilterVal : 'PD-L1 Status'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right ">
                                                {/* <button onClick={() => filterGraphs('Last 30 Days')} className="dropdown-item" type="button">Last 30 Days</button> */}
                                                <button onClick={() => setPdlFilterVal('Positive')} className="dropdown-item" type="button">Positive</button>
                                                <button onClick={() => setPdlFilterVal('Negative')} className="dropdown-item" type="button">Negative</button>
                                                <button onClick={() => setPdlFilterVal('')} className="dropdown-item" type="button">All Status</button>

                                            </div>
                                        </div>

                                        {/* <div className="btn-group range_button det_height">
                                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                                {pdlCloneFilterVal ? pdlCloneFilterVal : 'PD-L1 Clones'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right ">

                                                <button onClick={() => setPdlCloneFilterVal('22C3 Dako')} className="dropdown-item" type="button">22C3 Dako</button>
                                                <button onClick={() => setPdlCloneFilterVal('SP142 Ventana')} className="dropdown-item" type="button">SP142 Ventana</button>
                                                <button onClick={() => setPdlCloneFilterVal('SP263 Ventana')} className="dropdown-item" type="button">SP263 Ventana</button>
                                                <button onClick={() => setPdlCloneFilterVal('')} className="dropdown-item" type="button">All Clones</button>
                                            </div>
                                        </div> */}

                                        <div className="btn-group range_button">

                                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                                <i className="zmdi zmdi-calendar-note"></i>{filterVal ? filterVal : 'Time Range'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right ">
                                                <button onClick={() => setFilterVal('Last 1 week')} className="dropdown-item" type="button">Last 1 Week</button>
                                                <button onClick={() => setFilterVal('Last 15 days')} className="dropdown-item" type="button">Last 15 Days</button>
                                                <button onClick={() => setFilterVal('Last 1 Month')} className="dropdown-item" type="button">Last 1 month</button>
                                                <button onClick={() => setFilterVal('Last 3 Month')} className="dropdown-item" type="button">Last 3 months</button>
                                                <button onClick={() => setFilterVal('Last 6 Month')} className="dropdown-item" type="button">Last 6 months</button>
                                                <button onClick={() => setFilterVal('Last 1 year')} className="dropdown-item" type="button">Last 1 year</button>
                                                <button onClick={() => setFilterVal('')} className="dropdown-item" type="button">All Time</button>

                                            </div>
                                        </div>
                                    </div >

                                </div >
                            </div >
                        </div >
                    </div >
                </div >
                {/* <!-- /.content-header --> */}

                {/*  */}
                <section className="content">
                    <div className="container-fluid px-0">
                        <div className=" rounded bg-white">
                            <div className="row ">
                                <div className="col-lg-3 col-md-6">
                                    <div className='row '>
                                        <div className="col-12 " style={{ 'height': 210 }}>
                                            <div className="card h-100">
                                                <div className='grap-title'>
                                                    <h6 className='mb-0 py-1'>Number of Patients</h6>
                                                    {/* <img src={landScape} /> */}
                                                </div>
                                                <div className='simple-number'>
                                                    <h2 className='mb-0'>{commafy(totalPatientCount) ? totalPatientCount : 0}</h2>
                                                    {/* <h2 className='mb-0'>{commafy(allGraphData?.patientCount)}</h2> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-12  mb-3" style={{ 'height': '198px', marginTop: 15 }}>
                                            <div className="card h-100">
                                                <div className='grap-title'>
                                                    <h6 className='mb-0 py-1'>Deceased Count</h6>
                                                </div>
                                                <div className='simple-number'>
                                                    <h2 className='mb-0'>{commafy(allGraphData?.cancerSiteCount)}</h2>
                                                    <h2 className='mb-0'>{commafy(deceasedVal)}</h2>

                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-12 text_cards text_cards-one  mb-3" style={{ marginTop: 15 }}>
                                            <div className='card' >
                                                <p><i className="zmdi zmdi-quote start"></i>Patient data spanning from December 2021 to June 2024, providing a comprehensive view of our Clinicogenomic Data<i className="zmdi zmdi-quote end"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3 col-md-6 pl-lg-0 pl-md-0 mt-md-0 mt-sm-3 mt-lg-0 Primary Site Distribution  pie_charts pdl1_charts pdl1_status primary" >
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Primary Site Distribution</h6>
                                            <a onClick={() => primaryModal()}>
                                                <img src={expand} />
                                            </a>
                                        </div>
                                        <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}></h6>

                                        {cancerSiteCount ?
                                            <ResponsiveContainer width="100%" height="100%"  >
                                                <PieChart
                                                    // ref={drugRef}
                                                    width={300} height={700}>
                                                    {/* <Legend verticalAlign="top" align="top" /> */}
                                                    <Pie
                                                        data={drugFilterVal !== '' || pdlFilterVal !== '' || pdlCloneFilterVal !== '' || (value)  ||biologicalFilterVal !== '' | primaryFilterVal !== '' ? cansiteval :cancerSiteCount}
                                                      //  data={cancerSiteCount}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={PrimarySiteCustomizedLabel}
                                                        outerRadius={'85%'}
                                                        innerRadius={'35%'}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {cansiteval.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={CanSiteColors.filter((item) => item.name === entry.name).length > 0 ? CanSiteColors.filter((item) => item.name === entry.name)[0].code : colrs[index % colrs.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip content={<CancerSiteCustomTooltip />} />
                                                </PieChart>
                                            </ResponsiveContainer>

                                            :
                                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3 col-md-6 pl-lg-0 mt-3 mt-lg-0   pie_charts pdl1_charts pdl1_status" >
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>PD-L1 Status</h6>

                                            <div>
                                                <img src={expand} onClick={() => statusValModal()} />
                                            </div>
                                        </div>
                                        <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}></h6>
                                        {pdlStatusVal ?
                                            <ResponsiveContainer width="100%" height="100%"  >
                                                <PieChart
                                                    // ref={drugRef}
                                                    width={300} height={400}>
                                                    <Legend verticalAlign="top" align="top" />
                                                    <Pie
                                                        data={pdlStatusVal}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={TDBCustomizedLabel}
                                                        outerRadius={'65%'}
                                                        innerRadius={'30%'}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {pdlStatusVal.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={ColorValues.filter((item) => item.name === entry.name).length > 0 ? ColorValues.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip content={<PdlStatusCustomTooltip />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                            :
                                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                        }

                                    </div>
                                </div>
                                <div className="col-lg-3 mb-3 col-md-6 pl-lg-0 pl-md-0 mt-3  mt-sm-3 mt-lg-0 pie_charts pdl1_charts pdl1_clones" >
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>PD-L1 Clones</h6>

                                            <div >
                                                <img src={expand} onClick={() => cloneValModal()} />
                                            </div>
                                        </div>
                                        <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}></h6>
                                        {pdlCloneVal ?
                                            <ResponsiveContainer width="100%" height="100%">
                                                <PieChart
                                                    // ref={bioloRef} 
                                                    width={300} height={400}>
                                                    <Legend verticalAlign="top" align="top" />
                                                    <Pie
                                                        //  ref={bioloRef}
                                                        data={pdlCloneVal}
                                                        cx="50%"
                                                        cy="50%"
                                                        labelLine={false}
                                                        label={TDBCustomizedLabel}
                                                        outerRadius={'65%'}
                                                        innerRadius={'30%'}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {pdlCloneVal.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.name).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip content={<PdlcloneCustomTooltip />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                            :
                                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />

                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row px-3 histopathology_row" >

                                <div className="col-lg-4 pl-0  mt-sm-0 pr-lg-3 mt-lg-0 pr-0">
                                    <div className="card h-100" >
                                        <div className='pie_charts msi'>
                                            <div className='grap-title' >


                                                <h6 className='mb-0 py-1'>Histopathology</h6>

                                                <div className="d-flex align-items-center">
                                                    {/* <button className="png-button" onClick={handlerDrugClick}><img src={download} className='download' /></button> */}
                                                    <div className="ml-3" onClick={() => histopathologyModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}>{morphologyTotal}</h6>

                                            <div>
                                                {MorphologyCount &&

                                                    <ResponsiveContainer width="100%" height="100%" >

                                                        <PieChart
                                                            // ref={drugRef}
                                                            width={300} height={700}>
                                                            {/* <Legend verticalAlign="top" align="top" /> */}
                                                            <Pie
                                                                data={MorphologyCount}
                                                                cx="50%"
                                                                cy="50%"
                                                                labelLine={false}
                                                                label={TDBCustomizedLabel}
                                                                outerRadius={'80%'}
                                                                innerRadius={'35%'}
                                                                fill="#8884d8"
                                                                dataKey="value"
                                                            >
                                                                {MorphologyCount.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.name).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                                ))}
                                                            </Pie>
                                                            <Tooltip content={<HistopathologyCustomTooltip />} />
                                                        </PieChart>
                                                    </ResponsiveContainer>

                                                }
                                                {/* {MorphologyCount ?
                                               <ResponsiveContainer width="100%" height={300}>
                                               <RadarChart cx="50%" cy="50%" outerRadius="80%" data={MorphologyCount}>
                                                 <PolarGrid />
                                                 <PolarAngleAxis dataKey="name" />
                                                 <PolarRadiusAxis />
                                                 <Radar  dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                                                 <Tooltip/>
                                               </RadarChart>
                                             </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            } */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 px-0 mt-sm-3 mt-lg-0 pr-lg-3">
                                    <div className="card h-100" >
                                        <div className='pie_charts pdl1'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>TMB</h6>
                                                <div className="d-flex align-items-center">

                                                    {/* <button className="png-button" onClick={handlerBiologicalClick}><img src={download} className='download' /></button> */}
                                                    <div className="ml-3" onClick={() => tmbModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}>{tmbTotal}</h6>

                                            {tmbValue ?
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <PieChart

                                                        // ref={drugRef}
                                                        width={300} height={400}>
                                                        <Legend iconType="rect" />
                                                        <Pie
                                                            data={tmbValue}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TDBCustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {tmbValue.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={ColorValues.filter((item) => item.name === entry.name).length > 0 ? ColorValues.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        <Tooltip content={<TmbCustomTooltip />} />
                                                    </PieChart>
                                                </ResponsiveContainer>

                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 pl-0 pr-0 mt-sm-3 mt-lg-0 pr-sm-0">
                                    <div className="card h-100" >
                                        <div className='pie_charts msi'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>MSI</h6>
                                                <div className="d-flex align-items-center">
                                                    {/* <button className="png-button" onClick={handlerDrugClick}><img src={download} className='download' /></button> */}
                                                    <div className="ml-3" onClick={() => msiModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}>{msiTotal}</h6>
                                            {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}

                                            {msiValue ?
                                                <ResponsiveContainer width="100%" height="100%"  >
                                                    <PieChart
                                                        // ref={drugRef}
                                                        width={300} height={400}>
                                                        <Legend verticalAlign="top" align="top" />


                                                        <Pie
                                                            data={msiValue}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TDBCustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {msiValue.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={MSIColorValues.filter((item) => item.name === entry.name).length > 0 ? MSIColorValues.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        <Tooltip content={<MsiCustomTooltip />} />
                                                    </PieChart>
                                                </ResponsiveContainer>

                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }


                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='py-3 px-3 pb-3'>
                                <div className='row humon_body border_line' style={{}}>
                                    <div className=' grap-title w-100'>
                                        <h6 className='mb-0 py-1'>Major Cancer Sites</h6>
                                        <div className="d-flex align-items-center">
                                            {/* <button className="png-button" onClick={handlerHumanClick}><img src={download} className='download' /></button> 
                                                <div className="ml-3" onClick={() => genderCancerModal()}>
                                                    <img src={expand} className='expandImage' />
                                                </div> */}
                                        </div>
                                    </div>
                                    <div className=" col-4" style={{ 'height': 650, marginTop: 8 }}>
                                        <div className=" h-100">


                                            <div className="svg-body">
                                                <svg
                                                    version="1.1"
                                                    id="svg3481"
                                                    sodipodidocname="test2.svg"
                                                    inkscapeversion="0.48.2 r9819"
                                                    xmlnssodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                                                    xmlnsdc="http://purl.org/dc/elements/1.1/"
                                                    xmlnscc="http://creativecommons.org/ns#"
                                                    xmlnsrdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                                                    xmlnssvg="http://www.w3.org/2000/svg"
                                                    xmlnsinkscape="http://www.inkscape.org/namespaces/inkscape"
                                                    xmlnsxml="http://www.w3.org/XML/1998/namespace"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px" y="0px" width="350px" height="600px"
                                                    viewBox="0 0 460 720"
                                                    enableBackground="new 0 0 460 720"
                                                    xmlSpace="preserve"
                                                    onMouseEnter={handleMouseEnterPath}
                                                    onMouseLeave={handleMouseLeavePath}
                                                >

                                                    <BodyLayout />
                                                    <Lung />
                                                    <Breast />
                                                    <Pancreas />
                                                    {/* <Femur/> */}
                                                    <CirculatorySystem />
                                                    <Esophagus />
                                                    <Stomach />
                                                    <LargeIntestine />
                                                    <CorpusUteri />
                                                    <Ovary />
                                                    {/* <Appendix/> */}
                                                    {/* <Duodenum/> */}
                                                    <Liver />
                                                    <GalBladder />
                                                    {/* <Adipose/> */}

                                                    {/* <Colon/>*/}

                                                    {/* <SkeletalMuscle/>*/}
                                                    <Skin />

                                                </svg>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-8 human_graph " style={{ 'height': 650, marginTop: 0 }}>
                                        <div className=" h-100">
                                            <div className='grap-title'>
                                                <h6 className='mb-0 py-1'></h6>
                                                <div className="d-flex align-items-center">
                                                    {/* <button className="png-button" onClick={ handleAgeClicker}><img src={download} className='download' /></button> */}
                                                    {/* <div className="ml-3" onClick={() => genderAgeModal()}>
                                                    <img src={expand} className='expandImage' />
                                                </div> */}
                                                </div>
                                            </div>

                                            {top10CanSiteList ?
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <BarChart
                                                        width={500}
                                                        height={200}
                                                        data={top10CanSiteList}
                                                        layout="vertical"
                                                        margin={{
                                                            top: 0,
                                                            right: 30,
                                                            left: 40,
                                                            bottom: 40,
                                                        }}
                                                    >
                                                        {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                        {/*        <XAxis dataKey="name" interval={0} tickLine={false} angle={-15} textAnchor="middle"  verticalAnchor="end" width={30} dx={-6}/>   */}
                                                        {/* <XAxis dataKey="name" interval={0} tickLine={false}
                                                        tick={<CustomTick />}
                                                    />
                                                    <YAxis /> */}
                                                        <XAxis type="number" />
                                                        <YAxis type="category" dataKey="name" tick={<CustomTick />} interval={0} />
                                                        <Tooltip cursor={{ fill: '#f7f7f7' }} />
                                                        {/* <Legend /> */}
                                                        {/* <Brush dataKey='name' height={30} stroke="#8884d8"/> */}
                                                        <Bar
                                                            dataKey="value" stackId="a" fill="#f4908e" barSize={40}

                                                        >
                                                            {/* <LabelList dataKey="value" content={renderCustomizedLabel} /> */}
                                                            {
                                                                top10CanSiteList.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={CanSiteColors.filter((item) => item.name === entry.name).length > 0 ? CanSiteColors.filter((item) => item.name === entry.name)[0].code : colrs[index % colrs.length]}
                                                                        onMouseEnter={handleMouseOverBar}
                                                                        onMouseOut={handleMouseOutBar} />
                                                                    // <Cell key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                                ))
                                                            }
                                                        </Bar>
                                                    </BarChart>
                                                </ResponsiveContainer>

                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row px-3 therapy_row" >

                                <div className="col-lg-4 pl-0  mt-sm-0 pr-lg-3 mt-lg-0 pr-0">
                                    <div className="card h-100" >
                                        <div className='pie_charts msi'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Drug Therapy</h6>

                                                <div className="d-flex align-items-center">
                                                    {/* <button className="png-button" onClick={handlerDrugClick}><img src={download} className='download' /></button> */}
                                                    <div className="ml-3" onClick={() => drugModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}>{drugTherapyTotal}</h6>
                                            {drugTherapy ?
                                                <ResponsiveContainer width="100%" height="100%"  >
                                                    <PieChart
                                                        // ref={drugRef}
                                                        width={300} height={400}>
                                                        <Legend verticalAlign="top" align="top" />
                                                        <Pie
                                                            data={drugTherapy}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TDBCustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {drugTherapy.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={DrugColor.filter((item) => item.name === entry.name).length > 0 ? DrugColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        <Tooltip content={<DrugCustomTooltip />} />
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }


                                            {/* {drugTherapy ?
                                        <div id="chartdiv" style={{ width: '400px', height: "450px" }}></div>        
                                        :     
                                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} /> */}



                                            {/*          <HighchartsReact
                                                     highcharts={Highcharts}
                                                    // options={options}
                                                     options={options2}

                                                    // ref="chartComponent1"
                                />         */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 px-0 mt-sm-3 mt-lg-0 pr-lg-3">

                                    <div className="card h-100" >
                                        <div className='pie_charts pdl1'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Biological Therapy</h6>
                                                <div className="d-flex align-items-center">
                                                    {/* <button className="png-button" onClick={handlerBiologicalClick}><img src={download} className='download' /></button> */}
                                                    <div className="ml-3" onClick={() => biologicalModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}>{biologicalTherapyTotal}</h6>
                                            {biologicalTherapy ?
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <PieChart
                                                        // ref={bioloRef} 
                                                        width={300} height={400}>
                                                        <Legend verticalAlign="top" align="top" />
                                                        <Pie
                                                            //  ref={bioloRef}
                                                            data={biologicalTherapy}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TDBCustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >

                                                            {biologicalTherapy.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={BiologicalColor.filter((item) => item.name === entry.name).length > 0 ? BiologicalColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        <Tooltip content={<BiologicalCustomTooltip />} />
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />

                                            }
                                            {/* {biologicalTherapy ?
                                        <div id="biologicaldiv" style={{ width: '400px', height: "450px" }}></div>        
                                        :
                                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />

                                    }     */}

                                            {/* <HighchartsReact
                                                    ref={bioloRef}
                                                     highcharts={Highcharts}
                                                    // options={options}
                                                     options={options3}
                                                   
                                />          */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 pl-0 pr-0 mt-sm-3 mt-lg-0 pr-sm-0">
                                    <div className="card h-100" >
                                        <div className='pie_charts msi'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Immunotherapy drugs</h6>
                                                <div className="d-flex align-items-center">
                                                    {/* <button className="png-button" onClick={handlerDrugClick}><img src={download} className='download' /></button> */}
                                                    <div className="ml-3" onClick={() => drugscountModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}>{drugsCountTotal}</h6>
                                            {drugsCount ?
                                                <ResponsiveContainer width="100%" height="100%"  >

                                                    <PieChart
                                                        // ref={drugRef}
                                                        width={300} height={400}>
                                                        <Legend verticalAlign="top" align="top" />
                                                        <Pie
                                                            data={drugsCount}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TDBCustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {drugsCount.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={DrugsCountColor.filter((item) => item.name === entry.name).length > 0 ? DrugsCountColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        <Tooltip content={<DrugsCountCustomTooltip />} />
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }

                                            {/* {drugTherapy ?
                                        <div id="chartdiv" style={{ width: '400px', height: "450px" }}></div>        
                                        :     
                                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} /> */}



                                            {/*          <HighchartsReact
                                                     highcharts={Highcharts}
                                                    // options={options}
                                                     options={options2}

                                                    // ref="chartComponent1"
                                />         */}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='row mt-3'>
                                <div className="col-6  pt-0" style={{ 'height': 520, marginTop: 0, marginBottom: 0 }}>
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Stage Vs Primary Sites</h6>
                                            <div onClick={() => biomarModal()}>
                                                <img src={expand} className='expandImage' />
                                            </div>
                                        </div>
                                        {cancerStage ?
                                            <ResponsiveContainer width="100%" height="85%">
                                                <BarChart width={400} height={400} data={cancerStage} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                                    <XAxis dataKey="biomarkerName" />
                                                    <YAxis />
                                                    <Tooltip content={<BBioCustomTooltip />} />
                                                    {/* <Legend /> */}
                                                    {cann.map((entry, index) => (
                                                        <Bar
                                                            key={index}
                                                            // key={`cancerSiteCountList[${index}].cancerSiteName`}
                                                            // key={`${entry.cancerSiteName}_${index}`}
                                                            //  dataKey={`cancerSiteCountList[${index}].cancerSiteCount`} 
                                                            dataKey={`cancerSiteCountList[${index}].cancerSiteCount`}
                                                            stackId="a" fill={HospitalColor[index % HospitalColor.length]} barSize={65} />
                                                    ))}
                                                    {/* <LabelList  dataKey="total" position="top" content={renderCustomLabel} /> */}

                                                </BarChart>
                                            </ResponsiveContainer>
                                            :
                                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                        }
                                    </div>
                                </div>
                                {/*  */}
                                <div className="col-6 pl-0  mt-0 top_cities">
                                    <div className="card h-100" >
                                        <div className='pie_charts msi legends_adjust'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Treatment Response</h6>
                                                <div className="d-flex align-items-center">
                                                    {/* <button className="png-button" onClick={handlerDrugClick}><img src={download} className='download' /></button> */}
                                                    <div className="ml-3" onClick={() => recistModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}>{top20CitiesTotal}</h6> */}
                                            {recistCriteria ?
                                                <ResponsiveContainer width="100%" height="100%"  >

                                                    <PieChart
                                                        // ref={drugRef}
                                                        width={300} height={500}
                                                    // margin={{
                                                    //     top: 25,
                                                    //     right: 30,
                                                    //     left: 20,
                                                    //     bottom: 5,
                                                    // }}
                                                    >
                                                        <Legend verticalAlign="top" align="top" />
                                                        <Pie
                                                            data={recistCriteria}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TDBCustomizedLabel}
                                                            outerRadius={'95%'}
                                                            innerRadius={'40%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {
                                                                recistCriteria.map((entry, index) => (
                                                                    <Cell onClick={() => hospitalLocation(entry)} key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                                ))
                                                            }
                                                        </Pie>
                                                        <Tooltip content={<TreatmentResCustomTooltip />} />
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='mt-3'>
                                <div className="col px-0 mb-3" style={{ 'height': 750, }}>
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Top 20 Biomarkers</h6>
                                            <div onClick={() => cancerstatModal()}>
                                                <img src={expand} className='expandImage' />
                                                {/* <div onClick={() => sethospitalShow(false)} style={{ marginLeft: 15 }}>
                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                            </div> */}
                                            </div>
                                        </div>
                                        {geneCancer ?
                                            <ResponsiveContainer width="100%" height={650}>
                                                <BarChart width={400} height={400} data={geneCancer} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                                    <XAxis dataKey="biomarkerName" />
                                                    <YAxis />
                                                    <Tooltip content={<BBioCustomTooltip />} />
                                                    {/* <Legend /> */}
                                                    {geneCancer?.map((entry, index) => (
                                                        <Bar key={index} dataKey={`cancerSiteCountList[${index}].cancerSiteCount`} stackId="a" fill={HospitalColor[index % HospitalColor.length]} barSize={65} />
                                                    ))}

                                                </BarChart>

                                            </ResponsiveContainer>
                                            :
                                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                        }

                                    </div>
                                </div>
                            </div>
                            {/*  */}

                            <div className='row'>
                                <div className="col-6 " style={{ 'height': 520, marginTop: 0, marginBottom: 0 }}>
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Gender-Age Cohort Analysis</h6>
                                            <div onClick={() => ageGenderModal()}>
                                                <img src={expand} className='expandImage' />
                                            </div>
                                        </div>
                                        {ageGender &&
                                            <ResponsiveContainer width="100%" height="100%">
                                                <BarChart
                                                    // ref={barRef}
                                                    // ref={AgeRef}
                                                    width={500}
                                                    height={300}
                                                    data={ageGender}
                                                    margin={{
                                                        top: 30,
                                                        right: 10,
                                                        left: 10,
                                                        bottom: 5,
                                                    }}
                                                >
                                                    {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                    <XAxis dataKey="ageGroup"
                                                        tick={<CustomizedAxisTick />}
                                                    />
                                                    <YAxis />
                                                    <Tooltip cursor={{ fill: '#f7f7f7' }} />
                                                    <Legend />
                                                    <Bar dataKey="Male" fill="#4169e1">
                                                        <LabelList dataKey="Male" content={topCustomizedLabel} />
                                                    </Bar>
                                                    <Bar dataKey="Female" fill="#ff1493">
                                                        <LabelList dataKey="Female" content={topCustomizedLabel} />
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>
                                        }

                                    </div>
                                </div>
                                {/*  */}
                                <div className="col-6 pl-0  top_cities">
                                    <div className="card h-100" >
                                        <div className='pie_charts msi'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Top 20 Cities with PD-L1 Data</h6>
                                                <div className="d-flex align-items-center">
                                                    {/* <button className="png-button" onClick={handlerDrugClick}><img src={download} className='download' /></button> */}
                                                    <div className="ml-3" onClick={() => citiesModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <h6 className='mb-0 py-1 totalcount' style={{ fontWeight: 'bold' }}>{top20CitiesTotal}</h6> */}
                                            {hospCity ?
                                                <ResponsiveContainer width="100%" height="100%"  >

                                                    <PieChart
                                                        // ref={drugRef}
                                                        width={300} height={500}
                                                    // margin={{
                                                    //     top: 25,
                                                    //     right: 30,
                                                    //     left: 20,
                                                    //     bottom: 5,
                                                    // }}
                                                    >
                                                        {/* <Legend verticalAlign="top" align="top" /> */}
                                                        <Pie
                                                            data={hospCity}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TDBCustomizedLabel}
                                                            outerRadius={'95%'}
                                                            innerRadius={'40%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {/* {hospCity.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                            ))} */}

                                                            {
                                                                hospCity.map((entry, index) => (
                                                                    <Cell onClick={() => hospitalLocation(entry)} key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                                ))
                                                            }
                                                        </Pie>
                                                        <Tooltip content={<Top20CitiesCustomTooltip />} />
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }

                                            {/* {drugTherapy ?
                                        <div id="chartdiv" style={{ width: '400px', height: "450px" }}></div>        
                                        :     
                                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} /> */}



                                            {/*          <HighchartsReact
                                                     highcharts={Highcharts}
                                                    // options={options}
                                                     options={options2}

                                                    // ref="chartComponent1"
                                />         */}
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='mt-3'>
                                <div className="col px-0 mb-3" style={{ 'height': 750, }}>
                                    <div className="card h-100">
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Hospitals ({hospName})</h6>
                                            <div onClick={() => hospitalModal()}>
                                                <img src={expand} className='expandImage' />
                                                {/* <div onClick={() => sethospitalShow(false)} style={{ marginLeft: 15 }}>
                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                            </div> */}
                                            </div>
                                        </div>
                                        <ResponsiveContainer width="100%" height={650}>
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={hospNameData}
                                                margin={{
                                                    top: 25,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                <XAxis dataKey="name"
                                                    // tick={{ height: 55 }}
                                                    // interval={0}
                                                    // angle={-25}
                                                    textAnchor="middle"
                                                    dx={-6}
                                                    height={100}
                                                    // tickLine={false}
                                                    tick={<CustomizedXAxisTick />}
                                                    interval={0}
                                                    tickLine={false}
                                                />
                                                <YAxis />
                                                <Tooltip cursor={{ fill: '#f7f7f7' }} />
                                                {/* <Legend /> */}
                                                <Bar
                                                    dataKey="value" stackId="a" fill="#f4908e" barSize={40}
                                                >
                                                    <LabelList dataKey="value" content={renderCustomizedLabel} />

                                                    {
                                                        hospNameData?.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                        ))
                                                    }
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>

                                    </div>
                                </div>
                            </div>



                            <div className="row px-3" >

                                <div className="col-lg-4 pl-0 mb-3 mt-sm-0 pr-lg-3 mt-lg-0 pr-0">
                                    <div className="card h-100" >
                                        <div className='pie_charts msi'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Tobacco Users Among Patients</h6>

                                                <div className="d-flex align-items-center">
                                                    {/* <button className="png-button" onClick={handlerDrugClick}><img src={download} className='download' /></button> */}

                                                </div>
                                            </div>
                                            <div className='simple-number'>
                                                <h2 className='mb-0'>{commafy(tobaccoCount)}</h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-3 px-0 mt-sm-3 mt-lg-0 pr-lg-3">

                                    <div className="card h-100" >
                                        <div className='pie_charts pdl1'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>PD-L1 tests with Multiple clones</h6>
                                                <div className="d-flex align-items-center">

                                                    {/* <button className="png-button" onClick={handlerBiologicalClick}><img src={download} className='download' /></button> */}
                                                    {/* <div className="ml-3" onClick={() => biologicalModal()}>
<img src={expand} className='expandImage' />
</div>         */}
                                                </div>
                                            </div>
                                            <div className='simple-number'>
                                                <h2 className='mb-0'>{commafy(pdlMultipleCloneVal)}</h2>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-3 pl-0 pr-0 mt-sm-3 mt-lg-0 pr-sm-0">
                                    <div className="card card_height" >
                                        <div className='pie_charts msi'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Patients with Family History</h6>

                                            </div>
                                            <div className='simple-number simple_flex'>
                                                <h2 className='mb-0 pt-4'>{commafy(familyHistoryVal)}</h2>
                                                <p className='mb-0  '>First Degree Kin:{firstdegree ? firstdegree : 0}</p>
                                            </div>
                                            {/* <h5>DeceasedCount</h5> */}
                                            {/* <div className='simple-number'>
                <h2 className='mb-0'>{commafy(deceasedVal)}</h2>
            </div> */}


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>


                {/* Tmb */}
                <Modal
                    isOpen={primarySite}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={primarycloseModal}
                    className="Modal scroll_hiding "
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>Primary Site Distribution</h6>
                            <div className='close_icon' onClick={() => primarycloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {cansiteval ?
                            <ResponsiveContainer width="100%" height={550}  >
                                <PieChart
                                    // ref={drugRef}
                                    width={300} height={400}>
                                    {/* <Legend verticalAlign="top" align="top" /> */}
                                    <Pie
                                       data={drugFilterVal !== '' || pdlFilterVal !== '' || pdlCloneFilterVal !== '' || (value)  ||biologicalFilterVal !== '' | primaryFilterVal !== '' ? cansiteval :cancerSiteCount}
                                     //   data={drugFilterVal !== '' || pdlFilterVal !== '' || pdlCloneFilterVal !== '' || filterVal !== '' ||biologicalFilterVal !== '' | primaryFilterVal !== '' ? cansiteval :cancerSiteCount}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={PrimarySiteCustomizedLabel}
                                        outerRadius={'75%'}
                                        innerRadius={'30%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {cansiteval.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={CanSiteColors.filter((item) => item.name === entry.name).length > 0 ? CanSiteColors.filter((item) => item.name === entry.name)[0].code : colrs[index % colrs.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<CancerSiteCustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>

                            :
                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                        }
                    </div>
                </Modal>



                <Modal
                    isOpen={histpathOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={histopathologyCloseModal}
                    className="Modal scroll_hiding "
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>Histopathology</h6>
                            <div className='close_icon' onClick={() => histopathologyCloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {MorphologyCount &&

                            <ResponsiveContainer width="100%" height={500}   >

                                <PieChart
                                    // ref={drugRef}
                                    width={300} height={400}>
                                    {/* <Legend verticalAlign="top" align="top" /> */}
                                    <Pie
                                        data={MorphologyCount}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={TDBCustomizedLabel}
                                        outerRadius={'90%'}
                                        innerRadius={'40%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {MorphologyCount.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.name).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<HistopathologyCustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>

                        }
                    </div>
                </Modal>


                <Modal
                    isOpen={tmbOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={tmbCloseModal}
                    className="Modal scroll_hiding "
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>Tmb</h6>
                            <div className='close_icon' onClick={() => tmbCloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {tmbValue ?
                            <ResponsiveContainer width="100%" height={500}  >
                                <PieChart

                                    // ref={drugRef}
                                    width={300} height={400}>
                                    <Legend verticalAlign="top" align="top" />
                                    <Pie
                                        data={tmbValue}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={PrimarySiteCustomizedLabel}
                                        outerRadius={'90%'}
                                        innerRadius={'40%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {tmbValue.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={ColorValues.filter((item) => item.name === entry.name).length > 0 ? ColorValues.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<TmbCustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>

                            :
                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                        }
                    </div>
                </Modal>
                <Modal
                    isOpen={msiOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={msiCloseModal}
                    className="Modal scroll_hiding "
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>Msi</h6>
                            <div className='close_icon' onClick={() => msiCloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {msiValue ?

                            <ResponsiveContainer width="100%" height={500}  >
                                <PieChart
                                    // ref={drugRef}
                                    width={300} height={400}>
                                    <Legend verticalAlign="top" align="top" />
                                    <Pie
                                        data={msiValue}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={PrimarySiteCustomizedLabel}
                                        outerRadius={'90%'}
                                        innerRadius={'40%'}
                                        fill="#8884d8"
                                        dataKey="value"

                                    >
                                        {msiValue.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={ColorValues.filter((item) => item.name === entry.name).length > 0 ? ColorValues.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<MsiCustomTooltip />} />
                                    <Legend verticalAlign="top" align="top" dataKey="0" />
                                </PieChart>
                            </ResponsiveContainer>

                            :
                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                        }
                    </div>
                </Modal>

                <Modal
                    isOpen={drugOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={drugcloseModal}
                    //   ariaHideApp={false}
                    // appElement={document.getElementById('chartdiv')}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>Drug Therapy</h6>
                            <div className='close_icon' onClick={() => drugcloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {drugTherapy ?
                            <ResponsiveContainer width="100%" height={550}  >

                                <PieChart
                                    //  ref={drugRef}
                                    width={300} height={400}>
                                    <Legend verticalAlign="top" align="top" />
                                    <Pie
                                        data={drugTherapy}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={TDBCustomizedLabel}
                                        outerRadius={'90%'}
                                        innerRadius={'40%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {drugTherapy.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={DrugColor.filter((item) => item.name === entry.name).length > 0 ? DrugColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<DrugCustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>
                            :
                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                        }

                        {/* <div id='chartdiv'></div>    */}

                    </div>
                </Modal>

                {/* PDL */}
                <Modal
                    isOpen={drugsCountOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={drugscountcloseModal}
                    //ariaHideApp={false}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>Immunotherapy drugs</h6>
                            <div className='close_icon' onClick={() => drugscountcloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {drugsCount ?
                            <ResponsiveContainer width="100%" height={550}>
                                <PieChart width={300} height={400}>
                                    <Legend verticalAlign="top" align="top" />
                                    <Pie
                                        //  ref={bioloRef}
                                        data={drugsCount}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={TDBCustomizedLabel}
                                        outerRadius={'90%'}
                                        innerRadius={'40%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >

                                        {drugsCount.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={DrugsCountColor.filter((item) => item.name === entry.name).length > 0 ? DrugsCountColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<DrugsCountCustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>
                            :
                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />

                        }

                    </div>
                </Modal>
                <Modal
                    isOpen={biologicalOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={biologicalcloseModal}
                    //ariaHideApp={false}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>Biological Therapy</h6>
                            <div className='close_icon' onClick={() => biologicalcloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {biologicalTherapy ?
                            <ResponsiveContainer width="100%" height={550}>
                                <PieChart width={300} height={400}>
                                    <Legend verticalAlign="top" align="top" />
                                    <Pie
                                        //  ref={bioloRef}
                                        data={biologicalTherapy}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={TDBCustomizedLabel}
                                        outerRadius={'90%'}
                                        innerRadius={'40%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >

                                        {biologicalTherapy.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={BiologicalColor.filter((item) => item.name === entry.name).length > 0 ? BiologicalColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<BiologicalCustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>
                            :
                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />

                        }

                    </div>
                </Modal>
                <Modal
                    isOpen={ageGenderOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={ageGenderCloseModal}
                    className="Modal scroll_hiding "
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>Gender-Age Cohort Analysis</h6>
                            <div className='close_icon' onClick={() => ageGenderCloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {ageGender &&
                            <ResponsiveContainer width="100%" height={500}>
                                <BarChart
                                    // ref={barRef}
                                    // ref={AgeRef}
                                    width={500}
                                    height={300}
                                    data={ageGender}
                                    margin={{
                                        top: 30,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                    <XAxis dataKey="ageGroup"
                                        tick={<CustomizedAxisTick />}
                                    />
                                    <YAxis />
                                    <Tooltip cursor={{ fill: '#f7f7f7' }} />
                                    <Legend />
                                    <Bar dataKey="Male" fill="#4169e1">
                                        <LabelList dataKey="Male" content={topCustomizedLabel} />
                                    </Bar>
                                    <Bar dataKey="Female" fill="#ff1493">
                                        <LabelList dataKey="Female" content={topCustomizedLabel} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        }

                    </div>
                </Modal>

                {/*Cities */}
                <Modal
                    isOpen={citiesOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={citiesCloseModal}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div>
                            <div className='grap-title Modal-top-cn'>
                                <h6 className='mb-0 py-1'>Top 20 Cities with PD-L1 Data</h6>
                                <div className='close_icon' onClick={() => citiesCloseModal()}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                </div>
                            </div>
                            {top20CitiesData ?
                                <ResponsiveContainer width="100%" height={540}  >

                                    <PieChart
                                        // ref={drugRef}
                                        width={300} height={500}>
                                        {/* <Legend verticalAlign="top" align="top" /> */}
                                        <Pie
                                            data={top20CitiesData}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={TDBCustomizedLabel}
                                            outerRadius={'90%'}
                                            innerRadius={'40%'}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {
                                                top20CitiesData.map((entry, index) => (
                                                    <Cell onClick={() => hospitalLocation(entry)} key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                ))
                                            }
                                        </Pie>
                                        <Tooltip content={<Top20CitiesCustomTooltip />} />
                                    </PieChart>
                                </ResponsiveContainer>
                                :
                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                            }

                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={bioShow}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={biomarCloseModal}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div>
                            <div className='grap-title Modal-top-cn'>
                                <h6 className='mb-0 py-1'>Stage Vs Primary Sites</h6>
                                <div className='close_icon' onClick={() => biomarCloseModal()}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                </div>
                            </div>
                            {cancerStage ?
                                <ResponsiveContainer width="100%" height={650}>
                                    <BarChart width={400} height={400} data={cancerStage} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis dataKey="biomarkerName" />
                                        <YAxis />
                                        <Tooltip content={<BBioCustomTooltip />} />
                                        {/* <Legend /> */}
                                        {cann?.map((entry, index) => (
                                            <Bar key={index} dataKey={`cancerSiteCountList[${index}].cancerSiteCount`} stackId="a" fill={HospitalColor[index % HospitalColor.length]} barSize={65} />
                                        ))}

                                    </BarChart>

                                </ResponsiveContainer>
                                :
                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                            }

                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={recist}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={recistCloseModal}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div>
                            <div className='grap-title Modal-top-cn'>
                                <h6 className='mb-0 py-1'>Treatment Response</h6>
                                <div className='close_icon' onClick={() => recistCloseModal()}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                </div>
                            </div>
                            {recistCriteria ?
                                <ResponsiveContainer width="100%" height={540}  >

                                    <PieChart
                                        // ref={drugRef}
                                        width={300} height={500}>
                                        <Legend verticalAlign="top" align="top" />
                                        <Pie
                                            data={recistCriteria}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={TDBCustomizedLabel}
                                            outerRadius={'90%'}
                                            innerRadius={'40%'}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {
                                                recistCriteria.map((entry, index) => (
                                                    <Cell onClick={() => hospitalLocation(entry)} key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                                ))
                                            }
                                        </Pie>
                                        <Tooltip content={<TreatmentResCustomTooltip />} />
                                    </PieChart>
                                </ResponsiveContainer>
                                :
                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                            }


                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={cancerShow}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={cancerstatCloseModal}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div>
                            <div className='grap-title Modal-top-cn'>
                                <h6 className='mb-0 py-1'>Top 20 Biomarkers</h6>
                                <div className='close_icon' onClick={() => cancerstatCloseModal()}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                </div>
                            </div>
                            {geneCancer ?
                                <ResponsiveContainer width="100%" height={650}>
                                    <BarChart width={400} height={400} data={geneCancer} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis dataKey="biomarkerName" />
                                        <YAxis />
                                        <Tooltip content={<BBioCustomTooltip />} />
                                        {/* <Legend /> */}
                                        {geneCancer[0]?.cancerSiteCountList.map((entry, index) => (
                                            <Bar key={index} dataKey={`cancerSiteCountList[${index}].cancerSiteCount`} stackId="a" fill={HospitalColor[index % HospitalColor.length]} barSize={65} />
                                        ))}

                                    </BarChart>

                                </ResponsiveContainer>
                                :
                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                            }

                        </div>
                    </div>
                </Modal>

                {/*hospital */}
                <Modal
                    isOpen={hospitalOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={hospitalCloseModal}
                    className="Modal"
                    overlayClassName="Overlay"
                >
                    <div>
                        <div>
                            <div className='grap-title Modal-top-cn'>
                                <h6 className='mb-0 py-1'>Hospitals ({hospName})</h6>
                                <div className='close_icon' onClick={() => hospitalCloseModal()}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                </div>
                            </div>
                            <ResponsiveContainer width="100%" height={600}>
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={hospNameData}
                                    margin={{
                                        top: 25,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                    <XAxis dataKey="name"
                                        // tick={{ height: 55 }}
                                        // interval={0}
                                        // angle={-25}
                                        textAnchor="middle"
                                        dx={-6}
                                        height={100}
                                        // tickLine={false}
                                        tick={<CustomizedXAxisTick />}
                                        interval={0}
                                        tickLine={false}
                                    />
                                    <YAxis />
                                    <Tooltip cursor={{ fill: '#f7f7f7' }} />
                                    {/* <Legend /> */}
                                    <Bar
                                        dataKey="value" stackId="a" fill="#f4908e" barSize={40}
                                    >
                                        <LabelList dataKey="value" content={renderCustomizedLabel} />

                                        {
                                            hospNameData?.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={HospitalColor[index % HospitalColor.length]} />
                                            ))
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={statusShow}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    className="Modal scroll_hiding "
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>PD-L1 Status</h6>
                            <div className='close_icon' onClick={() => closeModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {pdlStatusVal &&
                            <ResponsiveContainer width="100%" height={600}>
                                <PieChart width={300} height={400}>
                                    <Legend verticalAlign="top" align="top" />
                                    <Pie
                                        data={pdlStatusVal}
                                        cx="50%"
                                        cy="45%"
                                        labelLine={false}
                                        label={TMBMSICustomizedLabel}
                                        outerRadius={'70%'}
                                        innerRadius={'30%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {pdlStatusVal.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={ColorValues.filter((item) => item.name === entry.name).length > 0 ? ColorValues.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<PdlStatusCustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>
                        }
                    </div>
                </Modal>

                <Modal
                    isOpen={cloneShow}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={valueCloseModal}
                    className="Modal scroll_hiding "
                    overlayClassName="Overlay"
                >
                    <div>
                        <div className='grap-title'>
                            <h6 className='mb-0 py-1'>PD-L1 Clones</h6>
                            <div className='close_icon' onClick={() => valueCloseModal()}>
                                {/* <img src={expand} /> */}
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {pdlCloneVal &&
                            <ResponsiveContainer width="100%" height={600}>
                                <PieChart width={300} height={400}>
                                    <Legend verticalAlign="top" align="top" />
                                    <Pie
                                        data={pdlCloneVal}
                                        cx="50%"
                                        cy="45%"
                                        labelLine={false}
                                        label={TMBMSICustomizedLabel}
                                        outerRadius={'70%'}
                                        innerRadius={'30%'}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {pdlCloneVal.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.name).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<PdlCloneCustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>
                        }
                    </div>
                </Modal>
                {/*  */}
                <div className='row ml-0 text_cards mb-3'>

                    <div className='col-6 pl-0'>
                        <div className='card'>
                            <p><i className="zmdi zmdi-quote start"></i>Dataset encompassing Over {CancersiteValue} cancer sites for PD-L1 tests<i className="zmdi zmdi-quote end"></i></p>
                        </div>
                    </div>
                    <div className='col-6 pl-0'>
                        <div className='card'>
                            <p><i className="zmdi zmdi-quote start"></i>Aggregating data from {hospitalValue} cancer hospitals/centers, fostering a holistic understanding of Oncological trends<i className="zmdi zmdi-quote end"></i></p>
                        </div>
                    </div>
                    {/* <div className='col-4 pl-0'>
                        <div className='card'>
                            <p><i className="zmdi zmdi-quote start"></i>No. of tests and unique patients - Featuring 2290 patients and over 6000 tests to propel our oncology journey.<i className="zmdi zmdi-quote end"></i></p>
                        </div>
                    </div> */}
                </div>

            </div >

            <div className="footer_logo ">
                <div>
                    <p className='mb-0'>4baseCare Onco Solutions Pvt. Ltd. All rights reserved. 2024-2025.</p>
                </div>
                <div className='note_img mr-1'>
                    <img src={company_logo} />
                </div>

            </div>
        </div >
    )
}

export default PDL;