import React, { useCallback, useEffect, useRef, useState, useContext, useLayoutEffect } from 'react';
//import ToolTip from '@material-ui/core/Tooltip';
import "./style.css"
//import Parts from './Parts'
//import {Adipose,AdrenalGland,Anus,Appendix,BodyLayout,Brain,Breast,CirculatorySystem,Colon,Duodenum,Esophagus,Eye,Femur,GalBladder,Heart,Kidney,Liver,Lung,Nasopharynx,OralCavity,Pancreas,Prostate,Rectum,SalivaryGland,SkeletalMuscle,Skin,SmallIntestine,Stomach,Testis,Thyroid,UrinaryBladder} from './Parts'
import ReactDOM from "react-dom";
import { useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";
import Modal from 'react-modal';
import sunrisePic from './../../../assets/images/GoodMorning.svg';
import expand from './../../../assets/images/expand.svg';
import company_logo from './../../../assets/images/4bc.svg';
import download from './../../../assets/images/download.png';
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Sector, Bar, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Label, LabelList } from "recharts";
// import "./style.css";
//import { useCurrentPng } from "recharts-to-png";
import FileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
//import domToImage from "dom-to-image";
import fileDownload from "js-file-download";
import html2canvas from 'html2canvas';

import {
    FilterContext, EventTypeContext,
    AgeGroupContext, GenderTypeContext, HospitalNameContext
} from '../../../Context/userContext'
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
//import { scaleQuantile } from 'd3-scale';
import { geoCentroid } from "d3-geo";
import { CancerSiteColors, HospitalColor, COLORS } from '../../../utils/constants'
import ReactTooltip from 'react-tooltip';
import { hover } from '@testing-library/user-event/dist/hover';
//import * as am5 from "@amcharts/amcharts5";
//import * as am5xy from "@amcharts/amcharts5/xy";
//import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
//import * as am5percent from "@amcharts/amcharts5/percent";
//import Highcharts from "highcharts/highstock";
//import variablePie from "highcharts/modules/variable-pie.js";
//import HighchartsReact from "./HighchartsReact.js";
//import HighchartsReact from "highcharts-react-official";
//import { useRechartToPng,UseCurrentPng } from "recharts-to-png";
//import Highcharts from 'highcharts';
//import HighchartsReact from 'highcharts-react-official';
//variablePie(Highcharts);
// import LinearGradient from './LinearGradient.js';
// import './App.css';


const INDIA_TOPO_JSON = require('./india.topo.json');

const PROJECTION_CONFIG = {
    scale: 350,
    center: [78.9629, 22.5937] // always in [East Latitude, North Longitude]
};


// will generate random heatmap data on every call
const getHeatMapData = () => {
    return [
        { id: 'AP', state: 'Andhra Pradesh', value: 23 },
        { id: 'AR', state: 'Arunachal Pradesh', value: 43 },
        { id: 'AS', state: 'Assam', value: 32 },
        { id: 'BR', state: 'Bihar', value: 56 },
        { id: 'CT', state: 'Chhattisgarh', value: 64 },
        { id: 'GA', state: 'Goa', value: 21 },
        { id: 'GJ', state: 'Gujarat', value: 22 },
        { id: 'HR', state: 'Haryana', value: 34 },
        { id: 'HP', state: 'Himachal Pradesh', value: 24 },
        { id: 'JH', state: 'Jharkhand', value: 26 },
        { id: 'KA', state: 'Karnataka', value: 27 },
        { id: 'KL', state: 'Kerala', value: 26 },
        { id: 'MP', state: 'Madhya Pradesh', value: 54 },
        { id: 'MH', state: 'Maharashtra', value: 27 },
        { id: 'MN', state: 'Manipur', value: 33 },
        { id: 'ML', state: 'Meghalaya', value: 59 },
        { id: 'MZ', state: 'Mizoram', value: 42 },
        { id: 'NL', state: 'Nagaland', value: 59 },
        { id: 'OR', state: 'Odisha', value: 59 },
        { id: 'PB', state: 'Punjab', value: 23 },
        { id: 'RJ', state: 'Rajasthan', value: 43 },
        { id: 'SK', state: 'Sikkim', value: 45 },
        { id: 'TN', state: 'Tamil Nadu', value: 53 },
        { id: 'TG', state: 'Telangana', value: 23 },
        { id: 'TR', state: 'Tripura', value: 14 },
        { id: 'UT', state: 'Uttarakhand', value: 42 },
        { id: 'UP', state: 'Uttar Pradesh', value: 15 },
        { id: 'WB', state: 'West Bengal', value: 17 },
        { id: 'WB', state: 'West Bengal', value: 17 },
        { id: 'AN', state: 'Andaman and Nicobar Islands', value: 22 },
        { id: 'CH', state: 'Chandigarh', value: 12 },
        { id: 'DN', state: 'Dadra and Nagar Haveli', value: 0 },
        { id: 'DD', state: 'Daman and Diu', value: 0 },
        { id: 'DL', state: 'Delhi', value: 59 },
        { id: 'JK', state: 'Jammu and Kashmir', value: 25 },
        { id: 'LA', state: 'Ladakh', value: 23 },
        { id: 'LD', state: 'Lakshadweep', value: 0 },
        { id: 'PY', state: 'Puducherry', value: 0 }
    ];
};
const Trdata = [
    { name: 'Drug Therapy', value: 400 },
    { name: 'Sergical Therapy', value: 300 },
    { name: 'Radio Therapy', value: 300 },
]

//Modal.setAppElement('#root');
//Modal.setAppElement('body');
const Dashboard = (props) => {
    const { allGraphData, userDetails, indiaMapData, psiteCount,
        filterGraphs, AllGraphsList, filterVal, totalCount, growthOfSimple, humanBodyData,
        genderCancerSite, genderAgeGroup, tmbData, msiData, pdlData, treatmentDetails, drugTherapy, biologicalTherapy, southCount, canSiteList,
        EastCount, NorthCount, WestCount, top20BoiMarkerKeys, top20BoiMarkerList, top20Loader,
        cancerSiteTop10Data, cancerSiteRestOfData, top10totalCount, primarySiteCount,
        CommonBiomarkersKeys, CommonBiomarkersList,IsLoading } = props


    const [genderData, setGenderData] = useState([{
        'cancerSiteName': 'Male',
        'color': '#E0BDD0',
        'active': false
    },
    {
        'cancerSiteName': 'Female',
        'color': '#945D9C',
        'active': false
    },
    ])

    const [modalIsOpen, setIsOpen] = useState(false);
    const [eventTypeOpen, setEventTypeOpen] = useState(false);
    const [bioMarkerOpen, setbioMarkerOpen] = useState(false);
    const [gendervdAgeOpen, setGenderVsAge] = useState(false);
    const [gendervdCancerOpen, setGenderVsCancer] = useState(false);
    const [tmbOpen, setTmbOpen] = useState(false);
    const [msiOpen, setMsiOpen] = useState(false);
    const [pdlOpen, setPdlOpen] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipContentModel, setTooltipContentModel] = useState('');
    const [data, setData] = useState(getHeatMapData());
    const [top20bioMar, setTop20BioMar] = useState(null)
    const [treatmentopen, setTreatmentOpen] = useState(false);
    const [drugOpen, setDrugOpen] = useState(false);
    const [biologicalOpen, setBiologicalOpen] = useState(false);
    const [tooltipData, setTooltipData] = useState('');
    const [color, setColor] = useState('');
    const [isShow, setShow] = useState(false);
    const [activeInd, setActiveInd] = useState(-1)
    const [activePart, setActivePart] = useState(null);
    const [hov, setHov] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
    const [tooltipActive, setTooltipActive] = useState(false)
    const ids = ["anjali@4basecare.com", "kshitij@user", "oncoviz-analyst@mailinator.com"];
    //const ids = ["anjali@4basecare.com","kshitij@user"];
    const value = ids?.includes(userDetails?.email)

    const handleMouseOver = (e) => {
        setTooltipActive(true)
    }

    const handleMouseOut = (e) => {
        setTooltipActive(false)
    }

    const onMouseMove = (e) => {
        // const tooltipHeight = -30 // you need to change this based on your tooltip
        //  setTooltipPosition({ x: tooltipHeight })
        //  setTooltipPosition({ x: e.x, y: e.y - tooltipHeight })
        setTooltipActive(true)
    }

    const onMouseOut = (e) => {
        //   setTooltipPosition({ x: -9999, y: -9999 }) // hide the tooltip
        setTooltipActive(false)
    }

    const navigate = useNavigate();


    const exportRef = useRef();
    const exportAsImage = async (element, imageFileName) => {
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
    };
    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none";
        fakeLink.download = fileName;

        fakeLink.href = blob;

        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);

        fakeLink.remove();
    };

    // const chartRef = useRef(null);

    // const handleExport = () => {
    //     const chart = Highcharts.charts[0];
    //     if (chart) {
    //         const svg = chart.getSVGForExport({
    //             type: "image/png",
    //             sourceWidth: chart.chartWidth,
    //             sourceHeight: chart.chartHeight,
    //         });
    //         domToImage.toBlob(chartRef.current).then((blob) => {
    //             FileSaver.saveAs(blob, "chart.png");
    //         });
    //     }
    // };
    //    const [getAreaPng, { ref: barRef }] = useCurrentPng();
    //     const handleAreaDownload = useCallback(async () => {
    //       const png = await getAreaPng();
    //       if (png) {
    //         FileSaver.saveAs(png, "bar-chart.png");
    //       }
    //     }, [getAreaPng]);
    const cansiteRef = useRef(null)
    const handleCanSiteDistClick = () => {
        const chart = cansiteRef.current
        let chartSVG = ReactDOM.findDOMNode(chart).children[0];
        //  const element = ReactDOM.findDOMNode(referenceToSvgChart.current).parentNode;

        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });

        saveAs(svgBlob, "cancersite dist.svg");

    }
    const restofcanRef = useRef(null)
    const handleRestOfCanClick = () => {
        const chart = restofcanRef.current
        let chartSVG = ReactDOM.findDOMNode(chart).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "restofcan.svg");
    }

    const tmpRef = useRef(null);
    const handleClick = () => {
        const chart = tmpRef.current
        let chartSVG = ReactDOM.findDOMNode(chart).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "tmb.svg");
    }

    const smiRef = useRef(null);
    const handlerSaveClick = () => {
        const chart1 = smiRef.current;
        let chartSVG = ReactDOM.findDOMNode(chart1).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "msi.svg");
    }



    const dlpRef = useRef(null);
    const handleDownClicker = () => {
        const chartRef = dlpRef.current
        let chartSVG = ReactDOM.findDOMNode(chartRef).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "pdl.svg");
    }


    const cancerRef = useRef(null)
    const handleCancerClick = () => {
        const chart = cancerRef.current
        let chartSVG = ReactDOM.findDOMNode(chart).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "gendvscan.svg");
    }

    const AgeRef = useRef(null);
    const handleAgeClicker = () => {
        const chartRef = AgeRef.current
        let chartSVG = ReactDOM.findDOMNode(chartRef).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "gendvsage.svg");
    }

    const biocanRef = useRef(null);
    const handleBioMarClicker = () => {
        const chartRef = biocanRef.current
        let chartSVG = ReactDOM.findDOMNode(chartRef).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "biomar.svg");
    }

    const prevcomRef = useRef(null);
    const handlePrevComClicker = () => {
        const chartRef = prevcomRef.current
        let chartSVG = ReactDOM.findDOMNode(chartRef).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "commonbio.svg");
    }

    const trtRef = useRef(null);
    const handleTreatmentClicker = () => {
        const chartRef = trtRef.current
        let chartSVG = ReactDOM.findDOMNode(chartRef).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "treatment.svg");
    }

    const drugRef = useRef(null);
    const handlerDrugClick = () => {
        const chart1 = drugRef.current;
        let chartSVG = ReactDOM.findDOMNode(chart1).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "drug.svg");
    }
    const bioloRef = useRef(null);
    const handlerBiologicalClick = () => {
        const chart1 = bioloRef.current;
        let chartSVG = ReactDOM.findDOMNode(chart1).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "restofcan.svg");
    }
    const humanRef = useRef(null)
    const handlerHumanClick = () => {
        const chart1 = humanRef.current;
        let chartSVG = ReactDOM.findDOMNode(chart1).children[0];
        const svgURL = new XMLSerializer().serializeToString(chartSVG);
        const svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
        saveAs(svgBlob, "human.svg");
    }

    // const bioloRef = useRef(null);
    // const handlerBiologicalClick = () => {
    //     const chart1 = bioloRef.current;
    //     let chartSVG = ReactDOM.findDOMNode(chart1).children[0];
    //     const width = chartSVG.clientWidth;
    //     const height = chartSVG.clientHeight;
    //     let svgURL = new XMLSerializer().serializeToString(chartSVG);
    //     let svgBlob = new Blob([svgURL], { type: "image/svg+xml;charset=utf-8" });
    //     let URL = window.URL || window.webkitURL || window;
    //     let blobURL = URL.createObjectURL(svgBlob);

    //     let image = new Image();
    //     image.onload = () => {
    //         let canvas = document.createElement('canvas');
    //         canvas.width = width;
    //         canvas.height = height;
    //         let context = canvas.getContext('2d');
    //         context.fillStyle = "#ffffff";
    //         context.fillRect(0, 0, width, height);
    //         context.drawImage(image, 0, 0, context.canvas.width, context.canvas.height);
    //         let png = canvas.toDataURL('dlp/png', 1.0);
    //         FileSaver.saveAs(png, "biological.png");
    //     };

    //     image.src = blobURL;

    // };

    // const graphRef = useRef(null);
    // const handleAreaDownloadChart = () => {
    //     const chart1 = graphRef.current;
    //     let chartSVG = ReactDOM.findDOMNode(chart1).children[0];
    //   //  const element = ReactDOM.findDOMNode(referenceToSvgChart.current).parentNode;

    //     const svgURL = new XMLSerializer().serializeToString(chartSVG);
    //     const svgBlob = new Blob([svgURL], {type: "image/svg+xml;charset=utf-8"});

    //     saveAs(svgBlob,"Hello.svg");

    //   }

    const svgToPng = (svg, width, height) => {
        return new Promise((resolve, reject) => {
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");

            // Set background to white
            ctx.fillStyle = "#ffffff";
            ctx.fillRect(0, 0, width, height);

            let xml = new XMLSerializer().serializeToString(svg);
            let dataUrl = "data:image/svg+xml;utf8," + encodeURIComponent(xml);
            let img = new Image(width, height);

            img.onload = () => {
                ctx.drawImage(img, 0, 0);
                let imageData = canvas.toDataURL("image/png", 1.0);

                resolve(imageData);
            };

            img.onerror = () => reject();

            img.src = dataUrl;
        });
    };

    //  {show &&
    const ref = useRef(null)
    const handleDownload = async () => {
        const chart = ref.current;
        let chartSVG = ReactDOM.findDOMNode(chart).children[0];

        const pngData = await svgToPng(chartSVG, 700, 500);
        FileSaver.saveAs(pngData, "tmb.png");
        return ref.current;
        // ref.current.reset();
        // }
    };

    const msref = useRef(null)
    const handleDownloader = async () => {
        //  msref.current = "";
        const chart1 = msref.current;
        let chartSVG1 = ReactDOM.findDOMNode(chart1).children[0];

        const msData = await svgToPng(chartSVG1, 600, 300);
        FileSaver.saveAs(msData, "msi.png");
        return msref.current;
        // msref.current.reset();
    };


    const pdref = useRef(null)
    const handleDownloading = async () => {
        const chart2 = pdref.current;
        let chartSVG2 = ReactDOM.findDOMNode(chart2).children[0];

        const pdData = await svgToPng(chartSVG2, 600, 300);
        FileSaver.saveAs(pdData, "pdl.png");
        return pdref.current;
    };


    //     useLayoutEffect(() => {
    //         var root = am5.Root.new("chartdivision");
    //         root._logo.dispose();
    //     root.setThemes([
    //       am5themes_Animated.new(root)
    //     ]);

    //     //  new PercentFormatter(new DecimalFormat("###,###,##0"))
    //   //  root.PercentFormatter.set("DecimalFormat", "###,###.##0");
    //     // root.defaultTheme.rule("ColorSet").set("colors", [

    //     //     am5.color(0x095256),
    //     //     am5.color(0x087f8c),
    //     //     am5.color(0x5aaa95),
    //     //     am5.color(0x86a873),
    //     //     am5.color(0xbb9f06)
    //     //   ]);

    //     var chart = root.container.children.push(am5percent.PieChart.new(root, {
    //       layout: root.verticalLayout
    //     }));
    //     chart.set("colors", [
    //             am5.color('#EB455F'),
    //             am5.color('#BAD7E9'),
    //             am5.color('#323D7B'),
    //             am5.color(0x86a873),
    //             am5.color(0xbb9f06)
    //           ]);

    //     var series = chart.series.push(am5percent.PieSeries.new(root, {
    //       alignLabels: false,
    //       calculateAggregates: false,
    //      valueField: "value",
    //      categoryField: "name",
    //    // legendLabelText: "[{fill}]{category}[/]",
    //    // legendLabelText: "{category}",
    //    // legendValueText:""
    //     legendValueText: "[bold]{valuePercentTotal.formatNumber('0.')}%",
    //      legendText:"{category}: [bold]{valuePercentTotal.formatNumber('0.')}%",
    //     }));
    //    // series.calculatePercent =true;
    //    // series.labels.template.textTemplate = "{value.percent.formatNumber('#')}";

    //     series.slices.template.setAll({
    //       strokeWidth: 3,
    //       stroke: am5.color(0xffffff),
    //     });
    //     // series.set("fill", am5.color('#EB455F')); // set Series color to red   
    //     // series.set("fill", am5.color('#BAD7E9'));
    //     series.get("colors").set("colors", [
    //         am5.color('#EB455F'),
    //         am5.color('#BAD7E9'),
    //         am5.color('#323D7B'),
    //         // am5.color(0x095256),
    //         // am5.color(0x087f8c),
    //         // am5.color(0x5aaa95),
    //         // am5.color(0x86a873),
    //         // am5.color(0xbb9f06)
    //       ]);


    //   //  series.labelsContainer.set("paddingTop", 30)

    //     series.slices.template.adapters.add("radius", function (radius, target) {
    //       var dataItem = target.dataItem;
    //       var high = series.getPrivate("valueHigh");

    //       if (dataItem) {
    //         var value = target.dataItem.get("valueWorking", 0);
    //         return radius * value / high
    //       }
    //       return radius;
    //     });
    //     //  var data =[{
    //     //  arr:    treatmentDetails.map(obj => ({ name: obj.name, value: obj.value })),
    //     //  }]
    // //    let data=[]


    // //    data.push(arr)
    // //    console.log("ttt",data);
    //    //var data = treatmentDetails.map(item=>(item.name item.value))   
    // //    series.data.setAll([{

    //     //     value: 1942,
    //     //     name: "Surgical therapy"
    //     //   }, {
    //     //     value: 608,
    //     //     name: "Radio therapy"
    //     //   }, {
    //     //     value: 2591,
    //     //     name: "Drug therapy"
    //     //   }]);
    //     var  data= treatmentDetails?.map(obj => ({ name: obj.name , value: obj.value })) 
    //     console.log("ttt",data); 
    //     var legend = chart.children.push(am5.Legend.new(root, {
    //       centerX: am5.p50,
    //       x: am5.p50,
    //       marginTop: 15,
    //       marginBottom: 15
    //     }));
    //     series.data.setAll(data);
    //    //series.data.setAll(treatmentDetails?.map(obj => ({ name: obj.name, value: obj.value })))
    //      legend.data.setAll(series.dataItems);
    //      series.labels.template.set("forceHidden", true);
    //      series.ticks.template.set("visible", false);
    //    // series.slices.template.tooltipText = "{category}: {value}";
    //       series.slices.template.setAll({
    //       tooltipText:"{category}: [bold]{valuePercentTotal.formatNumber('0.')}%",
    //        })
    //   //  series.labels.template.setAll({
    //    //    text:"{category}: [bold]{valuePercentTotal.formatNumber('0.')}%",
    //      //   text:"{category}: [bold]{valuePercentTotal.formatNumber('0.0')}%[/] ({value})",
    //       //  root.numberFormatter.set("numberFormat", "#,###.00");
    //       //  text: "{name}",
    //     //    radius:30,
    //     //    inside:true
    //      //   maxWidth: 150,
    //     //    oversizedBehavior: "wrap" 
    //   //  });
    //  //series.labels.template.set("text", "{category}: [bold]{valuePercentTotal.formatNumber('0')}%[/] ({value})");
    //     series.appear(1000, 100);
    //     return () => {
    //         root.dispose();
    //       };
    //     });


    //     useLayoutEffect(() => {
    //         var root = am5.Root.new("chartdiv");
    //         root._logo.dispose();

    //     root.setThemes([
    //       am5themes_Animated.new(root)
    //     ]);

    //     var chart = root.container.children.push(am5percent.PieChart.new(root, {
    //       layout: root.verticalLayout
    //     }));

    //     var series = chart.series.push(am5percent.PieSeries.new(root, {
    //       alignLabels: false,
    //       calculateAggregates: false,
    //      valueField: "value",
    //      categoryField: "name",
    //   //   legendLabelText: "{category}",
    //   //   legendValueText:""
    //       legendValueText: "[bold]{valuePercentTotal.formatNumber('0.')}%",
    //        legendText:"{category}: [bold]{valuePercentTotal.formatNumber('0.')}%",
    //     }));

    //     series.slices.template.setAll({
    //       strokeWidth: 3,
    //       stroke: am5.color(0xffffff)
    //     });
    //     series.get("colors").set("colors", [
    //         am5.color('#EB455F'),
    //         am5.color('#BAD7E9'),
    //         am5.color('#323D7B'),

    //       ]);



    //   //  series.labelsContainer.set("paddingTop", 30)

    //     series.slices.template.adapters.add("radius", function (radius, target) {
    //       var dataItem = target.dataItem;
    //       var high = series.getPrivate("valueHigh");

    //       if (dataItem) {
    //         var value = target.dataItem.get("valueWorking", 0);
    //         return radius * value / high
    //       }
    //       return radius;
    //     });
    //     //  var data =[{
    //     //      treatmentDetails.map(obj => ({ name: obj.name, y: obj.value, z: obj.z })),
    //     //  }]
    //    var  data= drugTherapy.map(obj => ({ name: obj.name, value: obj.value }))  
    //    console.log("dru",data);
    //    //var data = treatmentDetails.map(item=>(item.name item.value))   

    //     var legend = chart.children.push(am5.Legend.new(root, {
    //       centerX: am5.p50,
    //       x: am5.p50,
    //       marginTop: 15,
    //       marginBottom: 15
    //     }));
    //     series.data.setAll(data);
    //     legend.data.setAll(series.dataItems);
    //     series.labels.template.set("forceHidden", true);
    //     series.ticks.template.set("visible", false);
    //    series.slices.template.setAll({
    //             tooltipText:"{category}: [bold]{valuePercentTotal.formatNumber('0.')}%",
    //            })
    //   // series.labels.template.setAll({
    //  //    text:"{category}: [bold]{valuePercentTotal.formatNumber('0.')}%",
    //      //   text: "{name}",
    //      //   radius:30,
    //     //    inside:true
    //      //   maxWidth: 150,
    //     //    oversizedBehavior: "wrap" 
    //  //     });

    //     series.appear(1000, 100);
    //     return () => {
    //         root.dispose();
    //       };
    //     });


    //     useLayoutEffect(() => {
    //         var root = am5.Root.new("biologicaldiv");
    //         root._logo.dispose();

    //     root.setThemes([
    //       am5themes_Animated.new(root)
    //     ]);

    //     var chart = root.container.children.push(am5percent.PieChart.new(root, {
    //       layout: root.verticalLayout
    //     }));

    //     var series = chart.series.push(am5percent.PieSeries.new(root, {
    //       alignLabels: false,
    //       calculateAggregates: false,
    //      valueField: "value",
    //      categoryField: "name",
    //    //  legendLabelText: "{category}",
    //    //   legendValueText:""
    //      legendValueText: "[bold]{valuePercentTotal.formatNumber('0.')}%",
    //      legendText:"{category}: [bold]{valuePercentTotal.formatNumber('0.')}%",
    //     }));

    //     series.slices.template.setAll({
    //       strokeWidth: 3,
    //       stroke: am5.color(0xffffff)
    //     });
    //     series.get("colors").set("colors", [
    //         am5.color('#EB455F'),
    //         am5.color('#BAD7E9'),
    //         am5.color('#323D7B'),


    //       ]);


    //   //  series.labelsContainer.set("paddingTop", 30)

    //     series.slices.template.adapters.add("radius", function (radius, target) {
    //       var dataItem = target.dataItem;
    //       var high = series.getPrivate("valueHigh");

    //       if (dataItem) {
    //         var value = target.dataItem.get("valueWorking", 0);
    //         return radius * value / high
    //       }
    //       return radius;
    //     });
    //     //  var data =[{
    //     //      treatmentDetails.map(obj => ({ name: obj.name, y: obj.value, z: obj.z })),
    //     //  }]
    //    var  data= biologicalTherapy.map(obj => ({ name: obj.name, value: obj.value }))  
    //    console.log("bbb",data);

    //     var legend = chart.children.push(am5.Legend.new(root, {
    //       centerX: am5.p50,
    //       x: am5.p50,
    //       marginTop: 15,
    //       marginBottom: 15
    //     }));
    //     series.data.setAll(data);
    //     legend.data.setAll(series.dataItems);
    //     series.labels.template.set("forceHidden", true);
    //     series.ticks.template.set("visible", false);
    //     series.slices.template.setAll({
    //         tooltipText:"{category}: [bold]{valuePercentTotal.formatNumber('0.')}%",
    //        })
    //    //    series.labels.template.setAll({
    //    //       text:"{category}: [bold]{valuePercentTotal.formatNumber('0.')}%",
    //      //   text: "{name}",
    //   //      radius:30,
    //   //      inside:true
    //      //   maxWidth: 150,
    //     //    oversizedBehavior: "wrap" 
    //   //    });

    //     series.appear(1000, 100);
    //     return () => {
    //         root.dispose();
    //       };
    //     });

    // var colors = Highcharts.getOptions().colors,
    //     categories = [
    //         'Biological',
    //         'Hormonal',
    //         'Chemo'
    //     ],
    //     data1 = [
    //         {
    //             y: 61.04,
    //             color: colors[2],
    //             drilldown: {
    //                 name: 'Biological',
    //                 categories: [
    //                     'Targeted',
    //                     'Immuno'
    //                 ],
    //                 data: [
    //                     36.89,
    //                     18.16,

    //                 ]
    //             }
    //         },
    //         {
    //             y: 9.47,
    //             color: colors[3],
    //             drilldown: {
    //                 name: 'Hormonal',
    //                 categories: [
    //                     'Hormonal'
    //                 ],
    //                 data: [
    //                     //   0
    //                     8.47,
    //                     //  2.29,
    //                 ]
    //             },
    //         },
    //         {
    //             y: 9.32,
    //             color: colors[5],
    //             drilldown: {
    //                 name: 'Chemo',
    //                 categories: [
    //                     'Chemo'
    //                 ],
    //                 data: [
    //                     //     0
    //                     8.32
    //                 ]
    //             }
    //         },

    //     ],
    //     value1 = [],
    //     value2 = [],
    //     i,
    //     j,
    //     dataLen = data1.length,
    //     drillDataLen,
    //     brightness;


    // // Build the data arrays
    // for (i = 0; i < dataLen; i += 1) {

    //     // add browser data
    //     value1.push({
    //         name: categories[i],
    //         y: data1[i].y,
    //         color: data1[i].color
    //     });

    //     // add version data
    //     drillDataLen = data1[i].drilldown.data.length;
    //     for (j = 0; j < drillDataLen; j += 1) {
    //         brightness = 0.2 - (j / drillDataLen) / 5;
    //         value2.push({
    //             name: data1[i].drilldown.categories[j],
    //             y: data1[i].drilldown.data[j],
    //             color: Highcharts.color(data1[i].color).brighten(brightness).get()
    //         });
    //     }
    // }

    // // Create the chart
    // const options = {
    //     chart: {
    //         type: 'pie'
    //     },
    //     title: {
    //         text: ''
    //     },
    //     plotOptions: {
    //         pie: {
    //             shadow: false,
    //             center: ['50%', '50%']
    //         }
    //     },
    //     tooltip: {
    //         valueSuffix: '%'
    //     },
    //     series: [{
    //         name: 'value1',
    //         data: value1,
    //         size: '60%',
    //         dataLabels: {
    //             color: '#ffffff',
    //             distance: -30
    //         }
    //     }, {
    //         name: 'value2',
    //         data: value2,
    //         size: '90%',
    //         innerSize: '60%',
    //         dataLabels: {
    //             format: '<b>{point.name}:</b> <span style="opacity: 0.5">{y}%</span>',
    //             filter: {
    //                 property: 'y',
    //                 operator: '>',
    //                 value: 0
    //             },
    //             style: {
    //                 fontWeight: 'normal'
    //             }
    //         },
    //         id: 'values'
    //     }],
    //     responsive: {
    //         rules: [{
    //             condition: {
    //                 maxWidth: 600
    //             },
    //             chartOptions: {
    //                 series: [{
    //                 }, {
    //                     id: 'values',
    //                     dataLabels: {
    //                         enabled: false
    //                     }
    //                 }]
    //             }
    //         }]
    //     }
    // };


    const handleSaveClick = () => {
        domtoimage.toBlob(document.getElementById('node-to-convert'))
            .then(function (blob) {
                fileDownload(blob, 'dom-to-image.png');
            });
    }

    // const Tmb = {
    //     // Data retrieved from https://worldpopulationreview.com/country-rankings/countries-by-density
    //     //Highcharts.chart('container', {
    //     chart: {
    //         type: 'variablepie'
    //     },
    //     title: {
    //         text: '',
    //         //   align: 'left'
    //     },
    //     credits: {
    //         enabled: false
    //     },
    //     //   tooltip: {
    //     tooltip: {
    //         backgroundColor: 'null',
    //         //color:"white",
    //         borderWidth: 0,
    //         borderRadius: 2,
    //         padding: 2,




    //         //  'border-bottom': 1px solid black,
    //         shadow: false,
    //         useHTML: true,
    //         formatter: function () {
    //             return '<div style="background-color:' + this.point.color + '">' + this.point.name + ': ' + this.y + '<br/>' + this.point.z + '</div>';
    //         },
    //         style: {
    //             color: "white",
    //             //  symbolRadius: 4,
    //         }
    //     },
    //       formatter: function() {
    //            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + this.y + '</b><br/>';
    //         },

    //        backgroundColor: '#FCFFC5',

    //   backgroundColor:'{point.color}',           
    //     headerFormat: '',
    //        pointFormat:'<span style="color:{point.color}";"background-color:{point.color}">\u25CF</span><b>{point.name}</b><br/>' +
    //           '<b>{point.y}({point.percentage:.1f} %)</b><br/>'+ 
    //        '<b>{point.z}</b><br/>' , 
    //  tmbData.map(item=>'<b>{item.value}</b><br/>'+ 
    //   '<b>{item.z}</b><br/>')    
    //  'Area (square km): <b>{point.y}</b><br/>' +
    //  '<b>{point.z}</b><br/>' 

    //   },
    //   plotOptions: {
    //       series: {
    //           dataLabels: {
    //               enabled: true,
    //  'labelFormatter':function () { 
    //       var total = 0, percentage; 
    //          $.each(this.series.data, function() { 
    //            total+=this.y; 
    //         }); 

    //           percentage=((this.y/total)*100).toFixed(1); 
    //            return this.name+' '+percentage+'%'; 
    //          },
    //       format:'<b>{point.percentage:.1f} %</b>',
    //     formatter: function() {
    //          return Math.round(this.percentage*100)/100 + ' %';
    //      },
    //    distance: -75,
    //      color:'black',
    //    position:'vertical',
    //   rotate:'-45'
    //       },
    //        showInLegend: true
    //          }
    //       },

    //     plotOptions: {
    //         variablepie: {
    //             allowPointSelect: true,
    //             cursor: 'pointer',
    //             dataLabels: {
    //                 enabled: true,
    //                 format: '<b>{point.percentage:.1f} %</b>',
    //                 //       rotation: function () {
    //                 //           var chart = this.chart,
    //                 //                 angle = chart.options.chart.startAngle;
    //                 //              return angle;
    //                 //          },
    //                 style: {
    //                     //     fontWeight: 'bold',
    //                     //     color: 'white'
    //                 }
    //             },
    //             showInLegend: true
    //         },

    //     },
    //     legend: {
    //         symbolWidth: 16,
    //         symbolRadius: 4,
    //         //  rectangleSymbol:true
    //         squareSymbol: false
    //     },
    //     series: [{
    //         minPointSize: 40,
    //         innerSize: '20%',
    //         //   nameToX: true,
    //         zMin: 0,
    //         //name: 'countries',
    //         borderRadius: 5,
    //         sizeBy: 'radius',
    //         data: tmbData.map(obj => ({ name: obj.name, y: obj.value, z: obj.z })),
    //         //  data:  chartOptions.push(...tmbData.map(obj => console.log(obj))),
    //         //  data:tmbData.map((obj)=>(obj)),
    //         //   data:tmbData.map(({name,value,z})=>[({name,value,z})]),
    //         //    data:tmbData?.map((name,value,z)=>console.log(name&&value&&z)),
    //         //   data:tmbData.map((name,value,z)=>console.log([])),
    //         //    data:tmbData?tmbData.map(item =>(item.name && item.value && item.z)):tmbData,
    //         //  data:tmbData.map(item =>[(item.name && item.value && item.z)]),
    //         // data:tmbData.map(item=>(item.name)),
    //         //  data:tmbData.map(item =>(item.name&& item.value && item.z)),

    //         //  data: tmbData.map(item =>((item.name) && (item.value) && (item.z))),
    //         colors: [
    //             //  '#4caefe',
    //             //  '#3dc3e8',
    //             //  '#2dd9db',
    //             '#EB455F',
    //             '#BAD7E9',
    //             '#323D7B',

    //             // '#1feeaf',
    //             //  '#0ff3a0',
    //             //  '#00e887',
    //             //  '#23e274'
    //         ]

    //     }],
    // }

    //  console.log("datatmb",tmbData)

    // const options1 = {
    //     chart: {
    //         type: 'variablepie',
    //         //    height:400,
    //         //    width:420
    //     },
    //     title: {
    //         text: '',
    //         //   align: 'left'
    //     },
    //     credits: {
    //         enabled: false
    //     },

    //     tooltip: {
    //         backgroundColor: 'null',
    //         //color:"white",
    //         borderWidth: 0,
    //         borderRadius: 2,
    //         padding: 2,
    //         shadow: false,
    //         useHTML: true,
    //         formatter: function () {
    //             return '<div style="background-color:' + this.point.color + '">' + this.point.name + ': ' + this.y + '<br/>' + this.point.z + '</div>';
    //             //   return '<div style="background-color:' + this.point.color + '">' + this.point.name + '</div>';
    //         },
    //         style: {
    //             color: "white",

    //         }
    //     },

    //     plotOptions: {
    //         variablepie: {
    //             allowPointSelect: true,
    //             cursor: 'pointer',
    //             dataLabels: {
    //                 enabled: true,
    //                 format: '<b>{point.percentage:.1f} %</b>',
    //                 style: {
    //                     //     fontWeight: 'bold',
    //                     //     color: 'white'
    //                 }
    //             },
    //             showInLegend: true
    //         },

    //     },
    //     legend: {
    //         symbolWidth: 16,
    //         symbolRadius: 4,
    //         //  rectangleSymbol:true
    //         squareSymbol: false
    //     },
    //     series: [{
    //         minPointSize: 10,
    //         innerSize: '20%',
    //         //   nameToX: true,
    //         zMin: 0,
    //         //name: 'countries',
    //         borderRadius: 5,
    //         sizeBy: 'radius',
    //         data: treatmentDetails.map(obj => ({ name: obj.name, y: obj.value, z: obj.z })),

    //         colors: [
    //             '#EB455F',
    //             '#BAD7E9',
    //             '#323D7B',
    //         ]

    //     }]

    // }

    // const options2 = {
    //     chart: {
    //         type: 'variablepie'
    //     },
    //     title: {
    //         text: '',
    //         //   align: 'left'
    //     },
    //     credits: {
    //         enabled: false
    //     },

    //     tooltip: {
    //         backgroundColor: 'null',
    //         //color:"white",
    //         borderWidth: 0,
    //         borderRadius: 2,
    //         padding: 2,
    //         shadow: false,
    //         useHTML: true,
    //         formatter: function () {
    //             return '<div style="background-color:' + this.point.color + '">' + this.point.name + ': ' + this.y + '<br/>' + this.point.z + '</div>';
    //         },
    //         style: {
    //             color: "white",

    //         }
    //     },

    //     plotOptions: {
    //         variablepie: {
    //             allowPointSelect: true,
    //             cursor: 'pointer',
    //             dataLabels: {
    //                 //   enabled:false,
    //                 enabled: true,
    //                 format: '<b>{point.percentage:.1f} %</b>',
    //                 style: {
    //                     //     fontWeight: 'bold',
    //                     //     color: 'white'
    //                 }
    //             },
    //             showInLegend: true
    //         },

    //     },
    //     legend: {
    //         symbolWidth: 16,
    //         symbolRadius: 4,
    //         //  rectangleSymbol:true
    //         squareSymbol: false
    //     },
    //     series: [{
    //         minPointSize: 30,
    //         innerSize: '20%',
    //         //   nameToX: true,
    //         zMin: 0,
    //         //name: 'countries',
    //         borderRadius: 5,
    //         sizeBy: 'radius',
    //         data: drugTherapy.map(obj => ({ name: obj.name, y: obj.value, z: obj.z })),
    //         colors: [
    //             '#EB455F',
    //             '#BAD7E9',
    //             '#323D7B',
    //         ]
    //     }]

    // }

    // const options3 = {
    //     chart: {
    //         type: 'variablepie'
    //     },
    //     title: {
    //         text: '',
    //         //   align: 'left'
    //     },
    //     credits: {
    //         enabled: false
    //     },

    //     tooltip: {
    //         backgroundColor: 'null',
    //         //color:"white",
    //         borderWidth: 0,
    //         borderRadius: 2,
    //         padding: 2,
    //         shadow: false,
    //         useHTML: true,
    //         formatter: function () {
    //             return '<div style="background-color:' + this.point.color + '">' + this.point.name + ': ' + this.y + '<br/>' + this.point.z + '</div>';
    //         },
    //         style: {
    //             color: "white",

    //         }
    //     },

    //     plotOptions: {
    //         variablepie: {
    //             //     size: '89%', // the size of the pie relative to the plot area
    //             //     innerSize: '20%', 
    //             allowPointSelect: true,
    //             cursor: 'pointer',
    //             dataLabels: {
    //                 enabled: true,
    //                 format: '<b>{point.percentage:.1f} %</b>',
    //                 style: {
    //                     //     fontWeight: 'bold',
    //                     //     color: 'white'
    //                 }
    //             },
    //             showInLegend: true
    //         },

    //     },
    //     legend: {
    //         symbolWidth: 16,
    //         symbolRadius: 4,
    //         //  rectangleSymbol:true
    //         squareSymbol: false
    //     },
    //     series: [{
    //         minPointSize: 10,
    //         innerSize: '20%',
    //         //   nameToX: true,
    //         zMin: 0,
    //         //name: 'countries',
    //         borderRadius: 5,
    //         sizeBy: 'radius',
    //         data: biologicalTherapy.map(obj => ({ name: obj.name, y: obj.value, z: obj.z })),
    //         colors: [
    //             '#EB455F',
    //             '#BAD7E9',
    //             '#323D7B',
    //         ]
    //     }]

    // }

    // const onMouseHover = (arr, current = { value: 'NA' }) => {
    //     return () => {
    //         setTooltipData(`${arr.name}: ${current.value}`);
    //     };
    // };

    // const onMouseHLeave = () => {
    //     setTooltipData('');
    // };


    const onMouseEnter = (geo, current = { value: 'NA' }) => {
        return () => {
            setTooltipContent(`${geo.properties.name}: ${current.value}`);
        };
    };

    const onMouseLeave = () => {
        setTooltipContent('');
    };
    // const handleMouseEnter =(index)=>{
    //     setActiveInd(index);
    // }
    // const handleMouseLeave =()=>{
    //     setActiveInd(-1)
    // }

    const onMouseEnterModel = (geo, current = { value: 'NA' }) => {
        return () => {
            setTooltipContentModel(`${geo.properties.name}: ${current.value}`);
        };
    };

    const onMouseLeaveModel = () => {
        setTooltipContentModel('');
    };

    function timeMsg(params) {
        let myDate = new Date();
        let hours = myDate.getHours();
        let greet;

        if (hours < 12)
            greet = "Good Morning";
        else if (hours >= 12 && hours < 16)
            greet = "Good Afternoon";
        else if (hours >= 16 && hours <= 24)
            greet = "Good Evening";

        return greet;
    }


    const calculateLabelColor = (backgroundColor) => {
        if (backgroundColor.startsWith('#')) {
            const hex = backgroundColor.replace('#', '');
            const rgb = hex.match(/.{1,2}/g).map(val => parseInt(val, 16));

            const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;

            return luminance > 0.5 ? '#000000' : '#ffffff'; // Light background: use dark label color, Dark background: use light label color
        }

        if (backgroundColor.startsWith('rgb')) {
            const rgb = backgroundColor.match(/\d+/g).map(val => parseInt(val, 10));
            const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
            return luminance > 0.5 ? '#000000' : '#ffffff';
        }

        return '#000000';
    };

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
        const value = (percent * 100).toFixed(0) >= 10 ? 0.5 : 0.8
        const radius = innerRadius + (outerRadius - innerRadius) * value;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill={calculateLabelColor(payload?.fill)}
                style={{ fontWeight: 'bold', fontFamily: 'Mulish' }}
                fontSize={10}
                textAnchor="middle" dominantBaseline="central">
                {(percent * 100).toFixed(0) > 2 ? `${(percent * 100).toFixed(0)}%` : null}
            </text>

        );
    };
    const Top10CustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
        const value = (percent * 100).toFixed(0) >= 10 ? 0.5 : 0.8
        const radius = innerRadius + (outerRadius - innerRadius) * value;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x} y={y}
                fill={calculateLabelColor(payload?.fill)}
                style={{ fontWeight: 'bold', fontFamily: 'Mulish' }}
                fontSize={10}
                textAnchor="middle" dominantBaseline="central">
                {(percent * 100).toFixed(0) > 2 ? `${(percent * 100).toFixed(0)}%` : null}
            </text>

        );
    };
    const TMBMSICustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x - 8} y={y}
                // fill="white"
                fill={calculateLabelColor(payload.fill)}
                fontSize={10}
                style={{ fontWeight: 'bold', fontFamily: 'Mulish' }}
                // textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central">
                {(percent * 100).toFixed(0) >= 1 ? `${(percent * 100).toFixed(0)}%` : null}
            </text>

        );
    };
    const TDBCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x - 8} y={y}
                // fill="white"
                fill={calculateLabelColor(payload.fill)}
                fontSize={10}
                style={{ fontWeight: 'bold', fontFamily: 'Mulish' }}
                // textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central">
                {(percent * 100).toFixed(0) >= 1 ? `${(percent * 100).toFixed(0)}%` : null}
            </text>

        );
    };

    const [activeIndex, setActiveIndex] = useState(0)
    const onPieEnter = (_, index,) => {
        setActiveIndex(index)
    };
    const HumanCustomTooltip = ({ active, payload, humanBodyData, percent, label, props }) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        // backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.count}`}
                    </label>
                    {/* <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalCount)).toFixed(0)}%)
                    </label> */}
                </div>
            );
        }
        return null;
    };


    const CustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.cancerSiteName}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.cancerSiteCount}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalCount)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };

    const Top10CustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.cancerSiteName}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.cancerSiteCount}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (top10totalCount)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };

    const TmbCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalTMBInitial = tmbData?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalTMBInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const topBioCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalTMBInitial = tmbData?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalTMBInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };



    const MsiCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalTMBInitial = msiData?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalTMBInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };

    const PdlCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalTMBInitial = pdlData?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalTMBInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };

    const TreatmentCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalTreatmentInitial = treatmentDetails?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalTreatmentInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };
    const DrugCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalDrugInitial = drugTherapy?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalDrugInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };

    const BiologicalCustomTooltip = ({ active, payload, percent, label, props }) => {
        if (active) {
            const totalBiologicalInitial = biologicalTherapy?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.value,
                0
            );
            console.log("bio", biologicalTherapy);
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: payload[0].payload.fill,
                        padding: "5px",
                        borderColor: '#fff'
                    }}
                >
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.name}`}</label>
                    <br />
                    <label style={{ color: '#fff' }}>{`${payload[0].payload.value}`}
                    </label>
                    <label style={{ color: '#fff', marginLeft: 5 }}> ({(`${payload[0].value}` * 100 / (totalBiologicalInitial)).toFixed(0)}%)
                    </label>
                </div>
            );
        }
        return null;
    };

    const top20BooCustomTooltip = ({ active, payload }) => {
        console.log('active: ', active);
        if (active && payload && payload.length) {
            const data = payload[0].payload; // Extract the data object for the current bar

            return (
                <div className="custom-tooltip">
                    <p>{data.name}</p>
                    <p>{data.value}</p>
                </div>
            );
        }

        return null;
    };
    const renderingLegend = (props) => {
        const { payload } = props;
        console.log(payload)
        return (
            <div>
                {
                    payload.reverse().map((entry, index) => (
                        <span key={`item-${index}`}><div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: entry.color }}></div>{entry.value}</span>
                    ))
                }
            </div>
        );
    }


    const renderLegend = (props) => {
        const { payload } = props;
        return (
            <div className='div-container' style={{ marginLeft: 100 }}>
                {
                    payload.map((entry, index) => (
                        <div key={entry.payload.cancerSiteCount} style={{ color: entry.color, padding: 5 }}>{entry.payload.cancerSiteName}</div>
                    ))
                }
            </div>
        );
    }

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 15;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text> */}
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 10} y={ey} textAnchor={textAnchor} fill={fill}>{`${(percent * 100).toFixed(2)}%`}</text>
                {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} (${(percent * 100).toFixed(2)}%)`}</text> */}
                {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text> */}
            </g>
        );
    };
    const getLabelFill = (index) => {
        return index === activeIndex ? "white" : "black"
    }

    function openModal() {
        setIsOpen(true);
    }

    function eventTypeModal() {
        setEventTypeOpen(true);
    }
    function bioMarkerModal(value) {
        setTop20BioMar(value)
        setbioMarkerOpen(true);
    }
    function genderAgeModal() {
        setGenderVsAge(true);
    }
    function genderCancerModal() {
        setGenderVsCancer(true);
    }

    function tmbModal() {
        setTmbOpen(true);
    }

    function msiModal() {
        setMsiOpen(true);
    }

    function pdlModal() {
        setPdlOpen(true);
    }
    function treatmentModal() {
        setTreatmentOpen(true);
    }
    function drugModal() {
        setDrugOpen(true);
    }
    function biologicalModal() {
        setBiologicalOpen(true);
    }

    function eventTypecloseModal() {
        setEventTypeOpen(false);
    }
    function bioMarkercloseModal() {
        setbioMarkerOpen(false);
    }
    function genderAgecloseModal() {
        setGenderVsAge(false);
    }
    function genderCancercloseModal() {
        setGenderVsCancer(false);
    }
    function tmbcloseModal() {
        setTmbOpen(false);
    }

    function msicloseModal() {
        setMsiOpen(false);
    }
    function pdlcloseModal() {
        setPdlOpen(false);
    }
    function treatmentcloseModal() {
        setTreatmentOpen(false);
    }
    function drugcloseModal() {
        setDrugOpen(false);
    }
    function biologicalcloseModal() {
        setBiologicalOpen(false);
    }




    function afterOpenModal() {
    }

    function closeModal() {
        setIsOpen(false);
    }

    function commafy(num) {
        if (num) {
            var str = num.toString().split('.');
            if (str[0].length >= 3) {
                str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
            }
            if (str[1] && str[1].length >= 3) {
                str[1] = str[1].replace(/(\d{3})/g, '$1 ');
            }
            return str.join('.');
        }
    }

    function getAddress(latitude, longitude) {
        return new Promise(function (resolve, reject) {
            var request = new XMLHttpRequest();

            var method = 'GET';
            var url = 'http://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&sensor=true';
            var async = true;

            request.open(method, url, async);
            request.onreadystatechange = function () {
                if (request.readyState === 4) {
                    if (request.status === 200) {
                        var data = JSON.parse(request.responseText);
                        var address = data.results[0];
                        resolve(address);
                    }
                    else {
                        reject(request.status);
                    }
                }
            };
            request.send();
        });
    };

    const topCustomizedLabel = (props) => {
        const { x, y, width, height, value, fill } = props;
        const radius = 10;
        return (
            <g>
                {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill='#a9a9a9' /> */}
                <text x={x + width / 2} y={y - radius} fontSize={12} fill="#a9a9a9" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };
    const CustomLabel = (props) => {
        const { x, y, width, height, value } = props;
        // Adjust the offset and color based on the value
        const offset = value < 10 ? -30 : 5;
        const color = value < 10 ? "#285A64" : "#fff";
        return (
            <text
                x={x + width - offset} // Position the text inside the bar
                y={y + height - 5} // Align the text vertically
                fill={color} // Set the text color
                textAnchor="end" // Align the text horizontally
                fontSize={7}
            >
                {value}
            </text>
        );
    };


    const TreatmentColor = [{
        code: '#EB455F',
        name: 'Surgical Therapy'
    },
    {
        code: '#BAD7E9',
        name: 'Radiation Therapy'
    },
    {
        code: '#323D7B',
        name: 'Drug Therapy'
    },
    ]
    const BiologicalColor = [{
        code: '#EB455F',
        name: 'Immunotherapy'
    },
    {
        code: '#BAD7E9',
        name: 'Targeted Therapy'
    },
    ]
    const DrugColor = [{
        code: '#EB455F',
        name: 'Chemo Therapy'
    },
    {
        code: '#BAD7E9',
        name: 'Biological Therapy'
    },
    {
        code: '#323D7B',
        name: 'Hormonal Therapy'
    },
    ]

    const TmbColor = [{
        code: '#EB455F',
        name: 'High'
    },
    {
        code: '#BAD7E9',
        name: 'Low'
    },
    {
        code: '#323D7B',
        name: 'NA'
    },
    ]
    const MsiColor = [{
        code: '#BAD7E9',
        name: 'Unstable'
    }, {
        code: '#EB455F',
        name: 'Stable'
    }, {
        code: '#323D7B',
        name: 'NA'
    }
    ]
    const PDLColor = [{
        code: '#BAD7E9',
        name: 'Negative'
    }, {
        code: '#EB455F',
        name: "Positive"
    },
    {
        code: '#323D7B',
        name: 'NA'
    },
    ]
    const MAX_LABEL_LENGTH = 10; // Maximum number of characters to show in the label
    const LABEL_ANGLE = -45; // Angle (in degrees) to rotate the labels

    const CustomizedXAxisTick = (props) => {
        const { x, y, payload } = props;
        const [isHovered, setHovered] = useState(false);

        const handleMouseEnter = () => {
            setHovered(true);
        };

        const handleMouseLeave = () => {
            setHovered(false);
        };

        const renderLabel = () => {
            const { value } = payload;
            return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
            //   return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
        };

        return (
            <g transform={`translate(${x},${y})`} textAnchor="end" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <text
                    x={0}
                    y={0}
                    dy={0}
                    fontSize={isHovered ? 12 : 10}
                    fontWeight={isHovered ? 'bold' : 'bold'}
                    textAnchor="end"
                    // fill={payload.index === activeInd ? "#82ca9d" : "#8884d8"}
                    fill={isHovered ? '#5373ff' : "#666"}
                    transform={`rotate(${LABEL_ANGLE})`}
                >
                    {renderLabel()}
                </text>
            </g>
        );
    };

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        const [isHovered, setHovered] = useState(false);

        const handleMouseEnter = () => {
            setHovered(true);
        };

        const handleMouseLeave = () => {
            setHovered(false);
        };

        const renderLabel = () => {
            const { value } = payload;
            return isHovered ? value : value
            //  return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
            //   return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
        };

        return (
            <g transform={`translate(${x},${y})`} textAnchor="end" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <text
                    x={0}
                    y={10}
                    dy={0}
                    fontSize={isHovered ? 14 : 10}
                    fontWeight={isHovered ? 'bold' : 'bold'}
                    textAnchor="middle"
                    fill={isHovered ? '#5373ff' : "#666"}
                //  transform={`rotate(${LABEL_ANGLE})`}
                >

                    {renderLabel()}
                </text>
            </g>
        );
    };

    const CustomTick = ({ x, y, payload }) => {
        // const{payload} = humanBodyData
        const [isHovered, setHovered] = useState(false);
        // if (name.toLowerCase() === "john")
        const color = payload.value === activePart ? 'red' : 'black' && isHovered ? '#5373ff' : 'black';

        //   const color = payload.value === activePart  ? 'red' : 'black';
        //   const col = activePart ? payload.value === activePart :

        const handleMouseEnter = () => {
            setHovered(true);
        };

        const handleMouseLeave = () => {
            setHovered(false);
        };

        const renderLabel = () => {
            const { value } = payload;
            //  const col = activePart ? payload.value === activePart :
            //     return activePart ? activePart :value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
            //     return activePart ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
            return isHovered ? value : value && value?.slice(0, MAX_LABEL_LENGTH) + '...';
        };


        return (
            <g transform={`translate(${x},${y})`} textAnchor="end" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <text
                    x={0}
                    y={0}
                    dy={0}
                    fontSize={isHovered ? 13 : 12}
                    fontWeight={isHovered ? 'bold' : 'normal'}
                    textAnchor="end"
                    fill={color}
                // transform={`rotate(${LABEL_ANGLE})`}    
                >
                    {renderLabel()}
                </text>
            </g>
        );
    };


    // const CustomTooltips = ({ active, payload }) => {
    //   //  active={tooltipActive}
    //    if (tooltipActive && active && payload && payload.length ) {
    //         return (
    //             <div
    //                 className="custom-tooltip"
    //                 style={{ height: 350 ,pointerEvents:'auto'}}
    //             >
    //                 <div className="scrollable-tooltip">
    //                     <div className='py-2'><b>{payload[0].payload.geneName} : {payload[0].payload.total}</b></div>
    //                     {payload && payload.length > 0 && (
    //                         <ul>
    //                             {payload.map((entry, index) => (
    //                                 <li key={`tooltip-${index}`}>
    //                                     <span>{entry.name}:</span>
    //                                     <span>{entry.value}</span>
    //                                 </li>
    //                             ))}
    //                         </ul>
    //                     )}
    //                 </div>
    //             </div>
    //         );
    //     }
    //     return null;
    // };

    const CustomTooltips = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="container bg-white custom_tooltip_bor">
                    <div className="tooltip-content">
                        <b style={{ paddingBottom: 8, paddingTop: 8 }}>{payload[0].payload.geneName} : {payload[0].payload.total}</b>
                        <div className='row'>
                            {payload.map((barData, index) => (
                                <p key={index} className="label">
                                    <div style={{ color: barData?.fill }} className='col'>
                                        {`${barData.name}: ${barData.value}`}
                                    </div>
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };



    return (
        <div className="content-wrapper ">
            {IsLoading && <div className='downloadLoader '>
                <Loader type="spinner-circle" bgColor='black' title={"loading..."} color={'#FFFFFF'} size={100} />
            </div>
            }
            <div className="content-wrapper_inner">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-0  py-3">
                            <div className="col px-0">
                                {/* <div className='d-flex align-items-center px-4 p-4 bg-white rounded-top'>
                                <img src={sunrisePic} style={{ height: 95, width: 120 }} />
                                <div className='ml-3 title_panel'>
                                    <h2 className='mb-0'>{timeMsg()}, {userDetails?.fullName}!</h2>
                                    <p className='mb-0 font-weight-light'>Get the OncoBiz Advantage</p>
                                </div>

                            </div> */}
                                <div className='w-100'></div>
                                <div className='d-flex align-items-center justify-content-between bg-white rounded py-2'>
                                    <div className='title_panel'>
                                        <h4 className='mb-0'>Dashboard</h4>
                                    </div>
                                    <div className=''>
                                        <div className="btn-group range_button home_time_btn">
                                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                                <i className="zmdi zmdi-calendar-note"></i>{filterVal ? filterVal : 'Time Range'}
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-lg-right ">
                                                {/* <button onClick={() => filterGraphs('Last 30 Days')} className="dropdown-item" type="button">Last 30 Days</button> */}
                                                <button onClick={() => filterGraphs('Last 3 Month')} className="dropdown-item" type="button">Last 3 Months</button>
                                                <button onClick={() => filterGraphs('Last 6 Month')} className="dropdown-item" type="button">Last 6 Months</button>
                                                <button onClick={() => filterGraphs('Last 1 year')} className="dropdown-item" type="button">Last 1 year</button>
                                                <button onClick={() => AllGraphsList()} className="dropdown-item" type="button">All Time</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.content-header --> */}

                <section className="content">
                    <div className="container-fluid px-0">
                        <div className=' rounded bg-white pb-3 pt-0 '>
                            <div className="row  ">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-6  pr-lg-0 " style={{ 'height': 220 }}>
                                            <div className="card h-100">
                                                <div className='grap-title'>
                                                    <h6 className='mb-0 py-1'>Number of cases</h6>
                                                    {/* <img src={landScape} /> */}
                                                </div>
                                                <div className='simple-number'>
                                                    <h2 className='mb-0'>{commafy(allGraphData?.patientCount)}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6  mt-3 mt-lg-0 " style={{ 'height': 220, }}>
                                            <div className="card h-100">
                                                <div className='grap-title'>
                                                    <h6 className='mb-0 py-1'>Primary Cancer Sites</h6>
                                                </div>
                                                <div className='simple-number'>
                                                    <h2 className='mb-0'>{userDetails.email === "shankara@4bcresearch.com" ? commafy(psiteCount) : commafy(primarySiteCount)}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col px-0" style={{ 'height': 400, marginTop: 14 }}>
                                        <div className="card h-100">
                                            <div className='grap-title'>
                                                <h6 className='mb-0 py-1'>Cancer Site Distribution</h6>
                                                <div className="d-flex align-items-center">
                                                    <button className="png-button" onClick={handleCanSiteDistClick}><img src={download} className='download' /></button>
                                                    <button className="png-button ml-1" onClick={handleRestOfCanClick}><img src={download} className='download' /></button>
                                                    <div className="ml-3" onClick={() => openModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col-lg-6 col-md-6 col-sm-12' style={{ height: 272 }}>
                                                    <div className='top10cancerTitle'>
                                                        <p className='mb-0 py-1'>Top 10 Cancer Site Distribution</p>
                                                        {/* <button className="png-button" onClick={handleCanSiteDistClick}><img src={download} className='download'/></button>  */}
                                                        <h6 className='mb-0 py-1 logo_color' style={{ fontWeight: 'bold' }}>{top10totalCount}</h6>
                                                    </div>
                                                    {cancerSiteTop10Data ?
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <PieChart width={400} height={200}
                                                                ref={cansiteRef}
                                                            >
                                                                <Pie
                                                                    data={cancerSiteTop10Data}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    label={Top10CustomizedLabel}
                                                                    // activeShape={renderActiveShape}
                                                                    // activeIndex={activeIndex}
                                                                    onMouseEnter={onPieEnter}
                                                                    // outerRadius={110}
                                                                    // innerRadius={50}
                                                                    outerRadius={'85%'}
                                                                    innerRadius={'40%'}
                                                                    fill="#8884d8"
                                                                    dataKey="cancerSiteCount"
                                                                >
                                                                    {cancerSiteTop10Data.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.cancerSiteName).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.cancerSiteName)[0].code : COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                </Pie>
                                                                <Tooltip content={<Top10CustomTooltip />} />

                                                                {/* <Legend layout="horizontal" verticalAlign="top" align="right" content={renderLegend} /> */}

                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                        :
                                                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                                    }
                                                </div>
                                                <div div className='col-lg-6 col-md-6 col-sm-12' style={{ height: 272 }}>
                                                    <div className='top10cancerTitle'>
                                                        <p className='mb-0 py-1'>Rest of the Cancer sites </p>
                                                        {/* <button className="png-button" onClick={ handleRestOfCanClick}><img src={download} className='download'/></button>  */}
                                                        <h6 className='mb-0 py-1 logo_color' style={{ fontWeight: 'bold' }}>{totalCount}</h6>
                                                    </div>
                                                    {cancerSiteRestOfData ?
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <PieChart width={400} height={200}
                                                                ref={restofcanRef}
                                                            >
                                                                <Pie
                                                                    // ref={cansiteRef}
                                                                    data={cancerSiteRestOfData}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    label={renderCustomizedLabel}
                                                                    // activeShape={renderActiveShape}
                                                                    // activeIndex={activeIndex}
                                                                    onMouseEnter={onPieEnter}
                                                                    outerRadius={'85%'}
                                                                    innerRadius={'40%'}
                                                                    fill="#8884d8"
                                                                    dataKey="cancerSiteCount"
                                                                >
                                                                    {cancerSiteRestOfData.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.cancerSiteName).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.cancerSiteName)[0].code : COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                </Pie>
                                                                {value ? <Tooltip content={<CustomTooltip />} /> : ""}

                                                                {/* <Legend layout="horizontal" verticalAlign="top" align="right" content={renderLegend} /> */}

                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                        :
                                                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 pl-lg-0 mt-3 mt-lg-0" >
                                    <div className="" style={{ 'height': 633 }} >
                                        <div className="card h-100" >
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Location</h6>
                                                <div >
                                                    <img src={expand} onClick={() => eventTypeModal()} className='expandImage' />
                                                </div>
                                            </div>
                                            <div className="india_map">
                                                <ComposableMap
                                                    projectionConfig={PROJECTION_CONFIG}
                                                    projection="geoMercator"
                                                    width={310}
                                                    height={210}
                                                    data-tip=""
                                                >
                                                    <Geographies geography={INDIA_TOPO_JSON}>
                                                        {({ geographies }) => (
                                                            <>
                                                                {geographies?.map(geo => {
                                                                    const current = indiaMapData?.find(s => s.id === geo.id);
                                                                    return (
                                                                        <Geography
                                                                            key={geo.rsmKey}
                                                                            geography={geo}
                                                                            // fill={current?.value > 50 ? '#e7382c' : current?.value >= 25 ? 'green' : current?.value >= 1 ? '#3b53cb' : DEFAULT_COLOR}
                                                                            fill={current?.zone === 'ea' ? '#EFF5C5' : current?.zone === 'su' ? '#323D7B' : current?.zone === 'we' ? '#BAD7E9' : current?.zone === 'no' ? '#EB455F' : ''}
                                                                            stroke="#FFFFFF"
                                                                            strokeWidth={0.6}
                                                                            style={{
                                                                                default: {
                                                                                    outline: 'none',
                                                                                },
                                                                                hover: {
                                                                                    // fill: current?.zone == 'ea' ? '#EFF5C5' : current?.zone == 'su' ? '#323D7B' : current?.zone == 'we' ? '#BAD7E9' : current?.zone == 'no' ? '#EB455F' : '',
                                                                                    transition: 'all 250ms',
                                                                                    outline: 'none'
                                                                                },
                                                                                pressed: {
                                                                                    outline: 'none'
                                                                                }
                                                                            }}
                                                                            onMouseEnter={onMouseEnter(geo, current)}
                                                                            onMouseLeave={onMouseLeave}
                                                                            // onClick={()=>console.log('dddd')}
                                                                            id='app-title'
                                                                        />
                                                                    );
                                                                })}
                                                                {geographies.map(geo => {
                                                                    const centroid = geoCentroid(geo);
                                                                    const current = indiaMapData?.find(s => s.id === geo.id);
                                                                    return (
                                                                        <g key={geo.rsmKey + "-name"}>
                                                                            <Marker coordinates={centroid}>
                                                                                <text y="" x='0' fontSize={3} textAnchor="middle" className={current?.zone === 'su' ? 'mapText mapText-color' : 'mapText'}>
                                                                                    {current?.value > 0 ? current?.value : ''}
                                                                                </text>
                                                                            </Marker>
                                                                        </g>
                                                                    );
                                                                })}

                                                            </>
                                                        )
                                                        }
                                                    </Geographies>
                                                </ComposableMap>
                                                {value ? (tooltipContent ? <ReactTooltip>{tooltipContent}</ReactTooltip> : null) : ""}
                                                {/* {tooltipContent ? <ReactTooltip>{tooltipContent}</ReactTooltip> : null} */}
                                            </div>
                                            <div style={{ position: 'absolute', bottom: 0, marginLeft: 8, width: '100%' }}>
                                                <div className="d-flex justify-content-center align-items-center map_buttons mb-2">
                                                    <div className="d-flex align-items-center">
                                                        <div className='north_box'></div>
                                                        <div className='north'>North: <b>{NorthCount}</b></div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className='south_box'></div>
                                                        <div className='south'>South: <b>{southCount}</b></div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className='east_box'></div>
                                                        <div className='east'>East: <b>{EastCount}</b></div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className='west_box'></div>
                                                        <div className='west'>West: <b>{WestCount}</b></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className={"row p-3 "} >
                                <div className="col-12 px-0" style={{ 'height': 500, marginTop: 0 }}>
                                    <div className="card h-100" >
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Top 20 biomarkers across cancers</h6>
                                            <div className="d-flex align-items-center">
                                                <button className="png-button" onClick={handleBioMarClicker}><img src={download} className='download' /></button>
                                                <div className="ml-3" onClick={() => bioMarkerModal(1)}>
                                                    <img src={expand} className='expandImage' />
                                                </div>
                                            </div>
                                        </div>
                                        {!top20Loader ? <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                ref={biocanRef}
                                                width={500}
                                                height={300}
                                                data={top20BoiMarkerList}
                                                margin={{
                                                    top: 20,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            //   onClick={handleBarClick}
                                            //    onMouseLeave={handleBarLeave} 

                                            >
                                                {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                <XAxis dataKey="geneName" interval={0} />
                                                <YAxis />
                                                {value ? <Tooltip content={<CustomTooltips />} /> : ""}

                                                {top20BoiMarkerKeys.map(key => (
                                                    key.dataKey !== 'total' && <Bar key={key.dataKey} dataKey={key.dataKey} fill={key.fill} stackId="a" barSize={40}
                                                        //      onMouseOver={handleMouseOver}
                                                        //     onMouseOut={handleMouseOut}
                                                        onClick={onMouseMove}
                                                        onMouseLeave={onMouseOut}
                                                    //  onClick={handleBarClick}
                                                    //   onMouseLeave={handleBarLeave}
                                                    //  onMouseOut={(e) => e.chart.CustomTooltips.hide()}  
                                                    // label={CustomLabel}
                                                    >
                                                        {/* <LabelList dataKey={key.dataKey} content={topCustomizedLabel} />  */}
                                                    </Bar>
                                                ))}
                                            </BarChart>
                                        </ResponsiveContainer>
                                            :
                                            <div style={{ paddingTop: 180 }}>
                                                <Loader type="spinner-circle" bgColor='black' title={""} color={'#FFFFFF'} size={60} />
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                            <div className={"row px-3 "} >
                                <div className="col-12 px-0" style={{ 'height': 500, marginTop: 0 }}>
                                    <div className="card h-100" >
                                        <div className='grap-title'>
                                            <h6 className='mb-0 py-1'>Prevalence of common biomarkers across cancers</h6>
                                            <div className="d-flex align-items-center">
                                                <button className="png-button" onClick={handlePrevComClicker}><img src={download} className='download' /></button>
                                                <div className="ml-3" onClick={() => bioMarkerModal(2)}>
                                                    <img src={expand} className='expandImage' />
                                                </div>
                                            </div>
                                        </div>
                                        {!top20Loader ? <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                ref={prevcomRef}
                                                width={500}
                                                height={300}
                                                data={CommonBiomarkersList}
                                                margin={{
                                                    top: 20,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                <XAxis dataKey="geneName" interval={0} />
                                                <YAxis />
                                                {value ? <Tooltip content={<CustomTooltips />} /> : ""}
                                                {CommonBiomarkersKeys.map(key => (
                                                    key.dataKey !== 'total' && <Bar key={key.dataKey} dataKey={key.dataKey} fill={key.fill} stackId="a" barSize={40}>
                                                        {/* <LabelList dataKey={key.dataKey} content={topCustomizedLabel} /> */}
                                                    </Bar>
                                                ))}
                                            </BarChart>
                                        </ResponsiveContainer>
                                            :
                                            <div style={{ paddingTop: 180 }}>
                                                <Loader type="spinner-circle" bgColor='black' title={""} color={'#FFFFFF'} size={60} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row  px-3 pt-3" >
                                <div className="col-lg-4 pr-lg-3 pl-0  pr-sm-0 ">
                                    <div className="card h-100" >
                                        <div className='pie_charts tmb'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>TMB</h6>
                                                <div className="d-flex align-items-center">
                                                    <button className="png-button" onClick={handleClick}><img src={download} className='download' /></button>
                                                    <div className="ml-3" onClick={() => tmbModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            {tmbData ?
                                                <ResponsiveContainer width="100%" height="100%"  >

                                                    <PieChart
                                                        ref={tmpRef}
                                                        //   id="currentChart" 
                                                        //    ref={chart => (tmpRef.current= chart)}
                                                        // ref={(chart) => { this.currentChart = chart; } }
                                                        //  ref={chartRef}
                                                        width={300} height={400}>

                                                        <Legend verticalAlign="top" align="top" />
                                                        <Pie
                                                            data={tmbData}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TMBMSICustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >

                                                            {tmbData.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={TmbColor.filter((item) => item.name === entry.name).length > 0 ? TmbColor.filter((item) => item.name == entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        {value ? <Tooltip content={<MsiCustomTooltip />} /> : ""}
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }
                                            {/*                           {chartOptions && (
                                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                          )}   
                                        
                                         <HighchartsReact
                                                         highcharts={Highcharts}
                                                        // options={options}
                                                         options={Tmb}

                                                        // ref="chartComponent1"
                                        />      */}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 pl-0  mt-sm-3 pr-lg-3 mt-lg-0 pr-sm-0">
                                    <div className="card h-100" >
                                        <div className='pie_charts msi'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>MSI</h6>
                                                <div className="d-flex align-items-center">
                                                    <button className="png-button" onClick={handlerSaveClick}><img src={download} className='download' /></button>
                                                    <div className="ml-3" onClick={() => msiModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            {msiData ?
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <PieChart
                                                        ref={smiRef}
                                                        //   ref={chart => (smiRef.current= chart)}
                                                        // id="currentChart" 
                                                        // ref={(chart) => { this.currentChart = chart; } }
                                                        width={300} height={400} >
                                                        <Legend verticalAlign="top" align="top" />
                                                        <Pie
                                                            data={msiData}
                                                            // ref={tmpRef}
                                                            //  ref={printRef}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TMBMSICustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >

                                                            {msiData.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={MsiColor.filter((item) => item.name === entry.name).length > 0 ? MsiColor.filter((item) => item.name == entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        {value ? <Tooltip content={<MsiCustomTooltip />} /> : ""}
                                                    </PieChart>

                                                    {/* <button onClick={handleDownload}>
                                {isLoading ? 'Downloading...' : 'Download Chart'}
                                             </button> */}
                                                </ResponsiveContainer>

                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }


                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 px-0 mt-sm-3 mt-lg-0">

                                    <div className="card h-100" >
                                        <div className='pie_charts pdl1'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>PD-L1</h6>
                                                <div className="d-flex align-items-center">
                                                    <button className="png-button" onClick={handleDownClicker}><img src={download} className='download' /></button>
                                                    <div className="ml-3" onClick={() => pdlModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            {pdlData ?
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <PieChart
                                                        ref={dlpRef}
                                                        //  ref={chart => (dlpRef.current= chart)}
                                                        //  id="currentChart" 
                                                        //   ref={(chart) => { this.currentChart = chart; } }
                                                        width={400} height={400}>
                                                        <Legend verticalAlign="top" align="top" />
                                                        <Pie
                                                            data={pdlData}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TMBMSICustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {pdlData.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={PDLColor.filter((item) => item.name === entry.name).length > 0 ? PDLColor.filter((item) => item.name == entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        {value ? <Tooltip content={<PdlCustomTooltip />} /> : ""}
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className='  px-3 pb-3 pt-2'>
                                <div className='row ' style={{}}>
                                    <div className="col-lg-6 px-0" style={{ 'height': 600, marginTop: 8 }}>
                                        <div className="card h-100">
                                            <div className='grap-title'>
                                                <h6 className='mb-0 py-1'>Gender vs Cancer Sites</h6>
                                                <div className="d-flex align-items-center">
                                                    <button className="png-button" onClick={handleCancerClick}><img src={download} className='download' /></button>
                                                    <div className="ml-3" onClick={() => genderCancerModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            {genderCancerSite ?
                                                <ResponsiveContainer height={550} >
                                                    <BarChart
                                                        ref={cancerRef}
                                                        // width={700}
                                                        // layout="vertical"
                                                        // barCategoryGap={1}
                                                        height={450}
                                                        // minPointSize={15}
                                                        margin={{
                                                            top: 30,
                                                            right: 10,
                                                            left: 10,
                                                            bottom: 30,
                                                        }}
                                                        data={genderCancerSite} >
                                                        {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                        <YAxis type="number" domain={[0, '1000']} allowDataOverflow={true} />
                                                        <XAxis
                                                            // type="category"
                                                            width={200}
                                                            // tick={{ fontSize: 12 }}
                                                            tick={<CustomizedXAxisTick />}
                                                            interval={genderCancerSite.length > 5 ? 1 : 0} tickLine={false}
                                                            // interval={1}
                                                            angle={-35}
                                                            // textAnchor="end"
                                                            // sclaeToFit="true"
                                                            height={100}
                                                            // tickFormatter={val => val.toLocaleString().replace(/,/g, " ")}
                                                            dataKey="cancerSiteName" />
                                                        {genderData.map((item, index) => {
                                                            return (
                                                                <Bar key={item.cancerSiteName} dataKey={item.cancerSiteName} stackId="a" fill={item.color} barSize={50} />
                                                            )
                                                        })}
                                                        <Legend />
                                                        {value ? <Tooltip cursor={false} /> : ""}
                                                    </BarChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6 pr-0   " style={{ 'height': 600, marginTop: 8 }}>
                                        <div className="card h-100">
                                            <div className='grap-title'>
                                                <h6 className='mb-0 py-1'>Gender vs Age Group Distribution</h6>
                                                <div className="d-flex align-items-center">
                                                    <button className="png-button" onClick={handleAgeClicker}><img src={download} className='download' /></button>
                                                    <div className="ml-3" onClick={() => genderAgeModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            {genderAgeGroup ?
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <BarChart
                                                        // ref={barRef}
                                                        ref={AgeRef}
                                                        width={500}
                                                        height={300}
                                                        data={genderAgeGroup}
                                                        margin={{
                                                            top: 30,
                                                            right: 10,
                                                            left: 10,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        {/* <CartesianGrid strokeDasharray="1 1" /> */}
                                                        <XAxis dataKey="ageGroup"
                                                            tick={<CustomizedAxisTick />}
                                                        />
                                                        <YAxis />
                                                        {value ? <Tooltip cursor={{ fill: '#f7f7f7' }} /> : ""}
                                                        <Legend />
                                                        <Bar dataKey="Male" fill="#55CC61">
                                                            <LabelList dataKey="Male" content={topCustomizedLabel} />
                                                        </Bar>
                                                        <Bar dataKey="Female" fill="#005F73">
                                                            <LabelList dataKey="Female" content={topCustomizedLabel} />
                                                        </Bar>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row  px-3" >
                                <div className="col-lg-4 pr-lg-3 pl-0  pr-sm-0 ">
                                    <div className="card h-100" >
                                        <div className='pie_charts tmb'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Treatment Distribution</h6>
                                                <div className="d-flex align-items-center">
                                                    <button className="png-button" onClick={handleTreatmentClicker}><img src={download} className='download' /></button>
                                                    <div className="ml-3" onClick={() => treatmentModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            {treatmentDetails ?
                                                <ResponsiveContainer width="100%" height="100%"  >

                                                    <PieChart
                                                        ref={trtRef}
                                                        width={300} height={400}>
                                                        <Legend verticalAlign="top" align="top" />
                                                        <Pie
                                                            data={treatmentDetails}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TDBCustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >

                                                            {treatmentDetails.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={TreatmentColor.filter((item) => item.name === entry.name).length > 0 ? TreatmentColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        {value ? <Tooltip content={<TreatmentCustomTooltip />} /> : ""}
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }

                                            {/* <div ref={chartRef}>
                                             <HighchartsReact
                                                         highcharts={Highcharts}
                                                        // ref={chartRef}
                                                        // options={options}
                                                         options={options1}

                                                          // ref="chartComponent1"
                                    />           
                                             </div>
                                                    */}


                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 pl-0  mt-sm-3 pr-lg-3 mt-lg-0 pr-sm-0">
                                    <div className="card h-100" >
                                        <div className='pie_charts msi'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Drug Therapy</h6>
                                                <div className="d-flex align-items-center">
                                                    <button className="png-button" onClick={handlerDrugClick}><img src={download} className='download' /></button>
                                                    <div className="ml-3" onClick={() => drugModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            {drugTherapy ?
                                                <ResponsiveContainer width="100%" height="100%"  >

                                                    <PieChart
                                                        ref={drugRef}
                                                        width={300} height={400}>
                                                        <Legend verticalAlign="top" align="top" />
                                                        <Pie
                                                            data={drugTherapy}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TDBCustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {drugTherapy.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={DrugColor.filter((item) => item.name === entry.name).length > 0 ? DrugColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        {value ? <Tooltip content={<DrugCustomTooltip />} /> : ""}
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                                            }

                                            {/* {drugTherapy ?
                                            <div id="chartdiv" style={{ width: '400px', height: "450px" }}></div>        
                                            :     
                                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} /> */}



                                            {/*          <HighchartsReact
                                                         highcharts={Highcharts}
                                                        // options={options}
                                                         options={options2}

                                                        // ref="chartComponent1"
                                    />         */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 px-0 mt-sm-3 mt-lg-0">

                                    <div className="card h-100" >
                                        <div className='pie_charts pdl1'>
                                            <div className='grap-title' >
                                                <h6 className='mb-0 py-1'>Biological Therapy</h6>
                                                <div className="d-flex align-items-center">
                                                    <button className="png-button" onClick={handlerBiologicalClick}><img src={download} className='download' /></button>
                                                    <div className="ml-3" onClick={() => biologicalModal()}>
                                                        <img src={expand} className='expandImage' />
                                                    </div>
                                                </div>
                                            </div>
                                            {biologicalTherapy ?
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <PieChart ref={bioloRef} width={300} height={400}>
                                                        <Legend verticalAlign="top" align="top" />
                                                        <Pie
                                                            //  ref={bioloRef}
                                                            data={biologicalTherapy}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={TDBCustomizedLabel}
                                                            outerRadius={'65%'}
                                                            innerRadius={'30%'}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >

                                                            {biologicalTherapy.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={BiologicalColor.filter((item) => item.name === entry.name).length > 0 ? BiologicalColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        {value ? <Tooltip content={<BiologicalCustomTooltip />} /> : ""}
                                                    </PieChart>
                                                </ResponsiveContainer>
                                                :
                                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />

                                            }
                                            {/* {biologicalTherapy ?
                                            <div id="biologicaldiv" style={{ width: '400px', height: "450px" }}></div>        
                                            :
                                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />

                                        }     */}

                                            {/* <HighchartsReact
                                                        ref={bioloRef}
                                                         highcharts={Highcharts}
                                                        // options={options}
                                                         options={options3}
                                                       
                                    />          */}
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>



                    </div>

                </section>
            </div>
            <div className="footer_logo mt-2">
                <div>
                    <p className='mb-0'>4baseCare Onco Solutions Pvt. Ltd. All rights reserved. 2024-2025.</p>
                </div>
                <div className='note_img mr-1'>
                    <img src={company_logo} />
                </div>

            </div>


            {/* Cancer Site Distribution */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div className='grap-title'>
                        <h6 className='mb-0 py-1'>Cancer Site Distribution</h6>
                        <div className='close_icon' onClick={() => closeModal()}>
                            {/* <img src={expand} /> */}
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                        </div>
                    </div>
                    <div className="row">
                        <div style={{ width: '50%', height: 600, marginTop: 15 }}>
                            <div className='top10cancerTitle'>
                                <p className='mb-0 py-1'>Top 10 Cancer Site Distribution</p>
                                <h6 className='mb-0 py-1 logo_color' style={{ fontWeight: 'bold' }}>{top10totalCount}</h6>
                            </div>
                            {cancerSiteTop10Data ?
                                <ResponsiveContainer width="100%" >
                                    <PieChart width={400} height={150}>
                                        <Pie
                                            data={cancerSiteTop10Data}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={Top10CustomizedLabel}
                                            // activeShape={renderActiveShape}
                                            // activeIndex={activeIndex}
                                            onMouseEnter={onPieEnter}
                                            outerRadius={'70%'}
                                            innerRadius={'30%'}
                                            fill="#8884d8"
                                            dataKey="cancerSiteCount"
                                        >
                                            {cancerSiteTop10Data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.cancerSiteName).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.cancerSiteName)[0].code : COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip content={<Top10CustomTooltip />} />

                                        {/* <Legend layout="horizontal" verticalAlign="top" align="right" content={renderLegend} /> */}

                                    </PieChart>
                                </ResponsiveContainer>
                                :
                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                            }
                        </div>
                        <div style={{ width: '50%', height: 600, marginTop: 15 }}>
                            <div className='top10cancerTitle'>
                                <p className='mb-0 py-1'>Rest of the Cancer sites</p>
                                <h6 className='mb-0 py-1 logo_color' style={{ fontWeight: 'bold' }}>{totalCount}</h6>
                            </div>
                            {cancerSiteRestOfData ?
                                <ResponsiveContainer width="100%" >
                                    <PieChart width={400} height={200}>
                                        <Pie
                                            data={cancerSiteRestOfData}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                            // activeShape={renderActiveShape}
                                            // activeIndex={activeIndex}
                                            onMouseEnter={onPieEnter}
                                            outerRadius={'70%'}
                                            innerRadius={'30%'}
                                            fill="#8884d8"
                                            dataKey="cancerSiteCount"
                                        >
                                            {cancerSiteRestOfData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={CancerSiteColors.filter((item) => item.name === entry.cancerSiteName).length > 0 ? CancerSiteColors.filter((item) => item.name === entry.cancerSiteName)[0].code : COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        {value ? <Tooltip content={<CustomTooltip />} /> : ""}

                                        {/* <Legend layout="horizontal" verticalAlign="top" align="right" content={renderLegend} /> */}

                                    </PieChart>
                                </ResponsiveContainer>
                                :
                                <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                            }
                        </div>
                    </div>

                </div>
            </Modal>

            {/*Bio Marker */}
            <Modal
                isOpen={bioMarkerOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={bioMarkercloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div>
                        <div className='grap-title Modal-top-cn'>
                            <h6 className='mb-0 py-1'>{top20bioMar === 1 ? 'Top 20 biomarkers across cancers' : 'Prevalence of common biomarkers across cancers'} </h6>
                            {console.log('top20bioMar: ', top20bioMar)}
                            <div className='close_icon' onClick={() => bioMarkercloseModal()}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>
                        {!top20Loader && <ResponsiveContainer width="100%" height={600}>
                            <BarChart
                                width={500}
                                height={300}
                                data={top20bioMar === 1 ? top20BoiMarkerList : CommonBiomarkersList}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="1 1" />
                                <XAxis dataKey="geneName" interval={0} />
                                <YAxis />
                                {value ? <Tooltip content={<CustomTooltips />} /> : ""}
                                {/* <Tooltip content={<CustomTooltips />} wrapperStyle={{ pointerEvents: 'auto' }} /> */}
                                {top20bioMar === 1 ?
                                    top20BoiMarkerKeys.map(key => (
                                        key.dataKey !== 'total' && <Bar key={key.dataKey} dataKey={key.dataKey} fill={key.fill} stackId="a" barSize={40} />
                                    )) :
                                    CommonBiomarkersKeys.map(key => (
                                        key.dataKey !== 'total' && <Bar key={key.dataKey} dataKey={key.dataKey} fill={key.fill} stackId="a" barSize={40} />
                                    ))
                                }
                            </BarChart>
                        </ResponsiveContainer>
                        }
                    </div>
                </div>
            </Modal>

            {/* Tmb */}
            <Modal
                isOpen={tmbOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={tmbcloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div className='grap-title'>
                        <h6 className='mb-0 py-1'>TMB</h6>
                        <div className='close_icon' onClick={() => tmbcloseModal()}>
                            {/* <img src={expand} /> */}
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                        </div>
                    </div>
                    {tmbData ?
                        <ResponsiveContainer width="100%" height={550}>
                            <PieChart width={300} height={400}>
                                <Legend verticalAlign="top" align="top" />
                                <Pie
                                    data={tmbData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false} label={TMBMSICustomizedLabel}
                                    outerRadius={'90%'}
                                    innerRadius={'40%'}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {tmbData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={TmbColor.filter((item) => item.name === entry.name).length > 0 ? TmbColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                {value ? <Tooltip content={<TmbCustomTooltip />} /> : ""}
                            </PieChart>
                        </ResponsiveContainer>
                        :
                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                    }

                </div>
            </Modal>

            {/* MSI */}
            <Modal
                isOpen={msiOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={msicloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div className='grap-title'>
                        <h6 className='mb-0 py-1'>MSI</h6>
                        <div className='close_icon' onClick={() => msicloseModal()}>
                            {/* <img src={expand} /> */}
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                        </div>
                    </div>
                    {msiData ?
                        <ResponsiveContainer width="100%" height={550}>
                            <PieChart width={300} height={400}>
                                <Legend verticalAlign="top" align="top" />
                                <Pie
                                    data={msiData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={TMBMSICustomizedLabel}
                                    outerRadius={'90%'}
                                    innerRadius={'40%'}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {msiData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={MsiColor.filter((item) => item.name === entry.name).length > 0 ? MsiColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                {value ? <Tooltip content={<MsiCustomTooltip />} /> : ""}
                            </PieChart>
                        </ResponsiveContainer>
                        :
                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                    }

                </div>
            </Modal>

            {/* PDL */}
            <Modal
                isOpen={pdlOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={pdlcloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div className='grap-title'>
                        <h6 className='mb-0 py-1'>PD-L1</h6>
                        <div className='close_icon' onClick={() => pdlcloseModal()}>
                            {/* <img src={expand} /> */}
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                        </div>
                    </div>
                    {pdlData ?
                        <ResponsiveContainer width="100%" height={550}>
                            <PieChart width={300} height={400}>
                                <Legend verticalAlign="top" align="top" />
                                <Pie
                                    data={pdlData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={TMBMSICustomizedLabel}
                                    outerRadius={'90%'}
                                    innerRadius={'40%'}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {pdlData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={PDLColor.filter((item) => item.name === entry.name).length > 0 ? PDLColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                {value ? <Tooltip content={<PdlCustomTooltip />} /> : ""}
                            </PieChart>
                        </ResponsiveContainer>
                        :
                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                    }

                </div>
            </Modal>

            {/* Location Wise Distribution */}
            <Modal
                isOpen={eventTypeOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={eventTypecloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div>
                        <div className='grap-title Modal-top-cn'>
                            <h6 className='mb-0 py-1'>Location Wise Distribution</h6>
                            <div className='close_icon' onClick={() => eventTypecloseModal()}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                            </div>
                        </div>

                        <ComposableMap
                            projectionConfig={PROJECTION_CONFIG}
                            projection="geoMercator"
                            width={500}
                            height={220}
                            data-tip=""
                        >
                            <Geographies geography={INDIA_TOPO_JSON}>
                                {({ geographies }) => (
                                    <>
                                        {geographies?.map(geo => {
                                            const current = indiaMapData?.find(s => s.id === geo.id);
                                            return (
                                                <Geography
                                                    key={geo.rsmKey}
                                                    geography={geo}
                                                    // fill={current?.value > 50 ? '#e7382c' : current?.value >= 25 ? 'green' : current?.value >= 1 ? '#3b53cb' : DEFAULT_COLOR}
                                                    fill={current?.zone === 'ea' ? '#EFF5C5' : current?.zone === 'su' ? '#323D7B' : current?.zone === 'we' ? '#BAD7E9' : current?.zone === 'no' ? '#EB455F' : ''}
                                                    stroke="#FFFFFF"
                                                    strokeWidth={0.6}
                                                    style={{
                                                        default: {
                                                            outline: ''
                                                        },
                                                        hover: {
                                                            fill: current?.zone === 'ea' ? '#8a2759' : current?.zone === 'su' ? '#528bfa' : current?.zone === 'we' ? '#f53b81' : current?.zone === 'no' ? '#3148ad' : '',
                                                            transition: 'all 250ms',
                                                            outline: 'none'
                                                        },
                                                        pressed: {
                                                            outline: 'none'
                                                        }
                                                    }}
                                                    onMouseEnter={onMouseEnterModel(geo, current)}
                                                    onMouseLeave={onMouseLeaveModel}
                                                    // onClick={()=>console.log('dddd')}
                                                    id='app-title'
                                                />
                                            );
                                        })}
                                        {geographies.map(geo => {
                                            const centroid = geoCentroid(geo);
                                            const current = indiaMapData?.find(s => s.id === geo.id);
                                            return (
                                                <g key={geo.rsmKey + "-name"}>
                                                    <Marker coordinates={centroid}>
                                                        <text y="2" fontSize={3} textAnchor="middle" className={current?.zone === 'su' ? 'mapText mapText-color' : 'mapText'}>
                                                            {current?.value > 0 ? current?.value : ''}
                                                        </text>
                                                    </Marker>
                                                </g>
                                            );
                                        })}

                                    </>
                                )
                                }
                            </Geographies>
                        </ComposableMap>
                        {value ? (tooltipContent ? <ReactTooltip>{tooltipContent}</ReactTooltip> : null) : ""}
                    </div>
                    <div style={{ position: 'absolute', bottom: 0, marginLeft: 8, width: '100%' }}>
                        <div className="d-flex justify-content-center align-items-center map_buttons mb-2">
                            <div style={{ position: 'absolute', bottom: 0, marginLeft: 8, width: '100%' }}>
                                <div className="d-flex justify-content-center align-items-center map_buttons mb-2">
                                    <div className="d-flex align-items-center">
                                        <div className='north_box'></div>
                                        <div className='north'>North: <b>{NorthCount}</b></div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className='south_box'></div>
                                        <div className='south'>South: <b>{southCount}</b></div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className='east_box'></div>
                                        <div className='east'>East: <b>{EastCount}</b></div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className='west_box'></div>
                                        <div className='west'>West: <b>{WestCount}</b></div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-center map_buttons">
                                                <button type='button' className="btn  south">South</button>
                                                <button type='button' className="btn  east">East</button>
                                            </div> */}
                    </div>
                </div>
            </Modal>

            {/* Gender vs Cancer Sites */}
            <Modal
                isOpen={gendervdCancerOpen}
                onRequestClose={genderCancercloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    {genderCancerSite ?
                        <div>
                            <div className='grap-title Modal-top-cn'>
                                <h6 className='mb-0 py-1'>Gender vs Cancer Sites</h6>
                                <div className='close_icon' onClick={() => genderCancercloseModal()}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                </div>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <ResponsiveContainer width="100%" height={530}>
                                    <BarChart
                                        width={1200}
                                        // layout="vertical"
                                        // barCategoryGap={1}
                                        height={650}
                                        data={genderCancerSite} >
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <YAxis type="number" domain={[0, '1000']} allowDataOverflow={true} />
                                        <XAxis
                                            type="category"

                                            // // width={200}
                                            // tick={{ fontSize: 12 }}
                                            //  tickLine={false}
                                            // interval={0}
                                            angle={-45}
                                            textAnchor="end"
                                            // sclaeToFit="true"
                                            //  tick={<CustomizedXAxisTick />}
                                            interval={0}
                                            //  tickLine={false}
                                            height={200}
                                            dataKey="cancerSiteName" y={5} />
                                        {genderData.map((item, index) => {
                                            return (
                                                <Bar key={item.cancerSiteName} dataKey={item.cancerSiteName} stackId="a" fill={item.color} barSize={50} />
                                            )
                                        })}
                                        <Legend />
                                        {value ? <Tooltip cursor={{ fill: 'transparent' }} /> : ""}
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        :
                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                    }
                </div>
            </Modal>

            {/* gender Vs AgeGroup Distributions */}
            <Modal
                isOpen={gendervdAgeOpen}
                onRequestClose={genderAgecloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    {genderAgeGroup ?
                        <div>
                            <div className='grap-title Modal-top-cn'>
                                <h6 className='mb-0 py-1'>Gender vs Age Group Distribution</h6>
                                <div className='close_icon' onClick={() => genderAgecloseModal()}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                                </div>
                            </div>
                            <ResponsiveContainer width="100%" height={680}>
                                <BarChart
                                    width={1600}
                                    height={680}
                                    data={genderAgeGroup}
                                    margin={{
                                        top: 25,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <XAxis dataKey="ageGroup" />
                                    <YAxis />
                                    {value ? <Tooltip /> : ""}
                                    <Legend />
                                    <Bar dataKey="Male" fill="#55CC61">
                                        <LabelList dataKey="Male" content={topCustomizedLabel} />
                                    </Bar>
                                    <Bar dataKey="Female" fill="#005F73">
                                        <LabelList dataKey="Female" content={topCustomizedLabel} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        :
                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                    }
                </div>
            </Modal>


            <Modal
                isOpen={treatmentopen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={treatmentcloseModal}
                //  ariaHideApp={false}
                // appElement={'chartdivision'}
                // appElement={document.getElementById('useLayoutEffect')}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div className='grap-title'>
                        <h6 className='mb-0 py-1'>Treatment Distribution</h6>
                        <div className='close_icon' onClick={() => treatmentcloseModal()}>
                            {/* <img src={expand} /> */}
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                        </div>
                    </div>
                    {treatmentDetails ?
                        <ResponsiveContainer width="100%" height={550} >

                            <PieChart
                                // ref={trtRef}
                                width={300} height={550}>
                                <Legend verticalAlign="top" align="top" />
                                <Pie
                                    data={treatmentDetails}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={TDBCustomizedLabel}
                                    outerRadius={'90%'}
                                    innerRadius={'40%'}
                                    fill="#8884d8"
                                    dataKey="value"
                                >

                                    {treatmentDetails.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={TreatmentColor.filter((item) => item.name === entry.name).length > 0 ? TreatmentColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                {value ? <Tooltip content={<TreatmentCustomTooltip />} /> : ""}
                            </PieChart>
                        </ResponsiveContainer>
                        :
                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                    }

                    {/* {treatmentDetails?

                                          <div id="chartdivision" style={{ width: '400px', height: "450px" }}></div>             
                                            :
                                            <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />

                                        } */}
                </div>
            </Modal>


            <Modal
                isOpen={drugOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={drugcloseModal}
                //   ariaHideApp={false}
                // appElement={document.getElementById('chartdiv')}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div className='grap-title'>
                        <h6 className='mb-0 py-1'>Drug Therapy</h6>
                        <div className='close_icon' onClick={() => drugcloseModal()}>
                            {/* <img src={expand} /> */}
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                        </div>
                    </div>
                    {drugTherapy ?
                        <ResponsiveContainer width="100%" height={550}  >

                            <PieChart
                                //  ref={drugRef}
                                width={300} height={400}>
                                <Legend verticalAlign="top" align="top" />
                                <Pie
                                    data={drugTherapy}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={TDBCustomizedLabel}
                                    outerRadius={'90%'}
                                    innerRadius={'40%'}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {drugTherapy.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={DrugColor.filter((item) => item.name === entry.name).length > 0 ? DrugColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                {value ? <Tooltip content={<DrugCustomTooltip />} /> : ""}
                            </PieChart>
                        </ResponsiveContainer>
                        :
                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />
                    }

                    {/* <div id='chartdiv'></div>    */}

                </div>
            </Modal>

            {/* PDL */}
            <Modal
                isOpen={biologicalOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={biologicalcloseModal}
                //ariaHideApp={false}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div>
                    <div className='grap-title'>
                        <h6 className='mb-0 py-1'>Biological Therapy</h6>
                        <div className='close_icon' onClick={() => biologicalcloseModal()}>
                            {/* <img src={expand} /> */}
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></svg>
                        </div>
                    </div>
                    {biologicalTherapy ?
                        <ResponsiveContainer width="100%" height={550}>
                            <PieChart width={300} height={400}>
                                <Legend verticalAlign="top" align="top" />
                                <Pie
                                    //  ref={bioloRef}
                                    data={biologicalTherapy}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={TDBCustomizedLabel}
                                    outerRadius={'90%'}
                                    innerRadius={'40%'}
                                    fill="#8884d8"
                                    dataKey="value"
                                >

                                    {biologicalTherapy.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={BiologicalColor.filter((item) => item.name === entry.name).length > 0 ? BiologicalColor.filter((item) => item.name === entry.name)[0].code : COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                {value ? <Tooltip content={<BiologicalCustomTooltip />} /> : ""}
                            </PieChart>
                        </ResponsiveContainer>
                        :
                        <Loader type="spinner-circle" bgColor='black' title={"spinner-circle"} color={'#FFFFFF'} size={100} />

                    }

                </div>
            </Modal>


        </div>


    )
}
export default Dashboard;