import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Context/context";
import { SideMenuHideShowContext, UserDatailContext } from "../Context/userContext";
import homeIcon from '../assets/images/home_icon.svg';
import newAnyalsis from '../assets/images/new_Anyalsis.png';
import savedAnyalsis from '../assets/images/saved_anyalsis.png';
import Analytics from '../assets/images/Analytics icon.svg';
import setting from '../assets/images/settings.svg';
import logOut from '../assets/images/logOut.svg';
import shapeImage from '../assets/images/shape_svg.svg';
import divBox from '../assets/images/Vector.svg';
import Polygon1 from '../assets/images/Polygon1.svg';
import pdL1 from '../assets/images/pdL1.svg';
import HRR from '../assets/images/HRR.svg';
import ALK from '../assets/images/ALK.svg';
import SquaresFour from '../assets/images/SquaresFour.svg';
// import shapeImage from '../assets/images/shape_image.png';
import {
    BrowserRouter as Router,
    useLocation, useNavigate
} from "react-router-dom";
export default function SideMenu(props) {
    const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [sideMeneShowHide, setSideMeneShowHide] = useContext(SideMenuHideShowContext);
    const [userDetails, setUserDetails] = useContext(UserDatailContext);
    const [dropdownShow, setDropDownShow] = useState(null)
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [activeTab, setActiveTab] = useState(splitLocation[1])
    function logout(params) {
        navigate('/')
        signOutFun()
    }
    // useEffect(() => {
    //     console.log(location.pathname)
    //   }, [location])

    useEffect(() => {
        window.scrollTo({ top: 0 });
        //  if (activeTab === 'Dashboard') {
        //     setActiveTab('Dashboard')
        //     setDropDownShow(null)
        // }
        // else  if (activeTab === '/') {
        //     setActiveTab('/')
        //     setDropDownShow(null)
        // }
        if (location.pathname === "/Dashboard"||activeTab === 'Dashboard') {
            setActiveTab("/Dashboard")
            setDropDownShow(null)
        }
        else if (location.pathname === "/" ||activeTab === '') {
            setActiveTab("/")
            setDropDownShow(null)
        }
        else if (activeTab === 'GeneAnalysis') {
            setActiveTab('GeneAnalysis')
        //  navigate('/PDLAnyalsis')
        }
        else if (location.pathname === "/NTRKAnyalsis") {
            setActiveTab("/NTRKAnyalsis")
           // setDropDownShow(null)
        }
     //   else if(!dropdownShow){
     //      setActiveTab('GeneAnalysis')
         // setActiveTab(GeneAnalytics)
        //  navigate('/PDLAnyalsis')
      //  }
      //  else if(location.pathname !== '/PDLAnyalsis' || location.pathname !== '/ALKAnyalsis' || location.pathname !== '/NTRKAnyalsis' || location.pathname !== '/ROSAnyalsis' || location.pathname !== '/EGFRAnyalsis' || location.pathname !== '/RETAnyalsis'  || location.pathname !== '/ERBB2Anyalsis'){
         //   setActiveTab('GeneAnalysis'); 
      //      navigate('/PDLAnyalsis') 
    //    }
        //   else if(activeTab === 'PDLAnyalsis' || activeTab == 'ALKAnyalsis' || activeTab == 'NTRKAnyalsis' || activeTab == 'ROSAnyalsis' || activeTab == 'EGFRAnyalsis' || activeTab == 'RETAnyalsis' || activeTab == 'GeneAnalysis' ){
        //     setDropDownShow(!dropdownShow)
        //   }
        //   else if(!dropdownShow){
        //     navigate('/PDLAnyalsis')
        //   }

        //  else if(activeTab==="Dashboard"){
        //     setActiveTab("/Dashboard")
        //  }
        // scroll to the top of the browser window when changing route
        // the window object is a normal DOM object and is safe to use in React.
    }, [location.pathname]);
  
    // useEffect(() => {
    //      if (activeTab === 'GeneAnalysis'&& location.pathname!=="/" && location.pathname!=="/Dashboard") {
    //         setActiveTab('GeneAnalysis')
    //     //  navigate('/PDLAnyalsis')
    //     }
    // }, [activeTab,location.pathname])

    useEffect(() => {
        if (sideMeneShowHide) {
            setDropDownShow(null)
        } else {
            // setDropDownShow(null) 
        }
    }, [sideMeneShowHide])

    function homePage() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setActiveTab('')
        setDropDownShow(null)
        navigate('/')
    }

    function dashboardPage() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setActiveTab('Dashboard')
        setDropDownShow(null)
        navigate('/Dashboard')
    }

    function PDLAnyalsisPage() {
        navigate('/PDLAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    function HRRPage() {
        navigate('/HRR')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function ALKPage() {
        navigate('/ALKAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function NTRKPage() {
        navigate('/NTRKAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function ROSKPage() {
        navigate('/ROSAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function RETKPage() {
        navigate('/RETAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function EGFRKPage() {
        navigate('/EGFRAnyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    function ERBB2Page() {
        navigate('/ERBB2Anyalsis')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function GeneAnalytics(params) {
        setActiveTab('GeneAnalysis')
        setDropDownShow(!dropdownShow)
        navigate('/PDLAnyalsis')
        // setActiveTab('PDLAnalysis')
    }

    const ids = ["oncoviz-analyst@mailinator.com", "kshitij@user", "payal@user", "deb@4basecare.com"];

    return (
        <aside className={sideMeneShowHide ? "main-sidebar side-nav sideNav-menu1" : "main-sidebar side-nav "} >
            <ul className="list-unstyled mb-0 pt-3">
                <li onClick={() => homePage()}><a className={activeTab === '' || location.pathname === '/' ? "is-active" : ''}><span className="icon"><img src={homeIcon} /></span><span className="title">Home</span><img src={shapeImage} className="active-shape" /></a></li>
                <li onClick={() => dashboardPage()}><a className={activeTab === 'Dashboard' || location.pathname === '/Dashboard' ? "is-active" : ''}><span className="icon"><img src={SquaresFour} /></span><span className="title">Dashboard</span><img src={shapeImage} className="active-shape" /></a></li>
                {ids?.includes(userDetails?.email) && <li onClick={() => GeneAnalytics()}><a className={activeTab === 'PDLAnyalsis' || activeTab == 'ALKAnyalsis' || activeTab == 'NTRKAnyalsis' || activeTab == 'ROSAnyalsis' || activeTab == 'EGFRAnyalsis' || activeTab == 'RETAnyalsis' || activeTab == 'GeneAnalysis' || activeTab === 'ERBB2Anyalsis' ? "is-active" : ''} ><span className="icon"><img src={Analytics} /></span><span className="title">Gene Analysis</span><img src={Polygon1} className="dropdown_image" style={{ transform: dropdownShow ? 'rotate(180deg)' : '', marginLeft: 15 }} /><img src={shapeImage} className="active-shape" /></a></li>}
                {dropdownShow && <div className="side-menu-dropdown">
                    <div className={activeTab === 'PDLAnyalsis' ? "is-active sidemenu-option" : 'sidemenu-option sidemenu-hover'} onClick={() => PDLAnyalsisPage()}>
                        <div>
                            <img src={ALK} className="dropdown-img" />
                            <span className="tooltip_span">PD-L1</span>
                        </div>
                        <span className="dropdown-title" style={{ color: splitLocation[1] === 'PDLAnyalsis' ? '#5373FF' : '' }} >PD-L1</span>
                    </div>
                    <div className={activeTab === 'ALKAnyalsis' ? "is-active sidemenu-option" : 'sidemenu-option sidemenu-hover'} onClick={() => ALKPage()}>
                        <div>
                            <img src={ALK} className="dropdown-img" />
                            <span className="tooltip_span">ALK</span>
                        </div>
                        <span className="dropdown-title" style={{
                            color: splitLocation[1] === 'ALKAnyalsis' ? '#5373FF' : ''
                        }}>ALK</span>
                    </div>
                    <div className={activeTab === 'NTRKAnyalsis' ? "is-active sidemenu-option" : 'sidemenu-option sidemenu-hover'} onClick={() => NTRKPage()}>
                        <div>
                            <img src={ALK} className="dropdown-img" />
                            <span className="tooltip_span">NTRKAnyalsis</span>
                        </div>
                        <span className="dropdown-title" style={{ color: splitLocation[1] === 'NTRKAnyalsis' ? '#5373FF' : '' }}>NTRK</span>
                    </div>
                    <div className={activeTab === 'ROSAnyalsis' ? "is-active sidemenu-option" : 'sidemenu-option sidemenu-hover'} onClick={() => ROSKPage()}>
                        <div>
                            <img src={ALK} className="dropdown-img" />
                            <span className="tooltip_span">ROSAnyalsis</span>

                        </div>
                        <span className="dropdown-title" style={{ color: splitLocation[1] === 'ROSAnyalsis' ? '#5373FF' : '' }}>ROS</span>
                    </div>
                    <div className={activeTab === 'RETAnyalsis' ? "is-active sidemenu-option" : 'sidemenu-option sidemenu-hover'} onClick={() => RETKPage()}>
                        <div>
                            <img src={ALK} className="dropdown-img" />
                            <span className="tooltip_span">RETAnyalsis</span>
                        </div>
                        <span className="dropdown-title" style={{ color: splitLocation[1] === 'RETAnyalsis' ? '#5373FF' : '' }}>RET</span>
                    </div>
                    <div className={activeTab === 'EGFRAnyalsis' ? "is-active sidemenu-option" : 'sidemenu-option sidemenu-hover'} onClick={() => EGFRKPage()}>
                        <div>
                            <img src={ALK} className="dropdown-img" />
                            <span className="tooltip_span">EGFRAnyalsis</span>

                        </div>
                        <span className="dropdown-title" style={{ color: splitLocation[1] === 'EGFRAnyalsis' ? '#5373FF' : '' }}>EGFR</span>
                    </div>
                    <div className={activeTab === 'ERBB2Anyalsis' ? "is-active sidemenu-option" : 'sidemenu-option sidemenu-hover'} onClick={() => ERBB2Page()}>
                        <div>
                            <img src={ALK} className="dropdown-img" />
                            <span className="tooltip_span">ERBB2Anyalsis</span>

                        </div>
                        <span className="dropdown-title" style={{ color: splitLocation[1] === 'ERBB2Anyalsis' ? '#5373FF' : '' }}>ERBB2</span>
                    </div> 
                </div>
                }
                {/* <li onClick={() => ALKPage()}><a className={splitLocation[1] == 'ALKAnyalsis' ? "is-active" : ''} ><span className="icon"><img src={Analytics} /></span><span className="title">ALK Anyalsis</span><img src={shapeImage} className="active-shape" /></a></li> */}
                {/* <li onClick={() => HRRPage()}><a className={splitLocation[1] == 'HRR' ? "is-active" : ''} ><span className="icon"><img src={Analytics} /></span><span className="title">HRR</span><img src={shapeImage} className="active-shape" /></a></li> */}
            </ul>
            <div className="logout_section">
                <ul className="list-unstyled mb-0 pt-3">
                    {/* <li><a ><span className="icon"><img src={setting} /></span> <span className="title">Settings</span> <img src={shapeImage} className="active-shape" /> </a></li> */}
                    <li><a onClick={() => logout()}><span className="icon"><img src={logOut} /></span> <span className="title">Logout</span> <img src={shapeImage} className="active-shape" /></a></li>
                </ul>
            </div>

        </aside>
        // <aside className={sideMeneShowHide ? "main-sidebar side-nav sideNav-menu1" : "main-sidebar side-nav "} >

        //     <ul className="list-unstyled mb-0 pt-3">
        //         <li onClick={() => homePage()}><a className={splitLocation[1] == '' ? "is-active" : ''}><span className="icon"><img src={homeIcon} /></span><span className="title">Home</span><img src={shapeImage} className="active-shape" /></a></li>
        //         <li onClick={() => PDLAnyalsisPage()}><a className={splitLocation[1] == 'PDLAnyalsis' ? "is-active" : ''} ><span className="icon"><img src={Analytics} /></span><span className="title">PD-L1 Anyalsis</span><img src={shapeImage} className="active-shape" /></a></li>
        //         <li onClick={() => ALKPage()}><a className={splitLocation[1] == 'ALKAnyalsis' ? "is-active" : ''} ><span className="icon"><img src={Analytics} /></span><span className="title">ALK Anyalsis</span><img src={shapeImage} className="active-shape" /></a></li>
        //         {/* <li onClick={() => HRRPage()}><a className={splitLocation[1] == 'HRR' ? "is-active" : ''} ><span className="icon"><img src={Analytics} /></span><span className="title">HRR</span><img src={shapeImage} className="active-shape" /></a></li> */}
        //     </ul>
        //     <div className="logout_section">
        //         <ul className="list-unstyled mb-0 pt-3">
        //             <li><a ><span className="icon"><img src={setting} /></span> <span className="title">Settings</span> <img src={shapeImage} className="active-shape" /> </a></li>
        //             <li><a onClick={() => logout()}><span className="icon"><img src={logOut} /></span> <span className="title">Logout</span> <img src={shapeImage} className="active-shape" /></a></li>
        //         </ul>
        //     </div>

        // </aside>
    )
}



