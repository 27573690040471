import Loader from "react-js-loader";
import React, { useEffect } from "react";
const Loaders = () => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => (document.body.style.overflow = "scroll");
    });
    // document.body.style.overflow = "hidden"
    return (
        <div>
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}
export default Loaders
