import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Link, Routes, Navigate } from 'react-router-dom';
import { AuthContext } from '../Context/context';
import { SideMenuHideShowContext } from "../Context/userContext";
import { setDefaultHeader, apiCall } from '../utils/httpClient';
import Login from "../Screen/Login";
import Registration from "../Screen/Registration";
import ForgotPassword from "../Screen/ForgotPassword";
import ThankuPage from "../Screen/ThankuPage";
import Dashboard from "../Screen/Dashboard";
import PDLAnyalsis from "../Screen/PDLAnyalsis";
import ALKAnyalsis from "../Screen/ALKAnyalsis";
import NTRKAnyalsis from "../Screen/NTRKAnyalsis";
import ROSAnyalsis from "../Screen/ROSAnyalsis";
import RETAnyalsis from "../Screen/RETAnyalsis";
import EGFRAnyalsis from "../Screen/EGFRAnyalsis";
import ERBB2Anyalsis from "../Screen/ERBB2Anyalsis";
import HRR from "../Screen/HRR";
import Header from "../Components/Header";
import New_header from "../Components/New_header"
import SideMenu from "../Components/SideMenu";
import Footer from "../Components/Footer";
import Loader from "../Components/Loader";
import Home from "../Screen/Home";
import PDL from "../Screen/PDL"
import Roche from "../Screen/Roche"
import Protected from "../Components/Protected"
import {
  LoaderContext, UserDatailContext
} from "../Context/userContext";

function RoutesFile() {
  //const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useContext(LoaderContext);
  const [userDetails, setUserDetails] = useContext(UserDatailContext);
  const [sideMeneShowHide, setSideMeneShowHide] = useContext(SideMenuHideShowContext);

  let isLogged = false;
  const initialLoginState = {
    isLoading: true,
    userName: null,
    userToken: null,
  };
  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case 'RETRIEVE_TOKEN':
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false,
        };
      case 'LOGIN':
        return {
          ...prevState,
          userName: action.id,
          userToken: action.token,
          isLoading: false,
        };
      case 'LOGOUT':
        return {
          ...prevState,
          userName: null,
          userToken: null,
          isLoading: false,
        };
      case 'REGISTER':
        return {
          ...prevState,
          userName: action.id,
          userToken: action.token,
          isLoading: false,
        };
    }
  };
  const [loginState, dispatch] = React.useReducer(loginReducer, initialLoginState);
  const authContext = React.useMemo(() => ({
    signInFun: async (foundUser) => {
      const userToken = String(foundUser[0].userToken);
      const userName = foundUser[0].username;
      try {
        await localStorage.setItem('userToken', userToken);
        const expirationTime = Date.now() + sessionDurationMs;
        await localStorage.setItem('sessionExpiration', expirationTime.toString());
        // window.location.assign('/')
        // navigate('/')
      } catch (e) {
        console.log(e);
      }
      // console.log('user token: ', userToken);
      dispatch({ type: 'LOGIN', id: userName, token: userToken });
    },
    signOutFun: async (user_id) => {
      try {
        await localStorage.removeItem('userData');
        await localStorage.removeItem('userToken');
        // window.location.reload('/')
        await localStorage.removeItem('sessionExpiration');
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: 'LOGOUT' });
    },
  }), []);

  // const authContext = useMemo(() => ({
  //   signInFun,
  //   signOutFun,
  //   // ... other context methods
  // }), []);
  const signOutFun = async () => {
    try {
      await localStorage.removeItem('userData');
      await localStorage.removeItem('userToken');
      await localStorage.removeItem('sessionExpiration');
      dispatch({ type: 'LOGOUT' });
    } catch (e) {
      console.log(e);
    }
  };

  //  const sessionDurationMs = 30000;
  const sessionDurationMs = 24 * 60 * 60 * 1000;
  useEffect(() => {
    const checkSessionExpiration = async () => {
      const expirationTime = await localStorage.getItem('sessionExpiration');
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
       // alert("Session expired,Please Login Again")
        console.log("Session expired Please,Login again")
        //  navigate("/")
        signOutFun();

      }
    };

    checkSessionExpiration();
    const intervalId = setInterval(checkSessionExpiration, 1000); //check

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getToken()
    setTimeout(async () => {
      let userToken;
      userToken = null;
      try {
        userToken = await localStorage.getItem('userToken');
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: 'RETRIEVE_TOKEN', token: userToken });
    }, 1000);

  }, []);

 

  async function getToken() {
    try {
      const Auth = await localStorage.getItem('userToken');
      const userData = await localStorage.getItem('userData');
      const userDetails = await localStorage.getItem('userDetails');
      if (Auth === '01') {
        await setDefaultHeader('Authorization', 'Bearer' + ' ' + userData)
        await setUserDetails(JSON.parse(userDetails))
        console.log("lll",JSON.parse(userDetails))
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (loginState.isLoading) {

    return (
      <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        {/* <Loder /> */}
      </div>
    );

  }
  const ids = ["anjali@4basecare.com","kshitij@user", "oncoviz-analyst@mailinator.com"];
  
  return (
    <div>

      {/* {IsLoading && <div className="loaderMain">
        <Loader />
      </div>} */}
      <AuthContext.Provider value={authContext}>
        {/* {loginState.userToken !== null ? ( */}
        {loginState.userToken ? (
          <Router>
            <New_header/>
            <div className="justify-content-between d-flex">
              {/* <div className={!sideMeneShowHide ? "sideNav" : 'sideNav-menu'}>
                <SideMenu />
              </div> */}
              <div className={!sideMeneShowHide ? "dashBoard p-0" : "dashBoard-sidemenu p-0"} style={{}}>

                {ids.includes(userDetails?.email) ?
                  (
                   
                    <Routes>
                    
                       {/* <Route path="/" element={<Protected element={<Home />} />} />
                      <Route path="/Dashboard" element={<Protected element={<Dashboard />} />} /> */}
                      <Route path="/" element={<Dashboard />} /> 
                      <Route path="/Home" element={<Home/>} />
                      {/* <Route path="/Dashboard" element={<Protected isAuthenticated={isAuthenticated}><Dashboard/></Protected>} /> */}
                        <Route path="/PDLAnyalsis" element={<PDL/>} />
                      <Route path="/ALKAnyalsis" element={<ALKAnyalsis/>} />
                      <Route path="/HRR" element={<HRR />} />
                      <Route path="/NTRKAnyalsis" element={<NTRKAnyalsis/>} />
                      <Route path="/ROSAnyalsis" element={<ROSAnyalsis/>} />
                      <Route path="/EGFRAnyalsis" element={<EGFRAnyalsis/>} />
                      <Route path="/RETAnyalsis" element={<RETAnyalsis/>} />
                      <Route path="/ERBB2Anyalsis" element={<ERBB2Anyalsis/>} />
                    </Routes>
                   
                  )
                  :
                  <div>
                       {userDetails?.email !== "roche@user" &&(
                    <Routes>
                      <Route path="/" element={<Dashboard/>} />
                      </Routes>
                   
                 )}
                  </div>
                  // 
                  

                
                }
                    <div>
              {userDetails?.email === "roche@user" &&(
                 <Routes>
                   <Route path="/PDLAnyalsis" element={<PDL/>} />
                    {/* <Route path="/roche" element={<Roche />} />  */}
                 </Routes>
                
              )}
              </div>

              </div>
          
            </div>
          </Router>
         )
          : 
          <Router>
            <div>
              <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/thankuPage" element={<ThankuPage />} />
                <Route path="*" element={<Navigate replace to="/login" />} />
                {/* <Route path="*" element={<Login/>} /> */}

              </Routes>
            </div>
          </Router>
         } 
      </AuthContext.Provider>

    </div>
  );
}
export default RoutesFile;
