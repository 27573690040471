// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap'); */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Inter', sans-serif; */
  font-family: "Mulish", sans-serif;
}
::placeholder {
  font-size: 11px;
  color: #cfcfcf !important;
  font-weight: 300;
}
p,
li,
a,
button,
span,
input,
label,
a {
  font-size: 14px !important;
  line-height: 20px;
  color: #757575;
}

.recharts-layer .recharts-cartesian-axis-tick{
  position: absolute;
  top: 0px!important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;GAYG;AAEH,qGAAqG;AACrG;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,sCAAsC;EACtC,iCAAiC;AACnC;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;AACA;;;;;;;;EAQE,0BAA0B;EAC1B,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":["/* body {\r\n  margin: 0;\r\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\r\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\r\n    sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n    monospace;\r\n} */\r\n@import url(\"https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap\");\r\n/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap'); */\r\n* {\r\n  margin: 0;\r\n  padding: 0;\r\n  box-sizing: border-box;\r\n  /* font-family: 'Inter', sans-serif; */\r\n  font-family: \"Mulish\", sans-serif;\r\n}\r\n::placeholder {\r\n  font-size: 11px;\r\n  color: #cfcfcf !important;\r\n  font-weight: 300;\r\n}\r\np,\r\nli,\r\na,\r\nbutton,\r\nspan,\r\ninput,\r\nlabel,\r\na {\r\n  font-size: 14px !important;\r\n  line-height: 20px;\r\n  color: #757575;\r\n}\r\n\r\n.recharts-layer .recharts-cartesian-axis-tick{\r\n  position: absolute;\r\n  top: 0px!important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
