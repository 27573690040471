import React, { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../Context/context";
import hamburgerIcon from '../assets/images/Hamburger menu.svg'
import OncoBizLogo from '../assets/images/oncoBiz_logo.svg'
import messageIcon from '../assets/images/message-question.svg'
import notifications from '../assets/images/notification.svg'
import userPic from '../assets/images/user_account.png'
import userimg from '../assets/images/userimg.jpg'
import hamburgerSimpleIcon from '../assets/images/Hamburger_Menu_simple.png'
import { SideMenuHideShowContext, UserDatailContext } from "../Context/userContext";

export default function Header(props) {
    const [sideMeneShowHide, setSideMeneShowHide] = useContext(SideMenuHideShowContext);
    const { signInFun, signOutFun } = useContext(AuthContext);
    const [userDetails, setUserDetails] = useContext(UserDatailContext);
    const navigate = useNavigate();


    return (
        <div className="header header_bg cust_align px-3 ">
            <div className="left_section" >
                <a onClick={() => setSideMeneShowHide(!sideMeneShowHide)}>
                    <img src={!sideMeneShowHide ? hamburgerIcon : hamburgerSimpleIcon} className={sideMeneShowHide ? 'simplehamberger' : "hamberger"} />
                </a>
                <img src={OncoBizLogo} className="w-60 onco_logo" onClick={() => navigate("/")} />
            </div>
            <div className="right_section">
                {/*    <ul className="list-unstyled d-flex mb-0">
                    <li><a href="#"><img src={messageIcon} /></a></li>
                    <li><a href="#"><img src={notifications} /></a></li> 
                    <li>   */}
                {/* <div className="btn-group ml-3">
                            <button type="button" className="btn pr-0" data-toggle="dropdown" data-display="static" aria-expanded="false"> */}
                <div className="user_main">
                    <div className="user_left text-right">
                        <span>{userDetails?.fullName}</span><br />
                        <span className="role">{userDetails?.role}</span>
                    </div>
                    <div className="user_right p-2">
                        <span>{userDetails.email === "shankara@4bcresearch.com" ? <img src={userimg} /> : <img src={userPic} />}</span>
                    </div>
                </div>
                {/* </button> */}
                {/* <div className="dropdown-menu dropdown-menu-lg-right ">
                                <button className="dropdown-item" type="button">Profile</button>
                                <button onClick={() => signOutFun()} className="dropdown-item" type="button">Logout</button>
                            </div> */}
                {/* </div> */}
                {/* </li> */}

                {/* </ul> */}
            </div>
        </div>
    )
}