import React, { useEffect, useContext, useState,useMemo } from "react";
import Roche from "./Components/Roche";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { setDefaultHeader, apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { GLOBAL_URL } from '../../utils/constants'

import { AuthContext } from "../../Context/context";
import { LoaderContext, UserDatailContext } from "../../Context/userContext";


const RocheAnyalsisScreen = () => {
    const history = useNavigate()
    const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [IsLoading, setIsLoading] = useContext(LoaderContext);

    const [downloading, setDownloading] = useState(false);
    const [userDetails, setUserDetails] = useContext(UserDatailContext);
    const[rochedata,setRocheData] = useState([]);
    const[rocheVal,setRocheVal] = useState('');
    const[fromVal,setFromVal] = useState('');
    const[toVal,setToVal] = useState('');
    const[excelbio,setExcelbio] = useState([]);
    const[statusExcel,setStatusExcel] = useState('');
    const[totalval,setTotalval] = useState([]);
    const [dateRange, setDateRange] = useState({
        fromDate: '',
        toDate: '',
      });

   
    // useEffect(() => {

    // }, [fromVal,toVal,excelbio,statusExcel]);

    const today = new Date();
    const year = today.getFullYear().toString();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');

    const toDate = `${year}-${month}-${day}`;
    //console.log(formattedDate);

    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const yr = thirtyDaysAgo.getFullYear();
    const mth = (thirtyDaysAgo.getMonth() + 1).toString().padStart(2, '0');
    const dy = thirtyDaysAgo.getDate().toString().padStart(2, '0');

    const fromDate = `${yr}-${mth}-${dy}`;
    //console.log(`30 days ago: ${formattedDate}`);

    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const yrr = sevenDaysAgo.getFullYear();
    const mths = (sevenDaysAgo.getMonth() + 1).toString().padStart(2, '0');
    const dys = sevenDaysAgo.getDate().toString().padStart(2, '0');

    const frommDate = `${yrr}-${mths}-${dys}`;

    const threeDaysAgo = new Date(today);
    threeDaysAgo.setDate(today.getDate() - 3);

    const yrre = threeDaysAgo.getFullYear();
    const moth = (threeDaysAgo.getMonth() + 1).toString().padStart(2, '0');
    const val = threeDaysAgo.getDate().toString().padStart(2, '0');

    const fromDatee = `${yrre}-${moth}-${val}`;
    const handleDateRangeSelection = () => {
        setFromVal(fromDate);
        setToVal(toDate);
    };
    const handleWeekRangeSelection = () => {
       // excelValfun()
        setFromVal(frommDate);
        setToVal(toDate)
        // excelValfun()
    };
    const handlethreedaysSelection = () => {
       // excelValfun()
        setFromVal(fromDatee);
        setToVal(toDate);
       // console.log(fromval);
      
    };
   
    const excelValfun = async () => {
        setIsLoading(true)
        const params = {
          fromDate:fromVal ? fromVal : null,
          toDate:toVal ? toVal : null,
          geneNames: excelbio.length > 0 ? excelbio.map((x) => x.value) : null,
          status:statusExcel?statusExcel:null
        }
        const { data } = await apiCall('POST', ApiEndPoint.RocheExcelCount, params)
        if (data) {
            console.log("excelCount=>", data)
            setTotalval(data?.payLoad)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            toast.error(data, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    } 
  

    const excel= async () => {
        setDownloading(true)
        setDefaultHeader(true)
        excelValfun()
        const anchor = document.createElement('a');
        //let headers = new Headers();
        const token = await localStorage.getItem('userData');
        fetch(GLOBAL_URL + ApiEndPoint.Rochepatients,{
        method:'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          
        },
        body: JSON.stringify({
            fromDate:fromVal ? fromVal : null,
            toDate:toVal ? toVal : null,
            geneNames: excelbio.length > 0 ? excelbio.map((x) => x.value) : null,
          //  geneNames:excelbio.length>0?[excelbio.map((x)=>x.value).join(',')]:null,
            status:statusExcel?statusExcel:null 
          
        }),
        })
           // excelValfun() 
            .then(response => response.blob())
            .then(data => {
                console.log('data: ',data);
                setDownloading(false)
              //  excelValfun()
                if (data.type === "application/octet-stream") {
                    setRocheData(data.size)
                    let objectUrl = window.URL.createObjectURL(data);
                    anchor.href = objectUrl;
                    anchor.download = 'RocheData.xlsx';
                    anchor.click();
                    anchor.remove();
                    window.URL.revokeObjectURL(objectUrl);
                    toast.success('Successfully downloaded !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    setDownloading(false)
                    toast.error('Something went wrong', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
    }
        

    return (
        <>
            <Roche     
                userDetails={userDetails}
                setRocheVal={setRocheVal}
                setFromVal={setFromVal}
                setToVal={setToVal}
                setExcelbio={setExcelbio}
                setStatusExcel={setStatusExcel}
                statusExcel={statusExcel}
                excelbio={excelbio}
                totalval={totalval}
                rocheVal={rocheVal}
                fromVal={fromVal}
                toVal={toVal}
                excel={excel}
                rochedata={rochedata}
                downloading={downloading}
                excelValfun={()=>excelValfun()}
                handleWeekRangeSelection={()=>handleWeekRangeSelection()}
                handlethreedaysSelection={()=>handlethreedaysSelection()}
                IsLoading={IsLoading}
                setDateRange={setDateRange}
                dateRange={dateRange}
            />
        </>
    )
}
export default RocheAnyalsisScreen