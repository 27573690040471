export default {
    // Auth API Start
    SIGNIN: '/user/login',
    LAST_LOGIN: '/user/last_login',
    LOGIN_REQUEST: '/oncoViz/login_request',
    AllGraphs:'/oncoViz/dashboard_graphs',
    BioMarkers:'/oncoViz/get_biomarkers',
    PDLAnalysis:'/oncoViz/pdl_analysis_graphs',
    FilterGraphs:'/oncoViz/dashboard_graphs_filter',
    PDLFilterGraphs:'/oncoViz/pdl_analysis_graphs_filter',
    pdl1ExportExcel:'/oncoViz/pdl1_export_excel',
    alkRocheGeneAnalysis:'/oncoViz/roche_gene_analysis_graph/ALK',
    NTRKRocheGeneAnalysis:'/oncoViz/roche_gene_analysis_graph/NTRK',
    ROSRocheGeneAnalysis:'/oncoViz/roche_gene_analysis_graph/ROS',
    RETRocheGeneAnalysis:'/oncoViz/roche_gene_analysis_graph/RET',
    EGFRRocheGeneAnalysis:'/oncoViz/roche_gene_analysis_graph/EGFR',
    alkFilter:'/oncoViz/roche_gene_analysis_graphs_filter/ALK',
    NTRKFilter:'/oncoViz/roche_gene_analysis_graphs_filter/NTRK',
    ROSFilter:'/oncoViz/roche_gene_analysis_graphs_filter/ROS',
    RETFilter:'/oncoViz/roche_gene_analysis_graphs_filter/RET',
    EGFRFilter:'/oncoViz/roche_gene_analysis_graphs_filter/EGFR',
    cancerTypeDropdown:'cancerSite/getCancerSites',
   DataOverview:'/oncoViz/oncoviz_data_overview',
   MajorCancerSites:'/oncoViz/oncoviz_data_overview_cancer_site',
   MorphologyCount:'/pdl1/oncoviz/get_morphology_count',
   PdlAgeGender:'/pdl1/oncoviz/age_group_and_gender_count',
   PdlTotalPatients:'/pdl1/oncoviz/total_patients_count',
   PdlTobaccoCount:'/pdl1/oncoviz/tobacco_count',
   PdlFamilyHistoryCount:'/pdl1/oncoviz/family_history_count',
   firstDegreeCount:'/pdl1/oncoviz/first_degree_count',
   PdlCancerSiteCount:'/pdl1/oncoviz/get_cancer_site_count',
   PdlDeceasedCount:'/pdl1/oncoviz/get_deceased_count',
   PdlMsiCount:'/pdl1/oncoviz/get_msi_status_count',
   PdlTmbCount:'/pdl1/oncoviz/get_tmb_status_count',
   PdlStatusCount:'/pdl1/oncoviz/get_pdl1_status_count',
   PdlCloneCount:'/pdl1/oncoviz/get_pdl1_clone_count',
   PdlMultipleCloneCount:'/pdl1/oncoviz/multiple_clone_count',
   Top10CancerSite:'/pdl1/oncoviz/get_top_10_cancer_site_count',
   DrugsCount:'/pdl1/oncoviz/get_bio_drugs_count',
   DrugsTherapy:'/pdl1/oncoviz/get_drug_theraphy_count',
   BiologicalTherapy:'/pdl1/oncoviz/get_biological_drug_theraphy_count',
   Rochepatients:'/roche/excel_data',
   RocheExcelCount:'/roche/excel_data/count',
   PdlExcelData:'/pdl1/oncoviz/roche/excel_data',
   PdlHospitalCity:'/pdl1/oncoviz/get_hospital_city_graph',
   //PdlHospitalName:'/pdl1/oncoviz/get_hospital_name_graph_by_city'
  PdlHospitalName:'/pdl1/oncoviz/get_hospital_name_graph_by_city/',
  PdlCancerStage:'/pdl1/oncoviz/get_cancerStage_count_graph',
  PdlGeneCancerSite:'/pdl1/oncoviz/get_gene_cancerSite_count_graph',
  PdlRecistCriteria:'/pdl1/oncoviz/get_iRecist_Criteria_count',
  NumberOfCancerSites:'/pdl1/oncoviz/number_of_cancer_sites',
  NumberOfHospitals:'/pdl1/oncoviz/number_of_hospitals',

}