import React, { useEffect, useContext, useState } from "react";
import PDLAnyalsis from "./Components/PDLAnyalsis";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { setDefaultHeader, apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { GLOBAL_URL } from '../../utils/constants'

import { AuthContext } from "../../Context/context";
import { LoaderContext, UserDatailContext } from "../../Context/userContext";

const PDLAnyalsisScreen = () => {
    const history = useNavigate()
    const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [IsLoading, setIsLoading] = useContext(LoaderContext);

    const [downloadLoading, setDownloadLoading] = useState(false);
    const [userDetails, setUserDetails] = useContext(UserDatailContext);
    const [allGraphData, setAllGraphData] = useState([])
    const [filterVal, setFilterVal] = useState('')
    const [pdlFilterVal, setPdlFilterVal] = useState('')
    const [pdlCloneFilterVal, setPdlCloneFilterVal] = useState('')
    const [top10totalCount, setTop10TotalCount] = useState(0)
    const [primarySiteTotal, setPrimarySiteTotal] = useState(0)
    const [pdlCloneTotal, setPdlCloneTotal] = useState(0)
    const [pdl1ListTotal, setPdl1ListTotal] = useState([]);
    const [top20CitiesData, setTop20CitiesData] = useState([])
    const [hospitalNameData, setHospitalNameData] = useState([]);
    const [hospitalName, setHospitalName] = useState('');
    const [primarySiteDistribution, setPrimarySiteDistribution] = useState([]);
    const [drugTherapy, setDrugTherapy] = useState([]);
    const [biologicalTherapy, setBiologicalTherapy] = useState([])
    const [drugsCount, setDrugsCount] = useState([])
    const [top10CanSiteList, setTop10CanSiteList] = useState([])
    const [drugTherapyTotal, setDrugTherapyTotal] = useState([]);
    const [biologicalTherapyTotal, setBiologicalTherapyTotal] = useState([]);
    const [drugsCountTotal, setDrugsCountTotal] = useState([])

    useEffect(() => {
        AllGraphsList()
        //  DrugBiologicalList()
    }, [])
    function bodyDataFun(dt) {
        const humanData = [
            { name: 'Lung', value: 0, id: 'Lung' },
            { name: 'Breast', value: 0, id: 'Breast' },
            { name: 'Pancreas', value: 0, id: 'Pancreas' },
            { name: 'Stomach', value: 0, id: 'Stomach' },
            { name: 'Liver and intrahepatic bile ducts', value: 0, id: 'Liver and intrahepatic bile ducts' },
            { name: 'Esophagus', value: 0, id: 'Esophagus' },
            { name: 'Gallbladder', value: 0, id: 'Gallbladder' },
            { name: "Large Intestine", value: 0, id: "Large Intestine" },
            { name: "Ovary", value: 0, id: "Ovary" },
            { name: "Corpus Uteri", value: 0, id: "Corpus Uteri" }

        ]

        const newState = humanData.map(obj => {
            if (obj.name === dt?.filter((item) => { return item.cancerSiteName === obj.name })[0]?.cancerSiteName) {
                return { ...obj, value: dt?.filter((item) => { return item.cancerSiteName === obj.name })[0]?.cancerSiteCount };
            }
            return obj;
        });
        const sortedData = newState?.sort((a, b) => b.value - a.value);
        console.log("humantopp10", sortedData);
        return sortedData
    }
    function drugDataChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.name === "CHEMOTHERAPY") {
                return { ...obj, name: 'Chemo Therapy' };
            }
            else if (obj?.name === "DRUG_THERAPY") {
                return { ...obj, name: 'Hormonal Therapy' };
            }
            return obj;
        })
        return newState
    }

    function biologicalDataChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.name === "Targeted therapy") {
                return { ...obj, name: "Targeted Therapy" };
            }
            return obj;
        })
        return newState
    }


    function apiResponse(data) {
        setAllGraphData(data.payLoad);
        console.log("pdldata", data);
        const initialValue = 0;
        const topCities = data.payLoad?.hospitalCities?.filter((item) => { return item.value > 0 ? item : '' })
        setTop20CitiesData(topCities)
        const pdl1ListTotal = data.payLoad?.pdl1List?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        const pdl1CloneListTotal = data.payLoad?.pdl1CloneList?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        const biologicalTotal = data.payLoad?.biologicalTherapyDetails?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        const drugTherapyTotal = data.payLoad?.drugTherapyDetails?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        const drugsCountTotal = data.payLoad?.drugsCountDetails?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.value,
            initialValue
        );
        console.log("drugTherapytotal", drugTherapyTotal);
        const hospital = data?.payLoad?.hospitalCities[0].hospitalNameResList?.filter((item) => {
            if ((item.value !== 0)) {
                return item
            }
        })
        const sortedData = data.payLoad?.cancerSiteDistributionList?.sort((a, b) => b.cancerSiteCount - a.cancerSiteCount);
        console.log("sort", sortedData);
        const top10data = sortedData?.slice(0, 10);
        console.log("humantop10", top10data);
        setTop10CanSiteList(bodyDataFun(sortedData))
        console.log("topcansite", top10CanSiteList)
        const primarySite = data?.payLoad?.cancerSiteDistributionList?.filter((item) => {
            if (item.cancerSiteCount !== 0) {
                return item
            }
        }).sort((a, b) => b.cancerSiteCount - a.cancerSiteCount).filter((item) => item.cancerSiteCount >= 6)

        const primarySiteTotal = primarySite.reduce(
            (accumulator, currentValue) => accumulator + currentValue.cancerSiteCount,
            initialValue
        );
        setPrimarySiteTotal(primarySiteTotal)
        setPdlCloneTotal(pdl1CloneListTotal)
        setDrugTherapy(drugDataChange(data.payLoad?.drugTherapyDetails));
        setBiologicalTherapy(biologicalDataChange(data.payLoad?.biologicalTherapyDetails));
        setDrugsCount(data.payLoad?.drugsCountDetails)
        setPrimarySiteDistribution(primarySite)
        setHospitalNameData(hospital)
        setHospitalName(data?.payLoad?.hospitalCities[0].name)
        setPdl1ListTotal(pdl1ListTotal)
        setTop10TotalCount(pdl1ListTotal);
        setBiologicalTherapyTotal(biologicalTotal)
        setDrugTherapyTotal(drugTherapyTotal)
        setDrugsCountTotal(drugsCountTotal)
        setIsLoading(false)
    }

    const AllGraphsList = async () => {
        setIsLoading(true)
        const { data } = await apiCall('GET', ApiEndPoint.PDLAnalysis)
        if (data.success) {
            setFilterVal('')
            setPdlFilterVal('')
            setPdlCloneFilterVal('')
            apiResponse(data)
            setIsLoading(false)
            //DrugBiologicalList()
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }




    const excelDownload = async () => {
        setDownloadLoading(true)
        const anchor = document.createElement('a');
        let headers = new Headers();
        const token = await localStorage.getItem('userData');
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', 'application/json');
        // console.log('headers: ', headers);
        fetch(GLOBAL_URL + ApiEndPoint.pdl1ExportExcel, { headers })
            .then(response => response.blob())
            .then(data => {
                console.log('data: ', data);
                setDownloadLoading(false)
                if (data.type === "application/octet-stream") {
                    let objectUrl = window.URL.createObjectURL(data);
                    anchor.href = objectUrl;
                    anchor.download = 'PDL_Excel.xlsx';
                    anchor.click();
                    anchor.remove();
                    window.URL.revokeObjectURL(objectUrl);
                    toast.success('Successfully downloaded !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    setDownloadLoading(false)
                    toast.error('Something went wrong', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
    }

    const filterGraphs = async (value, pdl, pdlClone) => {
        setIsLoading(true)
        setFilterVal(value)
        setPdlFilterVal(pdl)
        setPdlCloneFilterVal(pdlClone)
        const params = {
            dateFilter: value ? value : null,
            pdl1StatusFilter: pdl ? pdl : null,
            pdl1CloneFilter: pdlClone ? pdlClone : null
        }
        const { data } = await apiCall('POST', ApiEndPoint.PDLFilterGraphs, params)
        if (data.success) {
            console.log("filtergraphs=>", data)
            apiResponse(data)
            //drugbioResponse(data1)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    return (
        <>
            <PDLAnyalsis
                allGraphData={allGraphData}
                userDetails={userDetails}
                filterGraphs={(val, pdl, pdlClone) => filterGraphs(val, pdl, pdlClone)}
                AllGraphsList={() => AllGraphsList()}
                filterVal={filterVal}
                pdlCloneFilterVal={pdlCloneFilterVal}
                pdl1ListTotal={pdl1ListTotal}
                biologicalTherapyTotal={biologicalTherapyTotal}
                drugsCountTotal={drugsCountTotal}
                drugTherapyTotal={drugTherapyTotal}
                pdlFilterVal={pdlFilterVal}
                top10totalCount={top10totalCount}
                drugTherapy={drugTherapy}
                biologicalTherapy={biologicalTherapy}
                drugsCount={drugsCount}
                top10CanSiteList={top10CanSiteList}
                top20CitiesData={top20CitiesData}
                hospitalNameData={hospitalNameData}
                setHospitalNameData={setHospitalNameData}
                hospitalName={hospitalName}
                setHospitalName={setHospitalName}
                excelDownload={excelDownload}
                primarySiteDistribution={primarySiteDistribution}
                primarySiteTotal={primarySiteTotal}
                downloadLoading={downloadLoading}
                pdlCloneTotal={pdlCloneTotal}
            />
        </>
    )
}
export default PDLAnyalsisScreen