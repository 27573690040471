import React, { useEffect, useState } from 'react';
import BrandLogo from "../../../assets/images/main_logo.svg";
import comapanyLogo from "../../../assets/images/4bc.svg";
import slider1 from '../../../assets/images/slider1.svg';
import slider2 from '../../../assets/images/6000_cancer.svg';
import slider3 from '../../../assets/images/Map_location.svg';

import "./style.css"

export default function Login(props) {
   return (
      <div className="row login_wrapper cust_align mx-0">
         <div className="left_logo">
            <img src={BrandLogo} />
         </div>
         <div className="right_logo">
            <img src={comapanyLogo} className="w-85" />
         </div>
         <div className="col-12 col-lg-6 col-md-12 col-sm-12 left_panel">
            <div className="dna_img">
               <img src="../../../assets/images/dna_image.png" className="img-fluid" alt="" />
            </div>
            <div className="logo text-right d-none">
               <img src="../../../../assets/images/login_logo.png" className="img-fluid w-75" alt="Logo" />
            </div>
            <div id="demo" className="carousel slide" data-ride="carousel">
               <ul className="carousel-indicators">
                  <li data-target="#demo" data-slide-to="0" className="active"></li>
                  <li data-target="#demo" data-slide-to="1"></li>
                  <li data-target="#demo" data-slide-to="2"></li>
               </ul>
               <div className="carousel-inner">
                  <div className="carousel-item active">
                     <img src={slider1} alt="Doctor-image" className="img-fluid w-50 m-auto " />
                     <div className="min_height py-3">
                        <p >A growing dataset of unique mutations across Asia </p>
                     </div>
                  </div>
                  <div className="carousel-item">
                     <img src={slider2} alt="Doctor-image" className="img-fluid w-50 m-auto " />
                     <div className="min_height py-3">
                        <p >Continuously evolving database with 300+ new cases added every month </p>
                     </div>
                  </div>
                  <div className="carousel-item">
                     <img src={slider3} alt="Doctor-image" className="img-fluid w-50 m-auto " />
                     <div className="min_height py-3">
                        <p>Working with leading healthcare and diagnostic chains across India & other parts of Asia </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="col-12 col-lg-5 col-md-12 col-sm-12 right_panel px-0">
            <div className="form_section p-5 mt-lg-5 mt-md-5">
               <div className="text-center">
                  <h3 className="text-blue">Sign in to your account</h3>
                  <p className="w-60 m-auto font-weight-normal">Please enter your Email ID</p>
               </div>
               <form className=" mt-4 pt-3  pr-2 " >
                  <div className="form-group mb-3 ">
                     <label htmlFor="email" className="cust_label">Email </label>
                     <input type="email"
                        className="form-control"
                        placeholder="Enter email Id"
                        onChange={(e) => props.handleChangeEmail(e)}
                        value={props.loginDetails.email} />
                     {props.validError && !props.loginDetails.email.length > 0 && <span className='label_error'>Please Enter Email </span>}
                  </div>
                  <div className="form-group mb-1 ">
                     <label htmlFor="pwd" className="cust_label">Password</label>
                     <input type={props.passwordShow ? "password" : 'text'}
                        className="form-control"
                        placeholder="Enter Password"
                        onChange={(e) => props.handleChangePassword(e)}
                        value={props.loginDetails.password} />
                     {props.validError && !props.loginDetails.password.length > 0 && <span className='label_error'  >Please Enter Password </span>}
                     {/* <a onClick={() => props.naviForgotPassword()}>Forgot Password?</a> */}
                     <a onClick={() => props.passwordShowFun()} className="show_password"><i className={props.passwordShow ? "zmdi zmdi-eye-off" : "zmdi zmdi-eye"}></i></a>
                  </div>
                  <div className="form-group form-check">
                     <label className="form-check-label">
                        {/* <input className="form-check-input" type="checkbox" /> Remember me */}
                     </label>
                  </div>
                  <div className="cust_btn mt-5 pt-3">
                     <button onClick={() => props.loginFun()} type='button' className="btn btn-info login_btn-color">Login</button>
                  </div>
                  <div className="login_footer mt-2 text-center">
                     <p className="font-weight-light">Not register yet? <a onClick={() => props.naviRegister()} className="text-soft_blue">Click here </a> to register </p>
                  </div>
               </form>
            </div>
         </div>
      </div>

   )
}