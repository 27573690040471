

import React, { useEffect, useContext, useState } from "react";
//import Home from "./Components/Home";
//import Parts from "./Components/Parts";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { setDefaultHeader, apiCall } from "../../utils/httpClient";
import ApiEndPoint from "../../utils/apiEndPoints";
import { AuthContext } from "../../Context/context";
import { LoaderContext, UserDatailContext } from "../../Context/userContext";
import { CancerSiteColors, COLORS } from '../../utils/constants'
//import { BodyLayout, Nasopharynx } from "./Components/Parts";
import Home from "./Components/Home";
const HomeScreen = () => {
    const history = useNavigate()
    const { signInFun, signOutFun } = React.useContext(AuthContext);
    const [IsLoading, setIsLoading] = useContext(LoaderContext);
    const [userDetails, setUserDetails] = useContext(UserDatailContext);
    const [allGraphData, setAllGraphData] = useState([])
    const [humanBodyData, setHumanBodyData] = useState([]);
    const [dataview, setDataview] = useState([]);
    const [dna, setDna] = useState([]);
    const [geneticmar, setGeneticMar] = useState([]);
    const [rna, setRna] = useState([]);
    const [totalrecords, setTotalRecords] = useState([]);
    const [famYes, setFamYes] = useState([]);
    const [famNo, setFamNo] = useState([]);
    const [totalFam, setTotalFam] = useState([]);
    const [radiology, setRadiology] = useState([]);
    const [pathology, setPathology] = useState([]);
    const [diagRecord, setDiagRecord] = useState([]);
    const [femaledemo, setFemaleDemo] = useState([]);
    const [maledemo, setMaleDemo] = useState([]);
    const [demoRecord, setDemoRecord] = useState([]);
    const [treatDrug, setTreatDrug] = useState([]);
    const [treatRadiation, setTreatRadiation] = useState([]);
    const [treatSurgery, setTreatSurgery] = useState([]);
    const [treatRecord, setTreatRecord] = useState([]);




    useEffect(() => {
        AllGraphsList()
        DataOverviewList()
    }, [])


    function bodyDataFun(dt) {
        const humanData = [
            { name: 'Kidney', value: 0, id: 'Kidney' },
            { name: 'Nasopharynx', value: 0, id: "Nasopharynx" },
            { name: 'Other and ill-defined sites in lip, oral cavity and pharynx', value: 0, id: 'Other and ill-defined sites in lip, oral cavity and pharynx' },
            { name: 'Testis', value: 0, id: 'Testis' },
            { name: 'Anus and Anal canal', value: 0, id: 'Anus and Anal canal' },
            { name: 'Eye', value: 0, id: 'Eye' },
            { name: 'Skin', value: 0, id: 'Skin' },
            { name: 'Lung', value: 0, id: 'Lung' },
            { name: 'Urinary Bladder', value: 0, id: 'Urinary Bladder' },
            { name: 'Heart', value: 0, id: 'Heart' },
            { name: 'Brain', value: 0, id: 'Brain' },
            { name: 'Breast', value: 0, id: 'Breast' },
            { name: 'Thyroid', value: 0, id: 'Thyroid' },
            { name: 'Pancreas', value: 0, id: 'Pancreas' },
            { name: 'Adrenal gland', value: 0, id: 'Adrenal gland' },
            { name: 'CirculatorySystem', value: 0, id: 'CirculatorySystem' },
            { name: 'Stomach', value: 0, id: 'Stomach' },
            { name: 'Small Intestine', value: 0, id: 'Small Intestine' },
            { name: 'Rectum', value: 0, id: 'Rectum' },
            { name: 'Esophagus', value: 0, id: 'Esophagus' },
            { name: 'Liver and intrahepatic bile ducts', value: 0, id: 'Liver and intrahepatic bile ducts' },
            { name: 'Gallbladder', value: 0, id: 'Gallbladder' },
            { name: 'Prostate', value: 0, id: 'Prostate' },
            // {name:'Adipose',count:100,id:'Adipose'},
            { name: 'Other and unspecified major salivary gland', value: 0, id: 'Other and unspecified major salivary gland' }

        ]

        const newState = humanData.map(obj => {
            if (obj.name === dt?.filter((item) => { return item.cancerSiteName === obj.name })[0]?.cancerSiteName) {
                return { ...obj, value: dt?.filter((item) => { return item.cancerSiteName === obj.name })[0]?.cancerSiteCount };
            }
            return obj;
        });
        const sortedData = newState?.sort((a, b) => b.value - a.value);
        console.log("map", sortedData);
        return sortedData
    }
    //     const dataState = humanData.map((obj,i) => {
    //    //     color = CancerSiteColors.filter((item) => item.name === obj).length > 0 ? CancerSiteColors.filter((item) => item.name === obj)[0].code : '',{
    //         if (obj.name === array?.filter((item) => { return item.cancerSiteName === obj.name })[0]?.cancerSiteName) {
    //             return { ...obj,value: array?.filter((item) => { return item.cancerSiteName=== obj.name })[0]?.cancerSiteCount,
    //             //id:array?.filter((item) => { return item.cancerSiteName === obj.name })[0]?.cancerSiteName,
    //           //  fill:CancerSiteColors.filter((item) => item.name === obj).length > 0 ? CancerSiteColors.filter((item) => item.name === obj)[0].code:COLORS[i % COLORS.length] };
    //         }
    //         return obj;
    //  });


    function tmbDataChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.name === null) {
                return { ...obj, name: 'NA', z: 250.9 };
            } else if (obj?.name === "High") {
                return { ...obj, z: 300.5 }
            } else if (obj?.name === "Low") {
                return { ...obj, z: 450.8 }
            }

        })
        console.log("tmbnew", newState);
        return newState
    }


    function mdataViewChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.familyHistoryInfo?.familyHistoryNoPercentage === null) {
                return { ...obj, familyHistoryNoPercentage: 0 };
            }
            return obj;
        })
        console.log("newState", newState);
        return newState

    }

    function pdlDataChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.name === "Negative") {
                return { ...obj, name: 'Positive' };
            }
            else if (obj?.name === "Positive") {
                return { ...obj, name: 'Negative' };
            }
            if (obj?.name === null) {
                return { ...obj, name: 'NA' };
            }
            return obj;
        })
        console.log("new", newState);
        return newState
    }

    function drugDataChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.name === "CHEMOTHERAPY") {
                return { ...obj, name: 'Chemo Therapy' };
            }
            else if (obj?.name === "DRUG_THERAPY") {
                return { ...obj, name: 'Hormonal Therapy' };
            }
            return obj;
        })
        return newState
    }
    function treatmentDataChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.name === "Surgery") {
                return { ...obj, name: "Surgical Therapy" };
            }
            else if (obj?.name === "Radiation") {
                return { ...obj, name: 'Radiation Therapy' };
            }
            if (obj?.name === "Drugs Therapy") {
                return { ...obj, name: 'Drug Therapy' }
            }
            return obj;
        })
        return newState
    }
    function biologicalDataChange(array) {
        const newState = array?.map((obj) => {
            if (obj?.name === "Targeted therapy") {
                return { ...obj, name: "Targeted Therapy" };
            }
            return obj;
        })
        return newState
    }

    function renameKeys(obj, newKeys) {
        const keyValues = Object.keys(obj).map(key => {
            const newKey = newKeys[key] || key;
            return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    }


    function apiResponse(dt) {
        setAllGraphData(dt.payLoad);
        // console.log("dt",allGraphData);
        const sortedData = dt.payLoad?.sort((a, b) => b.cancerSiteCount - a.cancerSiteCount);
        console.log("sortt", sortedData)
        setHumanBodyData(bodyDataFun(sortedData));

    }
    function dataResponse(data) {
        setDataview(data.payLoad);
        console.log("view", dataview)
        setDna(data.payLoad?.dnaPercentage)
        setRna(data.payLoad?.rnaPercentage)
        setGeneticMar(data.payLoad?.geneticMarkersPercentage)
        setTotalRecords(data.payLoad?.totalRecordedEventsCount)
        setFamYes(data.payLoad?.familyHistoryYesPercentage)
        setFamNo(data.payLoad?.familyHistoryNoPercentage)
        setTotalFam(data.payLoad?.totalFamilyHistoryRecordedPercentage)
        setPathology(data.payLoad?.pathologyPercentage)
        setRadiology(data.payLoad?.radiologyPercentage)
        setDiagRecord(data.payLoad?.diagnosticTestsRecordedPercentage)
        setMaleDemo(data.payLoad?.malePercentage)
        setFemaleDemo(data.payLoad?.femalePercentage)
        setDemoRecord(data.payLoad?.demographicsRecordedPercentage)
        setTreatSurgery(data.payLoad?.surgeryPercentage)
        setTreatRadiation(data.payLoad?.radiationPercentage)
        setTreatDrug(data.payLoad?.drugPercentage)
        setTreatRecord(data.payLoad?.treatmentsRecordedPercentage)
    }

    const AllGraphsList = async () => {
        setIsLoading(true)
        // Top20BoimarkerFun(null)
        const { data } = await apiCall('GET', ApiEndPoint.MajorCancerSites)
        console.log("data", data);
        if (data.success) {
            // setFilterVal('')
            apiResponse(data)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const DataOverviewList = async () => {
        setIsLoading(true)
        // Top20BoimarkerFun(null)
        const { data } = await apiCall('GET', ApiEndPoint.DataOverview)
        console.log("dataoverview", data);
        if (data.success) {
            // setFilterVal('')
            dataResponse(data)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            toast.error(data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }




    // const filterGraphs = async (value) => {
    //     setIsLoading(true)
    //     setFilterVal(value)
    //     //Top20BoimarkerFun(value)
    //   //  const params = value
    //   const params = {
    //     dateFilter:value ? value : null
    //   }
    //     const { data } = await apiCall('POST', ApiEndPoint.FilterGraphs, params)
    //     if (data.success) {
    //         apiResponse(data)
    //         setIsLoading(false)
    //     } else {
    //         setIsLoading(false)
    //         toast.error(data.message, {
    //             position: toast.POSITION.TOP_RIGHT
    //         });
    //     }
    // }


    return (
        <>
            <Home
                allGraphData={allGraphData}
                humanBodyData={humanBodyData}
                userDetails={userDetails}
                //  filterGraphs={(val) => filterGraphs(val)}
                AllGraphsList={() => AllGraphsList()}
                DataOverviewList={() => DataOverviewList()}
                dataview={dataview}
                dna={dna}
                rna={rna}
                geneticmar={geneticmar}
                totalrecords={totalrecords}
                famYes={famYes}
                famNo={famNo}
                totalFam={totalFam}
                radiology={radiology}
                pathology={pathology}
                diagRecord={diagRecord}
                maledemo={maledemo}
                femaledemo={femaledemo}
                demoRecord={demoRecord}
                treatDrug={treatDrug}
                treatRadiation={treatRadiation}
                treatSurgery={treatSurgery}
                treatRecord={treatRecord}
                IsLoading={IsLoading}
            />
        </>
    )
}
export default HomeScreen