import React, { useEffect, useState, useContext,useMemo,useRef } from 'react';
import Loader from "react-js-loader";

import grphBlur from './../../../assets/images/graph-blur.png';
//import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";


const Roche = (props) => {
    const { setToVal, setFromVal, toVal, fromVal, excel, downloading,totalval,setExcelbio,excelbio,excelValfun,
       statusExcel,setStatusExcel,IsLoading,handleWeekRangeSelection,handlethreedaysSelection } = props
   
        const options = [
            { value: 'ALK', label: 'ALK' },
            { value: 'EGFR', label: 'EGFR' },
            { value: 'NTRK', label: 'NTRK' },
            { value: 'RET', label: 'RET' },
            { value: 'ROS', label: 'ROS' },
          ];

        function useDidUpdateEffect(callback, dependencies) {
            const didMountRef = useRef(false);
            useEffect(() => {
              if (didMountRef.current) {
                callback();
              } else {
                didMountRef.current = true;
              }
            }, dependencies);
          }
          const combinedDependency = `${fromVal}-${toVal}`;
          useDidUpdateEffect(() => {
            excelValfun();
          }, [combinedDependency, excelbio, statusExcel]);
       
        // useMemo(() => {
        //    excelValfun()
        // }, [combinedDependency]);
        // useMemo(() => {
        //     excelValfun()
        //  }, [excelbio]);
        //  useMemo(() => {
        //     excelValfun()
        //  }, [statusExcel]);
        //  useMemo(() => {
        //     excelValfun()
        //  }, [totalval]);
         
    function commafy(num) {
        if (num !== undefined && num !== null) {
            var str = num.toString().split('.');
            if (str[0].length >= 0) {
                str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
            }
            if (str[1] && str[1].length >= 0) {
                str[1] = str[1].replace(/(\d{3})/g, '$1 ');
            }
            return str.join('.');
        }
        return '0';
    }
    const [selectedGenes, setSelectedGenes] = useState([]);
    const handleSelectChange = (event) => {
      //  const selectedOptions = Array.from(event.target.selectedOptions);
        const newSelectedValues = event.map((option) => option.value);
        console.log("New Selected Values:", newSelectedValues); 
        setExcelbio((prevSelectedValues) => [...new Set([...prevSelectedValues, ...newSelectedValues])]);
      };
  const handleSelectedGenes = (selected) => {
    const formatted = selected.map((item) => item.value);
    setExcelbio(formatted);
  };
  
    return (
        <div>
            {IsLoading && <div className='downloadLoader '>
                <Loader type="spinner-circle" bgColor='black' title={"downloading..."} color={'#FFFFFF'} size={100} />
            </div>
            }
            <div className="content-wrapper ">
                <div className="content-wrapper_inner">
                    <div className='w-100 py-3 text-right mb-4'>
                    {fromVal&&toVal ? (
                        <>
                        <div className="btn-group range_button det_height">
                          {/* <div className="btn btn-outline-blue dropdown-toggle></div>   */}
                        <MultiSelect
                       value={excelbio}
                       options={options}
                        onChange={setExcelbio}
                          />
                        </div>
                        <div className="btn-group range_button det_height">
                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                               {statusExcel ?statusExcel :"Status"}
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg-right ">
                                <button onClick={() => setStatusExcel('Positive')} className="dropdown-item" type="button">Positive</button>
                                <button onClick={() => setStatusExcel('Negative')} className="dropdown-item" type="button">Negative</button>
                            
                            </div>
                        </div>
                    
                         <div className="btn-group range_button det_height">
                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                <i className="zmdi zmdi-calendar-note"></i>{fromVal && toVal ? `${fromVal} - ${toVal}` : 'Time Range'}
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg-right ">
                            <button onClick={handlethreedaysSelection} className="dropdown-item" type="button">Last 3 days</button>
                                <button onClick={handleWeekRangeSelection} className="dropdown-item" type="button">Last 1 Week</button>

                            </div>
                        </div>
                        </>
                    ):(
                        <div className="btn-group range_button det_height">
                            <button type="button" className="btn btn-outline-blue dropdown-toggle" data-toggle="dropdown" data-display="static" aria-expanded="false">
                                <i className="zmdi zmdi-calendar-note"></i>{fromVal && toVal ? `${fromVal} - ${toVal}` : 'Time Range'}
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg-right ">
                                <button onClick={handlethreedaysSelection} className="dropdown-item" type="button">Last 3 days</button>
                                <button onClick={handleWeekRangeSelection} className="dropdown-item" type="button">Last 1 Week</button>

                            </div>
                        </div>
            )}
                        {/* <button className="btn btn-outline-blue  ml-2" onClick={excel} disabled={downloading} type="button"> {downloading ? 'Downloading...' : 'Download Excel'}</button> */}
                    </div>
                
                    {/*  */}
                    <div className=" px-lg-0 mb-3" >
                        <div className='p-3 record_card  sixth_box ' style={{ 'height': 223 }}>
                            <div className=" p-2 rounded  ">
                                {/* <div className='box-title my-1'>
                                                    <h6 className='mb-0' style={{ color: '#607EFF' }}>4579</h6>
                                                </div> */}

                                <div className='w-100  pt-68'>
                                    <h5 className='font-weight-bold text-center w-100'>Total Records Found</h5>
                                </div>
                            </div>
                            <div className="py-2 content position-relative mt-20 text-center">
                                <h2 className='mb-0 text-warning font-weight-bold text-center w-100'>{commafy(totalval) ? totalval : 0}</h2>
                                <div className='download_count' onClick={excel} >
                                    <i className="zmdi zmdi-download"></i>

                                </div>
                            </div>
                        </div>

                    </div>

                    {/*  */}
                    <div className='w-75 border rounded m-auto py-3'>
                        <img src={grphBlur} className=' img-fluid' />
                    </div>




                </div>
            </div>
        </div>

    )
}
export default Roche;